import React,{useState} from "react";
import "./aboutus.css";
import Header from "../header/header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../footer/footer";
import Slide from "../slide/slide";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";


const AboutUs = () => {
  const [datalist, setdatalist] = React.useState([]);
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [comment, setComment] = useState("")

  const transformation = {
    margin: 30,
    responsiveClass: true,
    touchDrag: false,
    mouseDrag: false,

    // nav: true,
    // autoplay:true,
    //       nav: true,
    //   navText:["<div className='nav-btn prev-slide'></div>","<div className='nav-btn next-slide'></div>"],
    autoplayTimeout: 12000,
    dots: true,
    autoplay: false,
    infinite: false,
    // items:3,
    slideBy: 3,
    // smartSpeed: 1000,
    rewind: true,

    responsive: {
      0: {
        items: 1,
        touchDrag: true,
      },
      400: {
        items: 1,
        touchDrag: true,
      },
      600: {
        items: 1,
        touchDrag: true,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  React.useEffect(() => {
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    const methods = "aboutus/English";
    new ServiceStore().GetAllData(methods).then((res) => {
      console.log(res);
      setdatalist(res.data);
    });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const body = {
      name: name,
      mobile: mobile,
      comment: comment,
    };
    const method = "/contactus/add";
    console.log("data :", body);
    console.log("!body.name :", !body.name);
    console.log("!body.mobile :", !body.mobile);
    console.log("!body.mobile :", !body.mobile.length == 10);
    console.log("!body.comment :", !body.comment);
    if (!body.name) {
      toast.warn("Please enter name", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);
    }else if(!body.mobile || body.mobile.length !== 10){
      toast.warn("Please enter valid Mobile number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);

    } else if(!body.comment){
      toast.warn("Please enter comment", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);

    }
     else {
      new ServiceStore()
        .InsertData(method, body)
        .then((response) => {
          if (response.response.status === 1) {
            toast.success("Response submitted Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setName('')
            setMobile('')
            setComment('')
            console.log(response);
          } else {
            toast.warn("Already submitted", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.error("Error response:", response);
          }
        })
        .catch((error) => {
          toast.warn("Already submitted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const handleInputChange = (e) => {
    console.log("Input changed:", e.target.name, e.target.value);

    const { name, value } = e.target;

    if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "comment") {
      setComment(value);
    }
  }

  return (
    <>
      <div className="outer-changes">
        <section className="about-section">
          <div className="upper-wrapper">
            {/* <Header/> */}
            <NewHeader />
            <div className="row header-pad" style={{ margin: "0", padding: "0", position: "initial" }}>
              <div className="col-xl-5 top-class">
                <div>
                  <p className="future-p2">
                    {datalist[0]?.line1}
                    {/* <br></br> The Mula-Mutha River
                    <br></br>Rejuvenation Project */}
                  </p>
                </div>
                <div>
                  <Link  data-toggle="modal" data-target="#exampleModal">
                    <button className="btn-lets2">
                      {datalist[0]?.line2}{" "}
                    </button>
                  </Link>
                  <Link to="/">

                    <button className="btn-lets">{datalist[0]?.line3}</button>
                  </Link>

                </div>
              </div>
              <div className="col-xl-7 top-class new-class">
                <div className="about-first-sec">
                  <p>
                    The Mula-Mutha River Rejuvenation Project is aimed at
                    transforming the Mula, Mutha, and Mula-Mutha rivers into
                    valuable assets for the city. With urbanization, these water
                    bodies have faced increasing levels of pollution and
                    degradation.{" "}
                    {/* {datalist[0]?.line4}{" "} */}
                  </p>
                  <p>
                    <b>
                      The goal of this project is to improve the state of the
                      rivers and make them more accessible and enjoyable for the
                      people of Pune.
                    </b>
                    By revitalizing these rivers, the Pune Municipal Corporation
                    hopes to not only address environmental and health issues,
                    but also create recreational and educational opportunities
                    for the community and potentially drive economic growth.{" "}
                  </p>
                  <p>
                    The Pune Municipal Corporation looks forward to working with
                    local stakeholders to ensure the success and sustainability
                    of this important project.
                  </p>
                </div>
              </div>
              <div className="about-first-img">
                <img src="../../images/Polygon 3.png" />
              </div>
            </div>
          </div>
        </section>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="cntainer" style={{ padding: '1rem' }}>
                  <div className="contact-us1">
                    <h2 style={{ fontSize: '3rem' }}>Contact Us</h2> <br></br>
                    <form>
                      <div className="form-group1">
                        <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Name</label>
                        <input className='contactUsFormInput'
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          required
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="form-group1">
                        <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Mobile Number</label>
                        <input className='contactUsFormInput'
                          type="text"
                          id="mobile"
                          name="mobile"
                          onChange={handleInputChange}
                          required
                          placeholder="Your Mobile Number" />
                      </div>

                      <div className="form-group1">
                        <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Comment Here</label>
                        <textarea className='contactUsFormInput'
                          type="text"
                          id="comment"
                          name="comment"
                          required
                          onChange={handleInputChange}
                          placeholder="Comment Here" ></textarea>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'end', fontSize: '15px' }}>
                        <button type="submit" onClick={handleSubmitForm} data-dismiss="modal" aria-label="Close">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="about-second-sec">
          <div className="container-flex">
            <div className="row" style={{ margin: "0", padding: "0" }}>
              <div className="col-xl-12">
                <h5 className="sec-left3"> {datalist[0]?.line5}</h5>
                <div className="slide-check">
                  <svg
                    width="92"
                    height="16"
                    viewBox="0 0 92 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.792893 7.29289C0.402367 7.68341 0.402367 8.31657 0.792892 8.7071L7.15685 15.0711C7.54738 15.4616 8.18054 15.4616 8.57107 15.0711C8.96159 14.6805 8.96159 14.0474 8.57107 13.6568L2.91422 7.99999L8.57107 2.34314C8.96159 1.95261 8.96159 1.31945 8.57107 0.928925C8.18054 0.538401 7.54738 0.5384 7.15685 0.928925L0.792893 7.29289ZM91.5 7L1.5 6.99999L1.5 8.99999L91.5 9L91.5 7Z"
                      fill="white"
                    />
                  </svg>

                  <p>Slide To Check</p>
                  <svg
                    width="92"
                    height="16"
                    viewBox="0 0 92 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M91.2071 8.70711C91.5976 8.31659 91.5976 7.68343 91.2071 7.2929L84.8431 0.92894C84.4526 0.538415 83.8195 0.538415 83.4289 0.928939C83.0384 1.31946 83.0384 1.95263 83.4289 2.34315L89.0858 8.00001L83.4289 13.6569C83.0384 14.0474 83.0384 14.6806 83.4289 15.0711C83.8195 15.4616 84.4526 15.4616 84.8431 15.0711L91.2071 8.70711ZM0.5 9L90.5 9.00001L90.5 7.00001L0.5 7L0.5 9Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {datalist[0] && (
                  <div className="row">
                    <div className="col-xl-12">
                      <OwlCarousel
                        {...transformation}
                        className="owl-theme hp-owl"
                      >
                        <div className="image-frame">
                          {/* <img src={imgeurl + datalist[0]?.line6[0]?.filename} /> */}

                          {/* <div> */}
                          {/* <p className="slide-para-1">Before</p> */}

                          {/* </div> */}

                          <Slide props={datalist[0]?.line6} />
                          <p className="trans-name">{datalist[0]?.line7} </p>
                        </div>
                        <div className="image-frame">
                          {/* <img src={imgeurl + datalist[0]?.line9[0]?.filename} /> */}
                          <Slide props={datalist[0]?.line9} />
                          <p className="trans-name">{datalist[0]?.line8} </p>
                        </div>
                        <div className="image-frame">
                          {/* <img src={imgeurl + datalist[0]?.line6[2]?.filename} /> */}
                          <Slide props={datalist[0]?.line11} />
                          <p className="trans-name">{datalist[0]?.line10} </p>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
