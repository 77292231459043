import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./Photographs.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Button } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageGallery from "react-image-gallery";
import { IoArrowBackOutline, IoArrowUp } from "react-icons/io5";
import { useEffect } from "react";
import { useState } from "react";

const image1 = "/drive/Photography/amit deshpande.jpeg";
const image2 = "/drive/Photography/yash deshmukh.jpeg";
const image3 = "/drive/Photography/vilas tolsankar.jpeg";
const image4 = "/drive/Photography/uday patil.jpeg";
const image5 = "/drive/Photography/shivaji dhute.jpeg";
const image6 = "/drive/Photography/sayali mohite.jpeg";
const image7 = "/drive/Photography/mahendra kolhe.jpeg";
const image8 = "/drive/Photography/kalpesh nukte.jpeg";
const image9 = "/drive/Photography/chetan kawre.jpeg";
const image10 = "/drive/Photography/Amit darunkar.jpeg";

function Photographs() {
  const [datalist, setdatalist] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);
  const pageSize = 3;

  const [gallary, setgallery] = React.useState([
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  ]);
  const [itemsToShow, setItemsToShow] = useState(4);

  React.useEffect(() => {
    getAlldataFile();
    if (datalist.length > 0) {
      fetchData();
    }
  }, [page]);
  // React.useEffect(() => {
  //   fetchData();
  // },[page])
  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollHeight + 1 >=
        document.documentElement.scrollTop
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
  }, []);
  const getAlldataFile = () => {
    setIsLoading(true);
    const methods = "gallary/English";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data[0].line6);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = res.data[0].line6.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    });
  };

  const fetchData = () => {
    setIsLoading(true);
    setTimeout(() => {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = datalist.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    }, 500);
  };
  const imagePerRow = 3;
  const [next, setNext] = React.useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This smooth scroll is optional, you can remove it if you want an instant scroll
    });
  };

  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          <section className="gal-sec-1">
            <div className="headerDiv">
              <div>
                <p className="gal-p3">
                  <span>
                    Top Photographs from the PuneRé Photography Competition
                  </span>
                </p>
              </div>
              <div>
                <Link to="/English/Gallery">
                  <div>
                    <IoArrowBackOutline
                      style={{
                        float: "right",
                        height: "5em",
                        width: "3em",
                        marginRight: "10em",
                      }}
                    />
                    <span className="back-btn1">Back</span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>

        <div className="container-flex">
          <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {/* {gallary?.map((image, index) => (
              <div className="col-md-4">
                <img
                  // src={image4}
                  // className="gallery-images"
                />
              </div>
            ))} */}
            {gallary.slice(0, itemsToShow).map((image) => {
              return (
                <div className="col-xl-3 col-md-3 col-sm-2">
                  <img style={{ width: "100%" }} src={image} />
                </div>
              );
            })}
          </div>
        </div>

        {/* {isLoading && (
          <div className="spinnerDiv">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
        <div className="btn-group-div">
          <div
            onClick={scrollToTop}
            // className="Ups-button"
            style={{ marginBottom: "10px", cursor: "pointer" }}
          >
            <IoArrowUp
              style={{
                float: "right",
                height: "5em",
                width: "3em",
                marginRight: "10em",
              }}
            />
            <span className="back-btn1">Top</span>
            {/* <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button> */}
          </div>
          <div>
            {itemsToShow < gallary.length && (
              <button
                onClick={() => {
                  setItemsToShow(itemsToShow + 4);
                }}
                className="view-more-btn"
              >
                View More
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Photographs;
