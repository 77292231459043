import React, { useState } from 'react'
import './ContactUs.css';
import Footer from '../footer/footer';
import NewHeader from '../NewHeader/NewHeader';
import ServiceStore from '../../utils/ServiceStore';

const ContactUs = () => {
  const [name,setName] = useState("")
  const [mobile,setMobile] = useState("")
  const [comment,setComment] = useState("")

  const handleSubmitForm=(e)=>{
    e.preventDefault();
    const body ={
      name:name,
      mobile:mobile,
      comment:comment,
    }
    const method ="admin/contactus/add"
    console.log("data :", body)
    new ServiceStore().InsertData(method,body).then((res)=>{
      console.log(res)
    })
  }

  const handleInputChange=(e)=>{
    const { name, value } = e.target;
  
    if (name === "name") {
      console.log(/[^\d]/.test(value))
      if(/[^\d]/.test(value)){
        setName(value);
      }
    } else if (name === "mobile") {
      if(value.length <= 10)
      {
        console.log(value.length)
        setMobile(value);
      }
    } else if (name === "comment") {
      setComment(value);
    }
  }
  return (
    <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper-for-contactUs">
    <NewHeader/>
    <div className="cntainer" style={{padding:'4rem'}}>
    <div className="contact-us1">
      <h2 style={{fontSize:'3rem'}}>संपर्क साधा</h2>
      <form>
        <div className="form-group1">
          <label className='contactUsFormLabels' style={{fontSize:'16px'}}>नाव</label><br></br>
          <input className='contactUsFormInput' 
          type="text" 
          id="name" 
          name="name" 
          required
          onChange={handleInputChange}
          placeholder="तुमचे नाव" />
        </div><br></br>

        <div className="form-group1">
          <label className='contactUsFormLabels' style={{fontSize:'16px'}}>मोबाईल नंबर</label><br></br>
          <input className='contactUsFormInput' 
          type="tel" 
          id="mobile" 
          name="mobile" 
          required
          onChange={handleInputChange}
          placeholder="आपला भ्रमणध्वनी क्रमांक" />
        </div><br></br>

        <div className="form-group1">
          <label className='contactUsFormLabels' style={{fontSize:'16px'}}>येथे टिप्पणी द्या</label><br></br>
          <textarea className='contactUsFormInput' 
          type="email" 
          id="email" 
          name="email" 
          required
          onChange={handleInputChange}
          placeholder="येथे टिप्पणी द्या" ></textarea>
        </div>

        <div style={{ display: 'flex', justifyContent: 'end',fontSize:'15px'}}>
          <button type="submit" onClick={handleSubmitForm}>Submit</button>
        </div>
      </form>
    </div>
    </div>
    <Footer/>
    </div>
    </section>
    </div>
  )
}

export default ContactUs