import React from "react";
import { Link } from "react-router-dom";
import "./logIn.css";
import { useHistory } from "react-router-dom";
import ServiceStore from "../../utils/ServiceStore";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useState } from "react";
import { toast } from "react-toastify";

const LogIn = () => {
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showErrorOTP, setShowErrorOTP] = useState(false);
  const [showErrorOTPMessage, setShowErrorOTPMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [showpassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [otpGet, setOtpGet] = React.useState(false);

  // const onSubmit = (e) => {
  //   const data = e;
  //   const method = "user/login";
  //   new ServiceStore().InsertData(method, data).then((res) => {
  //     if (res.response.data.status === 0) {
  //       setShowErrorMessage(res.response.data.message);
  //       setShowError(true);
  //     } else {
  //       setOtpGet(true);
  //       toast.success("Otp Send!", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       sessionStorage.setItem("items", JSON.stringify(res.response.data));
  //     }
  //   }).catch((err)=>{
  //     if(err.message==="Request failed with status code 500"){
  //       toast.warn("Network error!", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   })
  // };

  const onSubmit = (e) => {
    if (cooldown) {
      toast.warn("Please wait before sending another OTP.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Don't proceed if cooldown is active
    }

    setIsSendingOTP(true);
    setCooldown(true); // Activate the cooldown

    const data = e;
    const method = "user/login";
    new ServiceStore()
      .InsertData(method, data)
      .then((res) => {
        if (res.response.data.status === 0) {
          toast.warn(res.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsSendingOTP(false);
          setCooldown(false); // Deactivate the cooldown after a certain time
        } else {
          setOtpGet(true);
          toast.success("OTP Sent Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          sessionStorage.setItem("items", JSON.stringify(res.response.data));
          setIsSendingOTP(false);
          setTimeout(() => {
            setCooldown(false); // Deactivate the cooldown after a certain time
          }, 60000); // Set the cooldown duration in milliseconds (e.g., 1 minute)
        }
      })
      .catch((err) => {
        // Handle network error
        setIsSendingOTP(false);
        setCooldown(false); // Reset cooldown on error as well
      });
  };

  const resendOtp = () => {
    const body = {
      email: email,
    };
    const method = "user/login";
    new ServiceStore()
      .InsertData(method, body)
      .then((res) => {
        if (res.response.data.status === 0) {
          setShowErrorMessage(res.response.data.message);
          setShowError(true);
        } else {
          setOtpGet(true);
          toast.success("Otp Resent !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          sessionStorage.setItem("items", JSON.stringify(res.response.data));
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 500") {
          toast.warn("Network error!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const onOtpSubmit = (e) => {
    console.log(showpassword);
    // e.preventDefault()
    let body = {};
    if (showpassword) {
      e.preventDefault();
      body = {
        email: email,
        password: password,
        otp: otp,
      };
    } else {
      // e.preventDefault();
      // const data = e;
      body = {
        email: email,
        otp: otp,
        password: "",
      };
    }
    const data = e;
    const method = "user/matchotp";
    new ServiceStore().InsertData(method, body).then((res) => {
      console.log("res:", res);
      if (res.response.status == 1) {
        sessionStorage.setItem("token", JSON.stringify(res?.response?.token));
        history.push("/English/Home");
        toast.success("Login Successfully !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setOtpGet(false);
        sessionStorage.setItem("items", JSON.stringify(res.response.data));
      } else {
        toast.error(res.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowErrorOTPMessage(res.response.data.message);
        setShowErrorOTP(true);
      }
    });
  };
  const formClass = classNames({
    "form-validate": true,
  });

  const handleInputChange = (event) => {
    // Clear the error message and set showError to false when input field is cleared
    if (event.target.value === "") {
      setShowError(false);
      setShowErrorMessage("");
      setShowErrorOTPMessage("res.response.data.message");
      setShowErrorOTP(false);
      setEmail("")
    } else {
      setEmail(event.target.value);
    }
  };

  const handleInputPassword = (event) => {
    // setEmail(event.target.value)
    setPassword(event.target.value);
  };

  const handleOTPChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue == "") {
      setOtp("");
    }
    if (/^[0-9]{0,6}$/.test(inputValue) || inputValue.length === 6) {
      setOtp(inputValue);
    }
  };

  const showPasswordField = (e) => {
    setShowPassword(e.target.checked);
  };

  const backToLoginByOtp = () => {
    setOtpGet(false);
  };

  return (
    <>
      <div className="container-fluid class-sign">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 login-height">
                    <div className="logo-head">
                      <Link to="/">
                        <img src="../../images/logo.png" />
                      </Link>
                    </div>
                    {!otpGet && (
                      <div className="form-divpm">
                        <h5 className="login-head">Login</h5>
                        <Form
                          className={formClass}
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="form-grouppm">
                            <label>Email Id / Mobile Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              id="email"
                              value={email}
                              placeholder="Enter Email Id / Mobile Number"
                              {...register("email", {
                                required: "Email / Mobile Required",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.message && errors.message.message}
                            {showError && (
                              <h5 className="errroMessage">
                                {showErrorMessage}
                              </h5>
                            )}
                          </div>

                          <div className="form-group">
                            <label>
                              <input
                                type="checkbox"
                                name="isMerchant"
                                {...register("isMerchant")}
                              />{" "}
                              Are you a merchant ?
                            </label>
                          </div>

                          <div className="form-group">
                            <label>
                              <input
                                type="checkbox"
                                name="loginWithPassword"
                                value="loginWithPassword"
                                id="loginWithPassword"
                                onClick={showPasswordField}
                                onChange={handleInputPassword}
                                {...register("isloginWithPassword")}
                              />{" "}
                              Login With Password
                            </label>
                          </div>

                          {showpassword && (
                            <>
                              <div className="form-group">
                                <label>
                                  <div className="form-control-wrap d-flex first-container">
                                    <div className="password-input-container">
                                      <input
                                        className="form-control"
                                        type={
                                          passwordVisible ? "text" : "password"
                                        }
                                        name="password"
                                        id="password"
                                        placeholder="Enter Password"
                                        value={password}
                                        onChange={handleInputPassword}
                                      />{" "}
                                      <span
                                        className={`password-toggle-icon2 ni ${
                                          passwordVisible
                                            ? "fa fa-eye-slash"
                                            : "fa fa-eye"
                                        }`}
                                        onClick={() =>
                                          setPasswordVisible(!passwordVisible)
                                        }
                                      ></span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <button
                                onClick={onOtpSubmit}
                                className="account-btnpm"
                              >
                                Login
                              </button>
                              <div className="login-bottompm">
                                <span>Forgot Password ?</span>
                                <Link to="/SignUp">Click Here</Link>
                              </div>
                            </>
                          )}

                          {/* <Link to="/Loginotp"> */}
                          {!showpassword && (
                            <button
                              type="submit"
                              className="account-btnpm"
                              disabled={isSendingOTP}
                            >
                              {isSendingOTP ? "Sending OTP..." : "Send OTP"}
                            </button>
                          )}
                          {/* </Link> */}
                          <div className="login-bottompm">
                            <span>Don't have an account?</span>
                            <Link to="/SignUp">Sign up</Link>
                          </div>
                        </Form>
                        <Form></Form>
                      </div>
                    )}
                    {otpGet && (
                      <div className="lo-form-div">
                        <Form
                          className={formClass}
                          onSubmit={handleSubmit(onOtpSubmit)}
                        >
                          <div className="form-group">
                            <label>OTP</label>
                            <input
                              className="form-control"
                              type="number"
                              name="otp"
                              id="otp"
                              value={otp}
                              placeholder="Enter otp"
                              {...register("otp", {
                                required: "otp Required",
                              })}
                              onChange={handleOTPChange}
                            />
                            {errors.message && errors.message.message}
                            {showErrorOTP && (
                              <h5 className="errroMessage">
                                {showErrorOTPMessage}
                              </h5>
                            )}
                          </div>

                          <button type="submit" className="loginotp-btn">
                            Login
                          </button>

                          <div className="login-bottom">
                            <p>Haven't Received OTP ? </p>
                            <p
                              onClick={resendOtp}
                              style={{ color: "#1C21AB", cursor: "pointer" }}
                            >
                              &nbsp;Resend OTP
                            </p>
                          </div>

                          <div className="login-bottom">
                            <p>Login With Password </p>
                            <p
                              onClick={backToLoginByOtp}
                              style={{ color: "#1C21AB", cursor: "pointer" }}
                            >
                              &nbsp;Login With Password
                            </p>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pad-none">
                <div className="right-side-imagepm">
                  <div className="quotes-classpm"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
