import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import NewHeader from "../NewHeader/NewHeader";
import "./gallery.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import ImagesSection from "../PhotoGallary/ImagesSection";
import { colors } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const image1 = "/drive/Photography/amit deshpande.jpeg";
const image2 = "/drive/Photography/yash deshmukh.jpeg";
const image3 = "/drive/Photography/vilas tolsankar.jpeg";
const image4 = "/drive/Photography/uday patil.jpeg";
const image5 = "/drive/Photography/shivaji dhute.jpeg";
const image6 = "/drive/Photography/sayali mohite.jpeg";
const image7 = "/drive/Photography/mahendra kolhe.jpeg";
const image8 = "/drive/Photography/kalpesh nukte.jpeg";
const image9 = "/drive/Photography/chetan kawre.jpeg";
const image10 = "/drive/Photography/Amit darunkar.jpeg";

const image11 = "/drive/drawing/IMG_6397.JPG";
const image12 = "/drive/drawing/IMG_6398.JPG";
const image13 = "/drive/drawing/IMG_6399.JPG";
const image14 = "/drive/drawing/IMG_6400.JPG";
const image15 = "/drive/drawing/IMG_6401.JPG";
const image16 = "/drive/drawing/IMG_6402.JPG";
const image17 = "/drive/drawing/IMG_6403.JPG";
const image18 = "/drive/drawing/IMG_6404.JPG";
const image19 = "/drive/drawing/IMG_6405.JPG";
const image20 = "/drive/drawing/IMG_6406.JPG";

function Gallery() {
  const [datalist, setDataList] = React.useState([]);
  // const [photographer, setPhotographer] = React.useState([image1,image2,image3,image4,image5,image6,image7,image8,image9,image10]);
  const [token, setToken] = React.useState();

  React.useEffect(() => {
    const token = sessionStorage.getItem("token");
    setToken(token);
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    const methods = "gallary/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      console.log("image : ", res);
      setDataList(res.data);
    });
  };
  const [gallary, setgallery] = React.useState([
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
  ]);

  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          {/* <Header /> */}
          <NewHeader />
          <div className="row header-pad-blog">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 top-class header-pad2">
              <div>
                <p className="future-p9">
                  {datalist[0]?.line1}
                  {/* Ready to make an impact? <br></br> Upload a photo and join
                  <br></br>the Community */}
                </p>
                {/* <p className="gal-p1">
                <span>Photo Subject:</span>
                <span>Water Bodies of Pune</span>
              </p> */}
                {/* <p className="gal-p2">16-02-2023 - 16-03-2023</p> */}
              </div>
              <div className="gal-btn">
                {!token ? (
                  <Link
                    onClick={() => {
                      alert("Please login!");
                    }}
                  >
                    <button className="btn-lets2">{datalist[0]?.line2} </button>
                  </Link>
                ) : (
                  <Link to="/English/UploadPhoto">
                    <button className="btn-lets2">{datalist[0]?.line2} </button>
                  </Link>
                )}
                {/* <button className="btn-lets">Know About Competition</button> */}
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 top-class new-class">
              <div className="about-first-sec1">
                <img
                  src={imgeurl + datalist[0]?.line3}
                  className="rotated-image"
                />
              </div>
              <div className="about-first-img">
                {/* <img src="../../images/about poly.png" /> */}
              </div>
            </div>
          </div>
          {/* <ImagesSection
            images={datalist[0]?.line4}
            name="Winners of Competition"
            routename="WinnersOfCompetition"
          />
          <ImagesSection
            images={datalist[0]?.line5}
            // name="Vintage Pune"
            routename="VintagePune"
          /> */}
          {/* <section className="gal-sec-1">
            <p className="gal-p3">
              <span>नागरिकांचे फोटो</span>
              <Link>
                <span className="gal-p4">अल्बम पहा</span>
              </Link>
            </p>

            <div className="container-flex">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src="../../images/market.png"
                        className="gallery-images"
                      />
                    </div>
                    <div className="col-md-6 paddingAlldiv">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-6">
                              <img
                                src="../../images/ganesh.png"
                                className="gallery-images"
                              />
                            </div>
                            <div className="col-6">
                              <div className="row">
                                <div className="col-xl-12">
                                  <img
                                    src="../../images/road.png"
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-xl-12">
                                  <img
                                    src="../../images/dhol.png"
                                    className="gallery-images gal-dhol"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <ImagesSection
            images={datalist[0]?.line6}
            name="Photos by PMC"
            routename="PhotosByPmc"
          /> */}

          {/* <section className="gal-sec-2" >
            <div className="row">
              <h1>Top Photographs from the PuneRé Photography Competition</h1>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/Amit Darunkar.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/amit deshpande.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/chetan kawre.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/kalpesh nukte.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/mahendra kolhe.jpeg" />
              </div>
              <div className="col-xl-1 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/sayali mohite.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/shivaji dhute.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/uday patil.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/vilas tolsankar.jpeg" />
              </div>
              <div className="col-xl-2 col-md-2 col-sm-2">
                <img style={{ width: '100%' }} src="/drive/Photography/yash deshmukh.jpeg" />
              </div>
            </div>
          </section> */}

          {/* पुणेरे फोटोग्राफी स्पर्धेतील शीर्ष छायाचित्रे */}
          <section className="gal-sec-1">
            <div className="row">
              <p className="padding-Top">
                <span className="custom-span-title">
                  पुणेरे फोटोग्राफी स्पर्धेतील शीर्ष छायाचित्रे
                </span>
              </p>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/Marathi/Photographs">
                  <span className="gal-p4 padding-Top">अल्बम पहा</span>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle">
                          <img src={image1} className="gallery-images" />
                        </div>
                        <div className="col-md-6 paddingAlldiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={image2}
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={image3}
                                        className="gallery-images"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* पुणेरे रेखाचित्रे स्पर्धेतील उत्कृष्ट रेखाचित्रे */}
          <section className="gal-sec-1">
            <div className="row">
              <p className="padding-Top">
                <span className="custom-span-title">
                  पुणेरे रेखाचित्रे स्पर्धेतील उत्कृष्ट रेखाचित्रे
                </span>
              </p>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/Marathi/Drawing">
                  <span className="gal-p4 padding-Top">अल्बम पहा</span>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle">
                          <img src={image11} className="gallery-images" />
                        </div>
                        <div className="col-md-6 paddingAlldiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={image12}
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={image13}
                                        className="gallery-images"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* बातम्या आणि अपडेट्स */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>बातम्या आणि अपडेट्स</h1>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी
                  यांच्या हस्ते उद्घाटन
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NikxIJtA3t0"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी यांच्या हस्ते उद्घाटन"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  पुणेरे लोगो व
                  मॅसकॉट&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color: "rgb(222 215 222)" }}>.</span>
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/I4UJ7eiGptk"
                  title="PuneRé लोगो व मॅसकॉट अनावरण"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </section> */}

          {/* बातम्या आणि अपडेट्स Carousel*/}
          <section>
            <div className="row">
              <h1 className="padding-left">बातम्या आणि अपडेट्स</h1>
              <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
              >
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/NikxIJtA3t0"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी यांच्या हस्ते उद्घाटन"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी
                    यांच्या हस्ते उद्घाटन
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/I4UJ7eiGptk"
                    title="PuneRé लोगो व मॅसकॉट अनावरण"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">पुणेरे लोगो व मॅसकॉट</p>
                </div>
              </Carousel>
            </div>
          </section>

          {/* व्यस्तता */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>व्यस्तता</h1>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे चित्रकला स्पर्धा</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ahH0lHVGaxg"
                  title="PuneRé चित्रकला स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे आयोजित 'आठवणींचा ओल्वा'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ySC5hVeROTc"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे आयोजित 'आठवणींचा ओल्वा'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/zuHPYHzOxOw"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे आयोजित 'आठवणींचा ओल्वा'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bCu71cddo_Y"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे फोटोग्राफी स्पर्धा</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/8zgp7MGX0M4"
                  title="PuneRé फोटोग्राफी स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bzOpGosv7hU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/s94rzVTEsKc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/5mnFMgcQVeU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/se0IY1gFXpc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/J9sIXb1ADlM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/kVv-Go1Agig"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/gs2tTLeo-So"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/x3wmwEd_7-4"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NlU_Uk_If0A"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ay-27y7fUqI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/4eIEJ0lSYWY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/l30y4yoGNvI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/pr1LNbkDD3w"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/aH1PrJjBxYQ"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/3yEgq7rTmVM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/9awZi5s3VhY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/tEK4V32kMbw"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Omw9W0SoPWc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/cvBwCnzZyHY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/1s00ArncAXs"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </section> */}

          {/* व्यस्तता Carousel */}
          <section>
            <h1 className="padding-left">व्यस्तता</h1>
            <Carousel
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              stopOnHover={true}
            >
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ahH0lHVGaxg"
                  title="PuneRé चित्रकला स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">पुणेरे चित्रकला स्पर्धा</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ySC5hVeROTc"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">पुणेरे आयोजित 'आठवणींचा ओल्वा'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/zuHPYHzOxOw"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">पुणेरे आयोजित 'आठवणींचा ओल्वा'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bCu71cddo_Y"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">पुणेरे आयोजित 'आठवणींचा ओल्वा'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/8zgp7MGX0M4"
                  title="PuneRé फोटोग्राफी स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">पुणेरे फोटोग्राफी स्पर्धा</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bzOpGosv7hU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/s94rzVTEsKc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/5mnFMgcQVeU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/se0IY1gFXpc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/J9sIXb1ADlM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/kVv-Go1Agig"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/gs2tTLeo-So"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/1s00ArncAXs"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/cvBwCnzZyHY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Omw9W0SoPWc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/tEK4V32kMbw"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/9awZi5s3VhY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/3yEgq7rTmVM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/aH1PrJjBxYQ"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/pr1LNbkDD3w"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/l30y4yoGNvI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/4eIEJ0lSYWY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ay-27y7fUqI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NlU_Uk_If0A"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/x3wmwEd_7-4"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  पुणेरे रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार
                </p>
              </div>
            </Carousel>
          </section>

          {/* जाणीव */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>जाणीव</h1>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>मुळा मुठा नदी पुनरुज्जीवन प्रकल्प म्हणजे नेमके काय?</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/iURHI49mFSM"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प नेमका काय आहे?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती
                  आहे का?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ZVwgMPsych8"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती आहे का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर
                  जाणून घ्या.
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bX0ic2UncGo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर जाणून घ्या."
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी
                  करण्यात आली होती?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/11k2X3NwJCI"
                  title="नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी करण्यात आली होती?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/fNeZSN6stgw"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा
                  पद्धतीने केले जाणार?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/qr1zrGQ0tzw"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा पद्धतीने केले जाणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ
                  मिळालाय
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/jtxW6iyFlp8"
                  title="पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ मिळालाय"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात
                  क्राँक्रिटचे बांधकाम होणार का?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/yLrEEiCZdJo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात क्राँक्रिटचे बांधकाम होणार का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा
                  होणार?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Qa2NBybscbo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा होणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे
                  महापालिकेबाबत ही माहिती हवीच!
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/SCcHjruoedg"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे महापालिकेबाबत ही माहिती हवीच!"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/E3HbbIxLPCo"
                  title="मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी
                  पुनरुज्जीवन प्रकल्प{" "}
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Lnpv0ObqAIs"
                  title="या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी पुनरुज्जीवन प्रकल्प"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे
                  महत्त्वाचे उद्देश
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/7MtPaG2oJeI"
                  title="पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे महत्त्वाचे उद्देश"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>पुणेरे प्रतिज्ञा उपक्रम</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/R-gA9qKSDRw"
                  title="PuneRé  प्रतिज्ञा उपक्रम"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून
                  वेळोवेळी दिली जाणार माहिती
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/rFu1PSUy3yQ"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून वेळोवेळी दिली जाणार माहिती"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/RvJZUP-vQBE"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <h3>
                  मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक
                  अभ्यास केले गेले आहेत?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/7WXhsB6iLGE"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक अभ्यास केले गेले आहेत?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </section> */}

          {/* जाणीव Carousel */}
          <section>
            <div className="row">
              <h1 className="padding-left">जाणीव</h1>
              <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
              >
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/iURHI49mFSM"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प नेमका काय आहे?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्प म्हणजे नेमके काय?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/ZVwgMPsych8"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती आहे का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती
                    आहे का?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/bX0ic2UncGo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर जाणून घ्या."
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर
                    जाणून घ्या.
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/11k2X3NwJCI"
                    title="नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी करण्यात आली होती?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी
                    करण्यात आली होती?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/fNeZSN6stgw"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/qr1zrGQ0tzw"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा पद्धतीने केले जाणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा
                    पद्धतीने केले जाणार?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/jtxW6iyFlp8"
                    title="पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ मिळालाय"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ
                    मिळालाय
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/yLrEEiCZdJo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात क्राँक्रिटचे बांधकाम होणार का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात
                    क्राँक्रिटचे बांधकाम होणार का?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/Qa2NBybscbo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा होणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा
                    होणार?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/SCcHjruoedg"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे महापालिकेबाबत ही माहिती हवीच!"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे
                    महापालिकेबाबत ही माहिती हवीच!
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/E3HbbIxLPCo"
                    title="मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/Lnpv0ObqAIs"
                    title="या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी पुनरुज्जीवन प्रकल्प"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी
                    पुनरुज्जीवन प्रकल्प
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/7MtPaG2oJeI"
                    title="पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे महत्त्वाचे उद्देश"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे
                    महत्त्वाचे उद्देश
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/R-gA9qKSDRw"
                    title="PuneRé  प्रतिज्ञा उपक्रम"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">पुणेरे प्रतिज्ञा उपक्रम</p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/rFu1PSUy3yQ"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून वेळोवेळी दिली जाणार माहिती"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून
                    वेळोवेळी दिली जाणार माहिती
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/RvJZUP-vQBE"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/7WXhsB6iLGE"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक अभ्यास केले गेले आहेत?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक
                    अभ्यास केले गेले आहेत?
                  </p>
                </div>
              </Carousel>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
