import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./loginotp.css";
const Loginotp = () => {
  const [otp, setOtp] = useState('');
  useEffect(() => {});


  const handleOtpChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]{0,6}$/.test(inputValue)) {
      setOtp(inputValue);
    }
  };

  return (
    <>
      <div className="container-fluid class-sign">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="logo-head">
                      <Link to="/">
                        <img src="../../images/logo.png" />
                      </Link>
                    </div>
                    <div className="lo-form-div">
                      <form>
                      <div className="form-group">
                          <label>OTP</label>
                          <input
                            className="form-control"
                            type="text"
                            name="otp"
                            id="otp"
                            placeholder="Enter otp"
                            required
                            value={otp}
                            onChange={handleOtpChange}
                          />
                        </div>
                        <Link to="/">
                          <button className="loginotp-btn">Login</button>
                        </Link>
                        <div className="login-bottom">
                          <p>Haven't Receiverd OTP?</p>
                          <a href="">Re-send OTP</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pad-none">
                <div className="lo-right-side-image">
                  <div className="lo-quotes-class">
                    <p>
                      The river is not just water, it's a life.<br></br>
                      <p className="sadguru">- Sadguru Jaggi Vasudev</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loginotp;
