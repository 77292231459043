import React, { useState } from "react";
import "../../English/userdashboard/userdashboard.css";
import { NavLink } from "react-router-dom";
import ServiceStore from "../../utils/ServiceStore";
import { AiFillDelete } from "react-icons/ai";
import moment from 'moment'


const Dashboard = () => {
  const [datalist, setdatalist] = React.useState([]);
  const [ApproveCount, setApproveCount] = React.useState(0);
  const [PendingCount, setPendingCount] = React.useState(0);
  const [rejecteCount, setRejecteCount] = React.useState(0);
  const [All, setAll] = React.useState(0);

  const [formdatalist, setformdatalist] = React.useState([]);
  const [formApproveCount, setformApproveCount] = React.useState(0);
  const [formPendingCount, setformPendingCount] = React.useState(0);
  const [allForum, setAllForum] = useState();
  const [forumDecline, setForumDecline] = useState();

  const [imgAll, setImgAll] = useState([]);
  const [imgApproveCount, setImgApproveCount] = useState(0);
  const [imgPending, setImgPending] = useState(0);
  const [imgReject, setImgReject] = useState(0);
  const [allImageCount, setAllImageCount] = useState(0);

  const [deletedata, setdeletedata] = React.useState("");
  const [refresh, setRefresh] = React.useState(false)

  React.useEffect(() => {
    getAlldataFile();
  }, [deletedata, refresh]);

  const Deletedata = (e, index) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (shouldDelete) {
        console.log('Record deleted!');
        console.log("indexxx : ", index)
        const methods = "writeblogtopic/";
        new ServiceStore().DeleteData(methods, index._id).then((res) => {
          setdeletedata("true");
          setRefresh(!refresh)
          alert("Forum deleted successfully")
        });
    }else {
      // User clicked cancel, do nothing or handle accordingly
      console.log('Deletion canceled.');
    }
  };
  const getAlldataFile = () => {
    const auth = JSON.parse(sessionStorage.getItem("items"));
    const data = { userID: auth._id };
    const bodyData2 = {
      language: "marathi",
      status: "Approve",
    };
    const bodyData = {
      language: "marathi",
      status: "Pending",
    };
    const bodyData3 = {
      language: "marathi",
      status: "Reject",
    };
    const bodyData4 = {
      language: "marathi",
      status: "All",
    };
    const bodyData5 = {
      userID : auth._id,
      status : "Approve"
    }

    const imageApprove = {
      userID : auth._id,
      status : "All"
    }

    const bodyData7 = {
      userID : auth._id,
      status : "Pending"
    }
    const bodyData6 = {
      userID : auth._id,
      status : "Reject"
    }
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData4)
      .then((res) => {
        console.log("res", res);
        if (res.response.data != undefined) {
          setAll(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("writeblogtopic/UserIDWiseMarathi", data)
      .then((res) => {
        console.log("res.response", res.response.data);
        setdatalist(res.response.data);
      });
    new ServiceStore()
      .GetDataBYFilter("writeblogtopic/UserIDWiseApproveMarathi", data)
      .then((res) => {
        console.log("UserIDWiseApproveEnglish", res.response.data);
        if (res.response.data != undefined) {
          setApproveCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData3)
      .then((res) => {
        if (res.response.data != undefined) {
          setRejecteCount(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData)
      .then((res) => {
        console.log("res", res);
        if (res.response.data != undefined) {
          setPendingCount(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("writeblogtopic/UserIDWisePendingMarathi", data)
      .then((res) => {
        console.log("UserIDWisePendingEnglish", res.response.data);
        if (res.response.data != undefined) {
          setPendingCount(res.response.data);
        }
      });

    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseMarathi", data)
      .then((res) => {
        console.log("res.response", res.response.data);
        setformdatalist(res.response.data);
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseApproveMarathi", data)
      .then((res) => {
        console.log("UserIDWiseApproveEnglish", res.response.data);
        if (res.response.data != undefined) {
          setformApproveCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWisePendingMarathi", data)
      .then((res) => {
        console.log("UserIDWisePendingEnglish", res.response.data);
        if (res.response.data != undefined) {
          setformPendingCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseAllEnglish", data)
      .then((res) => {
        if (res.response.data != undefined) {
          console.log("all forums")
          setAllForum(res.response.data);
        }
      });


    //Images request
    
     //All Image Array
     new ServiceStore()
     .GetDataBYFilter('UserUploadImage/UserIDWise',data)   
     .then((res) => {
       // console.log("all image", res.response.data);
       if (res.response.data != undefined) {
         console.log("All images Request", res.response.data)
         setImgAll(res.response.data);
       }
     });

     //All Image Count
     new ServiceStore()
     .GetDataBYFilter('UserUploadImage/UserIDWise',imageApprove)   
     .then((res) => {
       if (res.response.data != undefined) {
         console.log("All images Count", res.response.data)
         setAllImageCount(res.response.data.length);
       }
     });
     //Approve Image Count
   new ServiceStore()
     .GetDataBYFilter('UserUploadImage/UserIDWiseApprove',bodyData5)
     .then((res) => {
       if (res.response.data != undefined) {
         console.log("Approve Images", res);
         setImgApproveCount(res.response.data.length);
       }
     });
   new ServiceStore()
     .GetDataBYFilter('UserUploadImage/UserIDWiseReject',bodyData6)
     .then((res) => {
       console.log("Rejected Images", res);
       if (res.response.data != undefined) {
         setImgReject(res.response.data);
       }
     });
   new ServiceStore()
     .GetDataBYFilter('UserUploadImage/UserIDWisePending',bodyData7)
     .then((res) => {
       if (res.response.data != undefined) {
         console.log("Pending Images", res);
         setImgPending(res.response.data);
       }
     });

  };
  return (
    <>
      <div className="container-fluid Btp-body">
        <div className="row">
          <div className="Btp-header">
            <span className="Btp-upload">डॅशबोर्ड</span>
            <span className="Btp-cross">
              <NavLink to="/Marathi/Home">
                <img src="../../images/crossArr.png" />
              </NavLink>
            </span>
          </div>
          <div className="warpper-dash">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              value="blogs"
              checked
            />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              value="images"
              checked
            />
            <input
              className="radio"
              id="three"
              name="group"
              type="radio"
              value="forum"
            />

            <div className="tabs">
              <label className="tab" id="one-tab" for="one">
                ब्लॉग
              </label>
              <label className="tab" id="two-tab" for="two">
                फोटो
              </label>
              <label className="tab" id="three-tab" for="three">

                मंच विषय
              </label>
            </div>

            <div className="panels">
              <div className="panel" id="one-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{ApproveCount}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{PendingCount}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{All}</h3>
                          <p>All Blog Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{rejecteCount}</h3>
                          <p>Declined Request</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">
                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              <div className="cell">Actions</div>
                            </div>
                            {datalist.length > 0 &&
                              (datalist.map((item, index) => {
                                return (
                                  <div className="row row-check" key={item._id}>
                                    <div className="cell" data-title="Username">
                                      <a>{item.title}</a>
                                    </div>
                                    <div className="cell" data-title="Email">
                                      <span>{moment(item.updatedAt).format('L')}</span>
                                    </div>
                                    {item.status == "Approve" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="approve-btn">
                                          {item.status}
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Pending" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="pending-btn">
                                          Pending
                                        </button>
                                      </div>
                                    )}
                                       {item.status == "Reject" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                          Rejected
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}
                                    <div className="cell">
                                      <span
                                        onClick={(e) => Deletedata(e, item)}
                                      >
                                        <AiFillDelete />
                                      </span>
                                    </div>
                                  </div>
                                );
                              }))
                            }
                          </div>
                          {datalist .length <= 0 && (
                            <div className="not-found-div">
                              <h1>
                                माहिती आढळली नाही</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="two-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{imgApproveCount ? imgApproveCount : 0}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{imgPending ? imgPending : 0}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{allImageCount ? allImageCount : 0}</h3>
                          <p>All Image Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{imgReject ? imgReject : 0}</h3>
                          <p>Reject Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">

                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              {/* <div className="cell">Actions</div> */}
                            </div>
                            {imgAll?.length > 0 &&
                              imgAll.map((item, index) => {
                                return (
                                  <div className="row row-check" key={item._id}>
                                    <div className="cell" data-title="Username">
                                      <a>{item.title}</a>
                                    </div>
                                    <div className="cell" data-title="Email">
                                      <span>{moment(item.updatedAt).format('L')}</span>
                                    </div>
                                    {item.status == "Approve" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="approve-btn">
                                          {item.status}
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Pending" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="pending-btn">
                                          Pending
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}
                                    {/* <div className="cell">
                                      <span
                                        onClick={(e) => Deletedata(e, item)}
                                      >
                                        <AiFillDelete />
                                      </span>
                                    </div> */}
                                    {/* <div className="cell" data-title="Active">
                                      <div className="menu-nav">
                                        <div className="dropdown-container">
                                          <div className="three-dots"></div>
                                          <div className="dropdown">
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>

                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                );
                              })
                            }
                          </div>

                          {imgAll?.length <= 0 && (
                            <div className="not-found-div">
                              <h1>माहिती आढळली नाही</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="three-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{formApproveCount ? formApproveCount : 0}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{formPendingCount ? formPendingCount : 0}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{allForum ? allForum : 0}</h3>
                          <p>All Forum Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{forumDecline ? forumDecline : 0}</h3>
                          <p>Declined Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">

                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              {/* <div className="cell">Actions</div> */}
                            </div>
                            {formdatalist.length > 0 && formdatalist.map((item, index) => {
                              return (
                                <div className="row row-check" key={item._id}>
                                  <div className="cell" data-title="Username">
                                    <a>{item.title}</a>
                                  </div>
                                  <div className="cell" data-title="Email">
                                    <span>{moment(item.updatedAt).format('L')}</span>
                                  </div>
                                  {item.status == "Approve" && (
                                    <div className="cell" data-title="Password">
                                      <button className="approve-btn">
                                        {item?.status}
                                      </button>
                                    </div>
                                  )}
                                  {item.status == "Pending" && (
                                    <div className="cell" data-title="Password">
                                      <button className="pending-btn">
                                        Pending
                                      </button>
                                    </div>
                                  )}
                                  {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}
                                  {/* <div className="cell">
                                    <span
                                      onClick={(e) => Deletedata(e, item)}
                                    >
                                      <AiFillDelete />
                                    </span>
                                  </div> */}
                                  {/* <div className="cell" data-title="Active">
                                    <div className="menu-nav">
                                      <div className="dropdown-container">
                                        <div className="three-dots"></div>
                                        <div className="dropdown">
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>

                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              );
                            })}
                          </div>

                          {formdatalist.length <= 0 && (
                            <div className="not-found-div">
                              <h1> माहिती आढळली नाही</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
