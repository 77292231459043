import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
// eslint-disable-next-line no-unused-vars
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./photosByPmc.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Button } from "react-bootstrap";
import { IoArrowBackOutline, IoArrowUp } from "react-icons/io5";

function PhotosByPmc() {
  const [datalist, setdatalist] = React.useState([]);
  React.useEffect(() => {
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    const methods = "gallary/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };

  const imagePerRow = 3;
  const [next, setNext] = React.useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };
  const [isLoading, setIsLoading] = React.useState(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This smooth scroll is optional, you can remove it if you want an instant scroll
    });
  };

  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          <section className="gal-sec-1">
            <div className="headerDiv">
              <div>
                <p className="gal-p3">
                  <span>Photos By Pmc</span>
                </p>
              </div>
              <div>
                <Link to="/Marathi/Gallery">
                  <div>
                    <IoArrowBackOutline
                      style={{
                        float: "right",
                        height: "5em",
                        width: "3em",
                        marginRight: "10em",
                      }}
                    />
                    <span className="back-btn1">Back</span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
        {/* <div className="Ups-button" style={{ marginBottom: "10px" }}>
          <Link to="/English/Gallery">
            <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button>
          </Link>
        </div> */}
        <div className="container-flex">
          <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {datalist?.slice(0, next)?.map((image, index) =>
              image.line6.map((imageData, index) => (
                <div className="col-md-4">
                  <img
                    src={imgeurl + imageData.originalname}
                    style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "16px",
                    }}
                  />
                </div>
              ))
            )}
          </div>
          {/* <div className="text-center">
            {next < datalist?.length && (
              <Button
                variant="primary"
                size="lg"
                onClick={handleMoreImage}
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  textAlign: "center",
                }}
              >
                Load more
              </Button>
            )}
          </div> */}
        </div>
        {isLoading && (
          <div className="spinnerDiv">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div
          onClick={scrollToTop}
          className="Ups-button"
          style={{ marginBottom: "10px", cursor: "pointer" }}
        >
          <IoArrowUp
            style={{
              float: "right",
              height: "5em",
              width: "3em",
              marginRight: "10em",
            }}
          />
          <span className="back-btn1">Top</span>
          {/* <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button> */}
        </div>
        {/* <div className="Ups-button" style={{ marginBottom: "10px" }}>
          <Link to="/English/Gallery">
            <button className="btn-1">Back to Gallery</button>
          </Link>
        </div> */}
      </div>
    </>
  );
}

export default PhotosByPmc;
