import React,{useState} from "react";
import "./CheckAvailability.css";
import Header from "../header/header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import { toast } from "react-toastify";
import Seat from './Seat';
import { useHistory } from "react-router-dom";

function CheckOut() {
  const history = useHistory();

  const [amount, setAmount] = useState(2000)
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [comment, setComment] = useState("")
  const [selectedSeats, setSelectedSeats] = useState([]);
  const timeSlots = [
    '6pm - 7pm',
    '7pm - 8pm',
    '8pm - 9pm',
    '9pm - 10pm',
    '10pm - 11pm',
    '11pm - 12am', // corrected from '11pm - 12pm'
    '12am - 1am', // corrected from '12pm - 1am,'
    '1am - 2am',
    '2am - 3am',
    '3am - 4am',
    '4am - 5am',
    '5am - 6am',
    '6am - 7am',
    '7am - 8am',
    '8am - 9am',
    '9am - 10am',
  ];

  React.useEffect(() => {
      const hisData = history.location.state;
      const count  = hisData?.details[0]
      setSelectedSeats(timeSlots[count])
  }, []);


  const getLoginData = sessionStorage.getItem("items");
  const getData =  JSON.parse(getLoginData);
  const loginName = getData?.name;
  const getMobile = getData?.mobile
  const getEmail = getData?.email

  const handlePayNow = async () =>{
    if(getLoginData === null){
      alert("Please login First")
    }else{
      try {
        const methods = "razorpay/create-order";
        const SpaceBookinghresponse = await new ServiceStore().InsertData(methods, {
          amount : amount,
          name : loginName,
          mobile : getMobile,
          email : getEmail,
          paymentType: "spacebooking"
        })
  
        const options = {
          // rzp_test_Fr5bJUCgd9WQb5
          // rzp_live_cnuR4Ya8EYRJqw
          key: 'rzp_test_Fr5bJUCgd9WQb5',
          amount: amount * 100,
          currency: "INR",
          description: 'Purchase description',
          order_id: SpaceBookinghresponse?.id,

          handler: function (response) {
            if(response?.razorpay_payment_id){
              history.push('/English/SpaceBookingSuccess');
              updateDonationData("success",SpaceBookinghresponse?.response?.donationId)
            }
          },
        };
  
        const rzp1 = new window.Razorpay(options);
        rzp1.on(`payment.failed`, function(response){
          if(response.error){
            history.push('/English/SpaceBookingFail')
            updateDonationData("failure",SpaceBookinghresponse?.response?.donationId)
          }
        })
        rzp1.open();
  
      } catch (error) {
        console.error('Error creating order:', error);
        // Handle error, e.g., show an error message
      }
    }
  }

  const updateDonationData = async(status, Id) =>{
    const methods = "donations/updateDonation"
    const updateSuccess = await new ServiceStore().InsertData(methods,{
      donationId: Id,
      paymentStatus: status
    })
  } 

  const handleSubmitForm = (e) => {
    e.preventDefault();
  };


  
  return (
    <>
      <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
            {/* <Header /> */}
            <NewHeader />
            <div className="spb2-mainbody">
              <div className="row spb2-carouselrow">
             
                <div className=" col-xl-6  spb-cartextbox">
                  {/* <div className="spb2-cartexthead">
                    <span className="spb2-cth1">Garden</span>
                  
                  </div> */}
                  <div className="spb2-details">
                    <span className="spb2-area">
                      Area<br></br>
                      <p>3000 Sqft</p>
                    </span>
                    <span className="spb2-area">
                      Capacity<br></br>
                      <p>500-800 people</p>
                    </span>
                    <span className="spb2-area">
                      Rent<br></br>
                      <p>2000/- per </p>
                    </span>
                  </div>
                  <div className="spb2-locd">Location Details</div>
                  <div className="spb2-locdetails">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.<br></br> Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s,
                  </div>
                 
                  {/* <div className="spb2-suitable">Suitable for</div>
                  <div className="spb2-suitable1">
                    Birthday Parties, Get Together, Family, Collage Functions
                    etc.{" "}
                  </div> */}
                  
                </div>
                <div className=" col-xl-6  spb2-carimgbox">
                <div className="spb2-details">
                    <span className="spb2-area">
                      Time<br></br>
                      <p>{selectedSeats}</p>
                    </span>
                   
                  </div>
                  <div className="spb2-locd"></div>
                  <div className="spb2-locdetails">
                   
                  </div>
                    <div className="spb2-button">
                    <button className="spb2-btn1" onClick={handlePayNow}>Pay Now</button>
                  </div>
                </div>
              </div>
             
            </div>

            <div  class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="cntainer" style={{ padding: '1rem' }}>
                <div className="contact-us1" >
                  <h2 style={{"color":"green"}}>Are You Sure You Want To Booked ?</h2> <br></br>
                 
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', fontSize: '15px' }}>
                      <button style={{"marginTop":"10px" ,"marginRight":"5%"}} type="submit" data-dismiss="modal" aria-label="Close" onClick={handleSubmitForm}>CheckOut</button>

                      <button style={{"marginTop":"10px"}}  type="button" data-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>

            <Footer />
          </div>
        </section>
      </div>
    </>
  );
}

export default CheckOut;
