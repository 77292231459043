
import React from "react";
import "../../Marathi/forum/forum.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import "../../Marathi/facilities/facility.css"
import "./Donation.css"

import { useHistory } from "react-router-dom";

const Donation = () => {
  const [selectedValue, setSelectedValue] = React.useState('');
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [selectedValue1, setSelectedValue1] = React.useState('');
  const handleSelectChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };

  const [selectedValue2, setSelectedValue2] = React.useState('');
  const handleSelectChange2 = (event) => {
    setSelectedValue2(event.target.value);
  };

  const [selectedValue3, setSelectedValue3] = React.useState('');
  const handleSelectChange3 = (event) => {
    setSelectedValue3(event.target.value);
  };



  const handleInputChange = (e) => {
    console.log("Input changed:", e.target.name, e.target.value);

    const { name, value } = e.target;

   
  }
  const handleSubmitForm = (e) => {
    e.preventDefault();
    // const body = {
    //   name: name,
    //   mobile: mobile,
    //   comment: comment,
    // }
    // const method = "/contactus/add"
    // console.log("data :", body)
    // new ServiceStore().InsertData(method, body).then((res) => {
    //   console.log(res)
    // })
  }

  return (
    <>
      <div className="outer-changes">
      
        <section className="facility-section">
          <div className="upper-wrapper">
          <NewHeader />
          <div
              className="row header-pad-fac"
              style={{ margin: "0", padding: "0" }}
            >
              </div>
           </div>
          
           <form>
                  <div className="col-xl-6 col-lg-6" style={{ "marginTop": "100px", "marginBottom": "100px" }}>
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Name</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        required
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Contact Number</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={handleInputChange}
                        required
                        placeholder="Your Contact Number" />
                    </div>

                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Email Address</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="email"
                        name="email"
                        required
                        onChange={handleInputChange}
                        placeholder="Your Email" />
                    </div>
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Mailing Address (Street Address, City, State, PIN Code)</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="address"
                        name="address"
                        required
                        onChange={handleInputChange}
                        placeholder="Your Mailing Address" />
                    </div>
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}> Donation Amount</label>
                     
                         <select id="selectBox" value={selectedValue} onChange={handleSelectChange} className='contactUsFormInput'>
                            <option value="">Select...</option>
                            <option value="Onetimedonation">One-time donation</option>
                            <option value="Recurringdonation">Recurring donation (monthly/yearly)</option>
                          </select>
                    </div>
                   
                    </div>
                    <div className="col-xl-6 col-lg-6 " style={{ "marginTop": "100px", "marginBottom": "100px" }}>
          
                   
                   
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Amount</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="Amount"
                        name="Amount"
                        required
                        onChange={handleInputChange}
                        placeholder="Your Amount" />
                    </div>

                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Donation Purpose</label>
                     
                         <select id="selectBox" value={selectedValue1} onChange={handleSelectChange1} className='contactUsFormInput'>
                            <option value="">Select...</option>
                            <option value="General">General River Rejuvenation Fund</option>
                            <option value="Specific">Specific Project (please specify)</option>
                          </select>
                    </div>

                    
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Anonymous Donation</label>
                     
                         <select id="selectBox" value={selectedValue2} onChange={handleSelectChange2} className='contactUsFormInput'>
                            <option value="">Select...</option>
                            <option value="Yes">Yes, I wish to remain anonymous</option>
                            <option value="No">No, you can acknowledge my donation publicly.</option>
                          </select>
                    </div>
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>How did you hear about our river rejuvenation project?</label>
                     
                         <select id="selectBox" value={selectedValue3} onChange={handleSelectChange3} className='contactUsFormInput'>
                            <option value="">Select...</option>
                            <option value="SocialMedia">Social Media (e.g., Facebook, Twitter)</option>
                            <option value="LocalNews">Local News or Media</option>
                            <option value="Mouth">Word of Mouth</option>
                            <option value="Municipal">Municipal Corporation Website</option>
                            <option value="CommunityEvent">Community Event or Fair</option>
                            <option value="Internet">Internet Search (e.g., Google)</option>
                            <option value="Other">Other (please specify)</option>
                          </select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', fontSize: '15px' }}>
                      <button type="submit" onClick={handleSubmitForm}  data-dismiss="modal" aria-label="Close">Submit</button>
                    </div>
                 
                    </div>
                 
                  </form>
           
          </section>
        </div>
        <Footer />
    </>
  );
};

export default Donation;
