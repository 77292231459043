import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import ServiceStore from "../../utils/ServiceStore";
import { toast } from "react-toastify";

const Footer = () => {
  const [subscribeMail, setSubscribeMail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [comment, setComment] = useState("");
  const [alreadySubscribedError, setAlreadySubscribedError] = useState(false);

  const handleSubscribeMail = (e) => {
    const email = e.target.value;
    setSubscribeMail(email);
    // Simple regex pattern to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  const submitSubscribeMail = () => {
    const body = {
      email: subscribeMail,
    };
    try {
      new ServiceStore()
        .InsertData("subscribemail/subscribeMail", body)
        .then((res) => {
          console.log("res : ", res);
          if (res.response.status === 1) {
            toast.success("Subscribed Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            window.scrollTo(0, 0);
            setSubscribeMail("");
          } else {
            setAlreadySubscribedError(res);
            toast.warn("Already Subscribed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        })
        .catch((error) => {
          console.error("error:", error);
          toast.warn("Already Subscribed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } catch (error) {
      console.error("error:", error);
      toast.warn("Already Subscribed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const body = {
      name: name,
      mobile: mobile,
      comment: comment,
    };
    const method = "/contactus/add";
    console.log("data :", body);
    console.log("!body.name :", !body.name);
    console.log("!body.mobile :", !body.mobile);
    console.log("!body.mobile :", !body.mobile.length == 10);
    console.log("!body.comment :", !body.comment);
    if (!body.name) {
      toast.warn("Please enter name", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);
    } else if (!body.mobile || body.mobile.length !== 10) {
      toast.warn("Please enter valid Mobile number", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);
    } else if (!body.comment) {
      toast.warn("Please enter comment", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      new ServiceStore()
        .InsertData(method, body)
        .then((response) => {
          if (response.response.status === 1) {
            toast.success("Response submitted Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setName("");
            setMobile("");
            setComment("");
            console.log(response);
          } else {
            toast.warn("Already submitted", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.error("Error response:", response);
          }
        })
        .catch((error) => {
          toast.warn("Already submitted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (value == "") {
        setName("");
      }
      const onlyCharacters = value.replace(/[^A-Za-z ]/g, "");
      setName(onlyCharacters);
    } else if (name === "mobile") {
      if (value == "") {
        setMobile("");
      }
      const onlyNumbers = value.replace(/[^0-9]/g, "");
      if(onlyNumbers.length <= 10)
      {
        setMobile(onlyNumbers);
      }
    } else if (name === "comment") {
      setComment(value);
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="container-flex">
          <div className="row " style={{ margin: "0", padding: "0" }}>
            <div className=" col-lg-3 col-md-6">
              <img src="/images/PMC-Logo.png" className="footer-img pb-3" />

              <img src="/images/punere-logo.png" className="footer-img" />
            </div>
            <div className="col-lg-4 f-inp-text col-md-6">
              <div className="widget3">
                <h5>आमच्याशी संपर्क साधा</h5>

                <div className="">
                  <h4>कर मुक्त</h4>
                  <a href="tel:९१४६३९३३९३">
                    <p className="f-no">९१४६३९३३९३</p>
                  </a>
                </div>
                <div className="">
                  <h4>ईमेल</h4>
                  <a href="mailto:puneriver@punecorporation.org">
                    <p className="f-no"> puneriver@punecorporation.org</p>
                  </a>
                </div>
                <div>
                  <h5>पत्ता</h5>
                  <p style={{ fontSize: "16px", color: "#fff" }}>
                    पुणे महानगरपालिका, तिसरा मजला मुख्य पीएमसी इमारत, शिवाजीनगर-
                    411005
                  </p>
                </div>
              </div>

              <div className="input-group f-inp-grup ">
                <input
                  type="text"
                  className="form-control footr-inp"
                  placeholder="ईमेल आयडी टाका"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={subscribeMail}
                  onChange={handleSubscribeMail}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary f-btn"
                    type="button"
                    onClick={submitSubscribeMail}
                  >
                    सदस्यता घ्या
                  </button>
                </div>
              </div>
              <br></br>
              {alreadySubscribedError && (
                <div style={{ color: "red", fontSize: "14px" }}>
                  {alreadySubscribedError}
                </div>
              )}
              {!isEmailValid && (
                <div className="" style={{ color: "red", fontSize: "14px" }}>
                  ईमेल वैध नाही
                </div>
              )}
            </div>
            {/* <div className="col-lg-3 col-md-6">
                       
                    </div> */}
            <div className=" col-lg-2 col-md-6">
              <div className="widget4">
                <h5>द्रुत दुवे</h5>
                <ul>
                  <li
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <Link
                    // to="/Marathi/ContactUs"
                    >
                      आमच्याशी संपर्क साधा
                    </Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Home">प्रतिबद्धता</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Gallery">गॅलरी</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Forum">मंच</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Blog">ब्लॉग</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Survey">सर्वेक्षण</Link>
                  </li>

                  <li>
                    <Link to="/Marathi/Spacebooking2">जागा बुकिंग</Link>
                    {/* <Link>&nbsp;</Link> */}
                  </li>
                  <li>
                    {/* <Link> */}
                    {/* <a
                      href="https://www.surveymonkey.com/r/8RVVFXZ"
                      target="_blank"
                    >
                      देणगी
                    </a> */}
                    <Link to="/Marathi/Donation" target="_blank">देणगी</Link>
                    {/* </Link> */}
                    {/* <Link>&nbsp;</Link> */}
                  </li>

                 

                  <li>
                    <Link to="/Marathi/PrivacyPolicy" target="_blank">गोपनीयता धोरण</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/SAQ" target="_blank">वारंवार विचारले जाणारे प्रश्न</Link>
                  </li>
                  <li>
                 
                 <Link to="/Marathi/CookiePolicy" target="_blank">कुकीपॉलिसी</Link>
               </li>
                </ul>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6">
              <div className="widget4">
                <h5 style={{ display: "flex" }}>आम्हाला फॉलो करा</h5>
                {/* <ul style={{ display: 'flex', justifyContent: 'space-evenly' }}> */}
                {/* <li> */}
                <div>
                  <a href="https://www.facebook.com/PMCPuneRe" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#3F51B5"
                        d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                      ></path>
                    </svg>
                  </a>
                </div>
                {/* </li> */}
                {/* <li> */}
                <div>
                  <a
                    href="https://www.instagram.com/pmcpunere/"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <radialGradient
                        id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1"
                        cx="19.38"
                        cy="42.035"
                        r="44.899"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#fd5"></stop>
                        <stop offset=".328" stop-color="#ff543f"></stop>
                        <stop offset=".348" stop-color="#fc5245"></stop>
                        <stop offset=".504" stop-color="#e64771"></stop>
                        <stop offset=".643" stop-color="#d53e91"></stop>
                        <stop offset=".761" stop-color="#cc39a4"></stop>
                        <stop offset=".841" stop-color="#c837ab"></stop>
                      </radialGradient>
                      <path
                        fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                        d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                      ></path>
                      <radialGradient
                        id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2"
                        cx="11.786"
                        cy="5.54"
                        r="29.813"
                        gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#4168c9"></stop>
                        <stop
                          offset=".999"
                          stop-color="#4168c9"
                          stop-opacity="0"
                        ></stop>
                      </radialGradient>
                      <path
                        fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                        d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
                      ></path>
                      <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                      <path
                        fill="#fff"
                        d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
                      ></path>
                    </svg>
                  </a>
                </div>
                {/* </li> */}
                {/* <li> */}
                <div style={{marginLeft:"-4px"}}>
                  <a href="https://twitter.com/PmcPuneRe" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg"    x="0px"
                      y="0px" viewBox="0 0 48 48" width="48px" height="48px" clip-rule="evenodd" baseProfile="basic"><path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd"/><path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"/><polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"/><polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"/></svg>

                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#03a9f4"
                        d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3	c-0.951,0.559-2.671,1.156-3.793,1.372C29.789,13.808,24,14.755,24,20v2c-4,0-7.9-3.047-10.327-6c-2.254,3.807,1.858,6.689,2.327,7	c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27	c0.626,1.935,3.773,2.958,5.928,3c-2.617,2.029-7.126,2.079-8,1.977c8.989,5.289,22.669,0.513,21.982-12.477	C34.95,18.818,35.342,18.104,36,17.12"
                      ></path>
                    </svg> */}
                  </a>
                </div>
                {/* </li> */}
                {/* <li> */}
                <div>
                  <a
                    href="https://youtube.com/@punere7309?si=jojl-Tw8bX0d4_He"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#FF3D00"
                        d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                      ></path>
                      <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
                    </svg>
                  </a>
                </div>
                {/* </li> */}
                {/* <li> */}
                <div>
                  <a
                    href="https://whatsapp.com/channel/0029Va6ICoeK5cDOCvGCgb2X"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#fff"
                        d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                      ></path>
                      <path
                        fill="#cfd8dc"
                        d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                      ></path>
                      <path
                        fill="#40c351"
                        d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                      ></path>
                      <path
                        fill="#fff"
                        fill-rule="evenodd"
                        d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
                {/* </li> */}
                {/* </ul><br></br> */}

                {/* <ul>
                  <li>
                    <Link to="/Marathi/AboutUs">आमच्याबद्दल</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Facility">सुविधा</Link>
                  </li>
                  <li>
                    <Link to="/">जागा बुकिंग</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Blog">ब्लॉग</Link>
                    <Link>&nbsp;</Link>
                  </li>
                  <li>
                    <Link to="/Marathi/Survey">सर्वेक्षण</Link>
                    <Link>&nbsp;</Link>
                  </li>
                </ul> */}
              </div>
            </div>
            {/* <div className=" col-lg-3 col-md-6">
              <div className="widget4 last-bloc">
                <ul style={{ marginTop: "-20px" }}>
                  <li>
                    <Link to="/English/AboutUs">आमच्याबद्दल</Link>
                  </li>
                  <li>
                    <Link to="/English/Facility">सुविधा</Link>
                  </li>
                  <li>
                    <Link to="/">जागा बुकिंग</Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="cntainer" style={{ padding: "1rem" }}>
                <div className="contact-us1">
                  <h2 style={{ fontSize: "3rem" }}>संपर्क साधा</h2>
                  <form>
                    <div className="form-group1">
                      <label
                        className="contactUsFormLabels"
                        style={{ fontSize: "16px" }}
                      >
                        नाव
                      </label>
                      <br></br>
                      <input
                        className="contactUsFormInput"
                        type="text"
                        id="name"
                        name="name"
                        required
                        value={name}
                        onChange={handleInputChange}
                        placeholder="तुमचे नाव"
                      />
                    </div>
                    <br></br>

                    <div className="form-group1">
                      <label
                        className="contactUsFormLabels"
                        style={{ fontSize: "16px" }}
                      >
                        मोबाईल नंबर
                      </label>
                      <br></br>
                      <input
                        className="contactUsFormInput"
                        type="tel"
                        id="mobile"
                        name="mobile"
                        required
                        value={mobile}
                        onChange={handleInputChange}
                        placeholder="आपला भ्रमणध्वनी क्रमांक"
                      />
                    </div>
                    <br></br>

                    <div className="form-group1">
                      <label
                        className="contactUsFormLabels"
                        style={{ fontSize: "16px" }}
                      >
                        येथे टिप्पणी द्या
                      </label>
                      <br></br>
                      <textarea
                        className="contactUsFormInput"
                        type="text"
                        id="comment"
                        name="comment"
                        required
                        value={comment}
                        onChange={handleInputChange}
                        placeholder="येथे टिप्पणी द्या"
                      ></textarea>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "15px",
                      }}
                    >
                      <button type="submit" onClick={handleSubmitForm}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
