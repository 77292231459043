import React from 'react'
import { useEffect } from "react";
import { useState } from "react";
import { IoArrowBackOutline, IoArrowUp } from "react-icons/io5";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SavedCommunityForums = () => {
    const [datalist, setdatalist] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const pageSize = 3;

    const [itemsToShow, setItemsToShow] = useState(4);

    const fetchData = () => {
        setIsLoading(true);
        setTimeout(() => {
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const newData = datalist.slice(startIndex, endIndex);
          setData((prevData) => [...prevData, ...newData]);
          setIsLoading(false);
        }, 500);
      };
      const imagePerRow = 3;
      const [next, setNext] = React.useState(imagePerRow);
      const handleMoreImage = () => {
        setNext(next + imagePerRow);
      };
    
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // This smooth scroll is optional, you can remove it if you want an instant scroll
        });
      };
  return (
    <>
    <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          <section className="gal-sec-1">
            <div className="headerDiv">
              <div>
                <p className="gal-p3">
                  <span>
                  जतन केलेले समुदाय मंच
                  </span>
                </p>
              </div>
              <div>
                <Link to="/Marathi/Favourites">
                  <div>
                    <IoArrowBackOutline
                      style={{
                        float: "right",
                        height: "5em",
                        width: "3em",
                        marginRight: "10em",
                      }}
                    />
                    <span className="back-btn1">मागे</span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>

        <div className="container-flex">
          <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {/* {gallary?.map((image, index) => (
              <div className="col-md-4">
                <img
                  // src={image4}
                  // className="gallery-images"
                />
              </div>
            ))} */}
            {/* {gallary.slice(0, itemsToShow).map((image) => { */}
              return (
                <div className="col-xl-3 col-md-3 col-sm-2">
                  {/* <img style={{ width: "100%" }} src={image} /> */}
                </div>
              );
            {/* })} */}
          </div>
        </div>

  
        <div className="btn-group-div">
          <div
            onClick={scrollToTop}
            // className="Ups-button"
            style={{ marginBottom: "10px", cursor: "pointer" }}
          >
            <IoArrowUp
              style={{
                float: "right",
                height: "5em",
                width: "3em",
                marginRight: "10em",
              }}
            />
            <span className="back-btn1">Top</span>
     
          </div>
          <div>
            {/* {itemsToShow < gallary.length && ( */}
              <button
                onClick={() => {
                  setItemsToShow(itemsToShow + 4);
                }}
                className="view-more-btn"
              >
                अधिक पहा
              </button>
            {/* )} */}
          </div>
        </div>
      </div>
      </>
  )
}

export default SavedCommunityForums