import React, { Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AboutUs from "../English/aboutus/aboutus";
import AccountSetting from "../English/accountSetting/accountSetting";
import Blog from "../English/blog/blog";
import BlogD from "../English/blogdetail/blogD";
import Blogtopic from "../English/blogTopic/blogtopic";
import Facility from "../English/facilities/facility";
import Forum from "../English/forum/forum";
import ForumBlog from "../English/forumblogs/forumblog";
import Gallery from "../English/gallery/gallery";
import HomePage from "../English/homepage/homepage";
import LogIn from "../English/logIn/logIn";
import Loginotp from "../English/loginOtp/loginotp";
import SignUp from "../English/signup/signup";
import SpaceBooking from "../English/spaceBooking/spaceBooking";
import Spacebooking2 from "../English/spaceBooking2/spacebooking2";
import Survey from "../English/Survey/survey";
import SurveyDetail from "../English/SurveyDetail/surveyDetail";
import Uploadstatus from "../English/uploadFeedback/uploadstatus";
import UploadPhoto from "../English/uploadPhoto/uploadPhoto";
import Dashboard from "../English/userdashboard/userdashboard";
import WriteBlog from "../English/writeblog/writeblog";
import Publication from "../English/publication/publication";
import WinnersOfCompetition from "../English/winnersofcompetition/winnerOfCompetition";
import VintagePune from "../English/vintagepune/vintagePune";
import PhotosByPmc from "../English/photosbypmc/photosByPmc";
import Verify from "../English/verify/Verify";
import Photographs from "../English/photograph/Photographs";
import Drawing from "../English/drawing/Drawing";
import ErrorPage from "../English/errorPage/ErrorPage";
import ContactUs from "../English/contactUs/ContactUs";
import Favourites from "../English/favourites/Favourites";
import SavedBlogs from "../English/savedItems/SavedBlogs";
import SavedCommunityForums from "../English/savedItems/SavedCommunityForums";
import PrivacyPolicy from "../English/privacyPolicy/PrivacyPolicy";
import BlogByPmc from "../English/blogByPmc/BlogByPmc";
import Notifications from "../English/notifications/Notifications";

import SAQ from "../English/saq/SAQ";
import Donation from "../English/Donation/Donation";
import CheckAvailability from "../English/CheckAvailability/CheckAvailability";
import CookiePolicy from "../English/CookiePolicy/CookiePolicy";
import CheckOut from "../English/CheckAvailability/CheckOut";
import DonationSuccess from "../English/Donation/DonationSuccess";
import DonationFail from "../English/Donation/DonationFail";
import SpaceBookingSuccess from "../English/CheckAvailability/SpaceBookingSuccess";
import SpaceBookingFail from "../English/CheckAvailability/SpaceBookingFail";

function PrivateRoute() {
  const auth = sessionStorage.getItem("items");
  // console.log("auth",auth)
  return (
    <Suspense fallback={<div>Loading</div>}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return auth ? (
            <Redirect to="/English/Home" />
          ) : (
            <Redirect to="/English/Home" />
          );
        }}
      />

      <Route path={`/English/Home`} component={HomePage}></Route>
      <Route path={`/English/AboutUs`} component={AboutUs}></Route>
      <Route path={`/English/Facility`} component={Facility}></Route>
      <Route path={`/English/Blog`} component={Blog}></Route>
      <Route path={`/English/BlogD`} component={BlogD}></Route>
      <Route path={`/English/Gallery`} component={Gallery}></Route>
      <Route path={`/SignUp`} component={SignUp}></Route>
      <Route path={`/Loginotp`} component={Loginotp}></Route>
      <Route path={`/LogIn`} component={LogIn}></Route>
      <Route path={`/English/Forum`} component={Forum}></Route>
      <Route path={`/English/SurveyDetail`} component={SurveyDetail}></Route>
      <Route path={`/English/Survey`} component={Survey}></Route>
      <Route path={`/English/Blogtopic`} component={Blogtopic}></Route>
      <Route path={`/English/UploadPhoto`} component={UploadPhoto}></Route>
      <Route path={`/English/ForumBlog`} component={ForumBlog}></Route>
      <Route path={`/English/Uploadstatus`} component={Uploadstatus}></Route>
      <Route path={`/English/WriteBlog`} component={WriteBlog}></Route>
      <Route path={`/English/SpaceBooking`} component={SpaceBooking}></Route>
      <Route path={`/English/Spacebooking2`} component={Spacebooking2}></Route>
      <Route path={`/English/Dashboard`} component={Dashboard}></Route>
      <Route path={`/English/Uploadstatus`} component={Uploadstatus}></Route>
      <Route path={`/English/ContactUs`} component={ContactUs}></Route>
      <Route path={`/English/Favourites`} component={Favourites}></Route>
      <Route path={`/English/SavedBlogs`} component={SavedBlogs}></Route>
      <Route path={`/English/SavedCommunityForums`} component={SavedCommunityForums}></Route>
      <Route path={`/English/PrivacyPolicy`} component={PrivacyPolicy}></Route>
      <Route path={`/English/BlogByPmc`} component={BlogByPmc}></Route>
      <Route path={`/English/Notifications`} component={Notifications}></Route>
      <Route path={`/English/SAQ`} component={SAQ}></Route>
      <Route path={`/English/Donation`} component={Donation}></Route>
      <Route path={`/English/DonationSuccess`} component={DonationSuccess}></Route>
      <Route path={`/English/DonationFail`} component={DonationFail}></Route>
      <Route path={`/English/CheckAvailability`} component={CheckAvailability}></Route>
      <Route path={`/English/CookiePolicy`} component={CookiePolicy}></Route>
      <Route path={`/English/CheckOut`} component={CheckOut}></Route>
      <Route path={`/English/SpaceBookingSuccess`} component={SpaceBookingSuccess}></Route>
      <Route path={`/English/SpaceBookingFail`} component={SpaceBookingFail}></Route>
      <Route path={`/Verify`} component={Verify}></Route>
      <Route
        path={`/English/AccountSetting`}
        component={AccountSetting}
      ></Route>
      <Route path={`/English/Publication`} component={Publication}></Route>
      <Route
        path={`/English/WinnersOfCompetition`}
        component={WinnersOfCompetition}
      ></Route>
      <Route path={`/English/VintagePune`} component={VintagePune}></Route>
      <Route path={`/English/PhotosByPmc`} component={PhotosByPmc}></Route>
      <Route path={`/English/Photographs`} component={Photographs}></Route>
      <Route path={`/English/Drawing`} component={Drawing}></Route>
      {/* <Route path={`*`} component={ErrorPage}></Route> */}


    </Switch>
    </Suspense>
  );
}

export default withRouter(PrivateRoute);
