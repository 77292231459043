import React from "react";
import { Link } from "react-router-dom";
import "../../Marathi/writeblog/writeblog.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { useHistory } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { toast } from "react-toastify";

function WriteBlog() {
  const [datalist, setdatalist] = React.useState([]);
  const [conservationtopiclist, setconservationtopiclist] = React.useState([]);

  const [language, setlanguage] = React.useState("");
  const [image, setImage] = React.useState("");

  const [categoryId, setCategoryId] = React.useState("");
  const [title, settitle] = React.useState("");
  const [subtitle, setsubtitle] = React.useState("");
  const [subtitleError, setSubtitleError] = React.useState("");

  const history = useHistory();
  React.useEffect(() => {
    getAlldataFile();
  }, []);
  const logEvent = (e) => {
    setsubtitle(e.editor.getData());
    setSubtitleError("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!language) {
      toast.warn("कृपया भाषा निवडा..", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!image) {
      toast.warn("कृपया एक प्रतिमा निवडा.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!categoryId) {
      toast.warn("कृपया ब्लॉग श्रेणी निवडा.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!title) {
      toast.warn("कृपया शीर्षक प्रविष्ट करा.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!subtitle) {
      setSubtitleError("कृपया मुख्य मजकूर प्रविष्ट करा.!!"); // Set the subtitle error message
      return;
    } else {
      setSubtitleError(""); // Clear the subtitle error message if the subtitle is not empty
    }
    const auth = JSON.parse(sessionStorage.getItem("items"));

    const ImageData = new FormData();
    ImageData.append("file", image);
    new ServiceStore()
      .InsertData("writeblogtopic/ImageUpload", ImageData)
      .then((res) => {
        console.log("res: ", res);
        if (res.response.status === 1) {
          const methods = "writeblogtopic/";
          const data = {
            language: language,
            username: auth.name,
            title: title,
            categoryId: categoryId,
            subtitle: subtitle,
            status: "Pending",
            userID: auth._id,
            image: res.response.data.imagename,
          };
          new ServiceStore().InsertData(methods, data).then((res) => {
            toast.success("तुमची ब्लॉग विनंती यशस्वीरित्या पाठवली आहे.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history.push("/Marathi/Blog");
          });
        } else {
          toast.error(
            "काहीतरी चूक झाली, कृपया काही वेळानंतर पुन्हा प्रयत्न करा!",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      });
  };
  const handleIdChange = (event) => {
    const categoryId = event.target.value;
    // const categoryId = event.target.getAttribute('data-tag');

    setCategoryId(categoryId);
  };
  const getAlldataFile = () => {
    const methods = "/blogcategory/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };
  const langaugeChange = (event) => {
    const categoryId = event.target.value;
    // const categoryId = event.target.getAttribute('data-tag');

    setlanguage(categoryId);
  };

  const ImagehandleIdChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="container-fluid Btp-body">
      <div className="row">
        <div className="Btp-header">
          <span className="Btp-upload">ब्लॉग लिहा</span>
          <span className="Btp-cross">
            <Link to="/Marathi/Blog">
              <img src="../../images/crossArr.png" />
            </Link>
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row Btp-row">
            <div className="col-xl-3 col-md-3">
              <div className="form-group input-font">
                <label>
                  भाषा
                  <select
                    className="form-control input-font"
                    onChange={langaugeChange}
                    required
                  >
                    <option value="">भाषा निवडा</option>

                    <option value="english">इंग्रजी</option>
                    <option value="marathi">मराठी</option>
                  </select>
                </label>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="form-group">
                <label>प्रतिमा</label>
                <input
                  className="form-control input-font"
                  style={{ padding: "18px 55px" }}
                  type="file"
                  onChange={ImagehandleIdChange}
                  required
                />
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="form-group">
                <label>
                  श्रेणी
                  <select
                    className="form-control input-font"
                    onChange={handleIdChange}
                    required
                  >
                    <option value="">ब्लॉग श्रेणी निवडा</option>
                    {datalist.map((item) => (
                      <option
                        key={item._id}
                        value={item._id}
                        data-tag={item._id}
                      >
                        {item.category}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="form-group">
                <label>शीर्षक</label>

                <input
                  className="form-control input-font"
                  type="text"
                  name="otp"
                  id="otp"
                  placeholder="विषयाचे शीर्षक प्रविष्ट करा"
                  required
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="Btp-editorheader">मुख्य मजकूर</div>
          <div className="row Btp-row">
            <div className="col-xl-12 col-md-12">
              <div className="form-group input-font">
                <CKEditor
                  style={{ fontSize: "15px" }}
                  onSelectionChange={logEvent}
                  data={subtitle}
                />
              </div>
              {subtitleError && (
                <div
                  className="error-message"
                  style={{ fontSize: "16px", color: "red" }}
                >
                  {subtitleError}
                </div>
              )}
            </div>
          </div>
          {/* <div className='Btp-editor'>
        <div className='Btp-toolbar'>
        <span className="Btp-para">
                    <button
                      className="btn btn-para  dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                    >
                      Paragraph
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="#">HTML</a>
                      </li>
                      <li>
                        <a href="#">CSS</a>
                      </li>
                      <li>
                        <a href="#">JavaScript</a>
                      </li>
                    </ul>
                  </span>
      <span className='Btp-para2'>
      <span className='Btp-bold'><a>B</a></span>
      <span className='Btp-italic'><a>I</a></span>
      <span className='Btp-underline'><a>U</a></span>
      </span>
      
      <span className='span-display'>
      <span className='Btp-minimize'>
      <a> <img src='../../images/minimize.png' /></a>
    
      </span>
      <span className='Btp-Uppertext'><a>AG</a></span>
      <span className='Btp-Midtext'><a>Ag</a></span>
      <span className='Btp-Lowertext'><a>ag</a></span>
      </span>
      
      <span className='Btp-align'>
      <a> <img src='../../images/align.png' /></a>
      </span>
      <span className='Btp-list'>
      <a> <img src='../../images/list.png' /></a>
      </span>
      <span className='Btp-images'>
      <a> <img src='../../images/Images.png' /></a>
      </span>
      <span className='Btp-link'>
      <a> <img src='../../images/Link.png' /></a>
      </span>
        
        
      
        </div>
        <textarea cols="2" rows="10" id="rules" onChange={(e) => setsubtitle(e.target.value)} ></textarea>
        </div> */}

          <div className="Btp-button">
            {/* <Link to="/Marathi/Gallery">
              <button className="Btp-btn1"> Save</button>
            </Link> */}
            {/* <Link to="#">
            <button className="Btp-btn2" type="submit" >Submit</button>
            </Link> */}
            <input type="submit" value="submit" className="Btp-btn2" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default WriteBlog;
