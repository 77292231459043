import React, { useEffect, useState } from "react";
import "../../English/userdashboard/userdashboard.css";
import { Link } from "react-router-dom";
import ServiceStore from "../../utils/ServiceStore";
import DeleteIcon from '@mui/icons-material/Delete';
import { AiFillDelete } from "react-icons/ai";
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert';


const Dashboard = () => {
  const [datalist, setdatalist] = React.useState([]);
  const [ApproveCount, setApproveCount] = React.useState(0);
  const [PendingCount, setPendingCount] = React.useState(0);
  const [rejecteCount, setRejecteCount] = React.useState(0);
  const [All, setAll] = React.useState(0);

  const [formdatalist, setformdatalist] = React.useState([]);
  const [formApproveCount, setformApproveCount] = React.useState(0);
  const [formPendingCount, setformPendingCount] = React.useState(0);
  const [activeTab, setActiveTab] = useState("one");
  const [allForum, setAllForum] = useState();
  const [forumDecline, setForumDecline] = useState();

  const [imgAll, setImgAll] = useState([]);
  const [imgApproveCount, setImgApproveCount] = useState(0);
  const [imgPending, setImgPending] = useState(0);
  const [imgRejectCount, setImgRejectCount] = useState(0);
  const [allImageCount, setAllImageCount] = useState(0);

  const [deletedata, setdeletedata] = React.useState("");
  const [refresh, setRefresh] = React.useState(false)

  React.useEffect(() => {
    getAlldataFile();
    const savedTab = sessionStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, [deletedata,refresh]);

  useEffect(() => {
    // Save the active tab in local storage whenever it changes
    sessionStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const getAlldataFile = () => {
    const auth = JSON.parse(sessionStorage.getItem("items"));
    const data = { userID: auth._id };
    // const methods = "writeblogtopic/filterRequest";
     
    const bodyData2 = {
      language: "english",
      status: "Approve",
    };
    const bodyData = {
      language: "english",
      status: "Pending",
    };
    const bodyData3 = {
      language: "english",
      status: "Reject",
    };
    const bodyData4 = {
      language: "english",
      status: "All",
    };
    const bodyData5 = {
      userID : auth._id,
      
    }
    const imageApprove = {
      userID : auth._id,
      status : "All"
    }
    const bodyData6 = {
      userID : auth._id,
      status : "Reject"
    }
    const bodyData7 = {
      userID : auth._id,
      status : "Pending"
    }

    new ServiceStore()
      .GetDataBYFilter("writeblogtopic/UserIDWiseEnglish", data)
      .then((res) => {
        console.log(">>>>>>>>>>>>>>>>>", res)
        setdatalist(res.response.data);
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData2)
      .then((res) => {
        console.log("res", res);
        if (res.response.data != undefined) {
          setApproveCount(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData)
      .then((res) => {
        console.log("res", res);
        if (res.response.data != undefined) {
          setPendingCount(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData3)
      .then((res) => {
        console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,",res)
        if (res.response.data != undefined) {
          setRejecteCount(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter(`writeblogtopic/filterRequest/${auth._id}`, bodyData4)
      .then((res) => {
        console.log("res", res);
        if (res.response.data != undefined) {
          setAll(res.response.data.count);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseEnglish", data)
      .then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!", res)
        setformdatalist(res.response.data);
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseApproveEnglish", data)
      .then((res) => {
        if (res.response.data != undefined) {
          setformApproveCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWisePendingEnglish", data)
      .then((res) => {
        if (res.response.data != undefined) {
          setformPendingCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseAllEnglish", data)
      .then((res) => {
        if (res.response.data != undefined) {
          console.log("all forums",res)
          setAllForum(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter("conservationtopic/UserIDWiseRejectEnglish", data)
      .then((res) => {
        if (res.response.data != undefined) {
          console.log(".......................",res)
          setForumDecline(res.response.data);
        }
      });

    //Images request

    //All Image Array
    new ServiceStore()
      .GetDataBYFilter('UserUploadImage/UserIDWise',data)   
      .then((res) => {
        // console.log("all image", res.response.data);
        if (res.response.data != undefined) {
          console.log("All images Request", res.response.data)
          setImgAll(res.response.data);
        }
      });

      //All Image Count
      new ServiceStore()
      .GetDataBYFilter('UserUploadImage/UserIDWise',imageApprove)   
      .then((res) => {
        if (res.response.data != undefined) {
          console.log("All images Count", res.response.data)
          setAllImageCount(res.response.data.length);
        }
      });

      //Approve Image Count
    new ServiceStore()
      .GetDataBYFilter('UserUploadImage/UserIDWiseApprove',bodyData5)
      .then((res) => {
        if (res.response.data != undefined) {
          console.log("Approve Images", res);
          setImgApproveCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter('UserUploadImage/UserIDWiseReject',bodyData6)
      .then((res) => {
        console.log("Rejected Images", res);
        if (res.response.data != undefined) {
          setImgRejectCount(res.response.data);
        }
      });
    new ServiceStore()
      .GetDataBYFilter('UserUploadImage/UserIDWisePending',bodyData7)
      .then((res) => {
        if (res.response.data != undefined) {
          console.log("Pending Images", res);
          setImgPending(res.response.data);
        }
      });
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const Deletedata = (e, index) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (shouldDelete) {
      console.log('Record deleted!');
      console.log("indexxx : ", index)
      const methods = "writeblogtopic/";
    new ServiceStore().DeleteData(methods, index._id).then((res) => {
      setdeletedata("true");
      setRefresh(!refresh)
      alert("Blog deleted successfully")
    });
    } else {
      // User clicked cancel, do nothing or handle accordingly
      console.log('Deletion canceled.');
    }
  
       

  };
const onCancel =  (e, index) => {
}
  return (
    <>
      <div className="container-fluid Btp-body">
        <div className="row">
          <div className="Btp-header">
            <span className="Btp-upload">User Dashboard</span>
            <span className="Btp-cross">
              <Link to="/">
                <img src="../../images/crossArr.png" />
              </Link>
            </span>
          </div>
          <div className="warpper-dash">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              value="blogs"
              // checked
              checked={activeTab === "one"}
              onChange={() => handleTabChange("one")}
            />
            <input
              className="radio"
              id="two"
              name="group"
              type="radio"
              value="images"
              checked={activeTab === "two"}
              onChange={() => handleTabChange("two")}
            />
            <input
              className="radio"
              id="three"
              name="group"
              type="radio"
              value="forum"
              checked={activeTab === "three"}
              onChange={() => handleTabChange("three")}
            />

            <div className="tabs">
              <label className="tab" id="one-tab" for="one">
                Blogs
              </label>
              <label className="tab" id="two-tab" for="two">
                Images
              </label>
              <label className="tab" id="three-tab" for="three">
                Forum Topics
              </label>
            </div>
            {/* <div className="tabs">
              <label
                className={`tab ${activeTab === "one" ? "active" : ""}`}
                id="one-tab"
                onClick={() => handleTabChange("one")}
              >
                Blogs
              </label>
              <label
                className={`tab ${activeTab === "two" ? "active" : ""}`}
                // className="tab active"
                id="two-tab"
                onClick={() => handleTabChange("two")}
              >
                Images
              </label>
              <label
                className={`tab ${activeTab === "three" ? "active" : ""}`}
                id="three-tab"
                onClick={() => handleTabChange("three")}
              >
                Forum Topics
              </label>
            </div> */}

            <div className="panels">
              <div className="panel" id="one-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{ApproveCount}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{PendingCount}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{All}</h3>
                          <p>All Blog Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{rejecteCount ? rejecteCount : 0}</h3>
                          <p>Declined Request</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">
                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              <div className="cell">Actions</div>
                            </div>
                           
                            {datalist.length > 0 &&
                            (datalist.map((item, index) => {
                                return (
                                  <div className="row row-check" key={item._id}>
                                    <div className="cell" data-title="Username">
                                      <a>{item.title}</a>
                                    </div>
                                    <div className="cell" data-title="Email">
                                      <span>{moment(item.updatedAt).format('L')}</span>
                                    </div>
                                    {item.status == "Approve" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="approve-btn">
                                          {item.status}
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Pending" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="pending-btn">
                                          Pending
                                        </button>
                                      </div>
                                    )}
                                       {item.status == "Reject" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                          Rejected
                                        </button>
                                      </div>
                                    )}
                                     {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}

                                    <div className="cell">
                                      <span
                                        onClick={(e) => Deletedata(e, item)}
                                      >
                                        <AiFillDelete />
                                      </span>
                                    </div>
                                    {/* <div className="cell" data-title="Active">
                                      <div className="menu-nav">
                                        <div className="dropdown-container">
                                          <div className="three-dots"></div>
                                          <div className="dropdown">
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>

                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                    </div> */}

                                  </div>
                                );
                              }))
                            }
                          </div>
                          {datalist.length <= 0 && (
                            <div className="not-found-div">
                              <h1>No Data Found</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="two-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{imgApproveCount ? imgApproveCount : 0}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{imgPending ? imgPending : 0}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{allImageCount ? allImageCount : 0}</h3>
                          <p>All Image Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{imgRejectCount ? imgRejectCount : 0}</h3>
                          <p>Reject Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">
                        
                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              {/* <div className="cell">Actions</div> */}
                            </div>
                            {imgAll?.length > 0 &&
                              (imgAll.map((item, index) => {
                                return (
                                  <div className="row row-check" key={item._id}>
                                    <div className="cell" data-title="Username">
                                      <a>{item.title}</a>
                                    </div>
                                    <div className="cell" data-title="Email">
                                      <span>{moment(item.updatedAt).format('L')}</span>
                                    </div>
                                    {item.status == "Approve" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="approve-btn">
                                          {item.status}
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Pending" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="pending-btn">
                                          Pending
                                        </button>
                                      </div>
                                    )}
                                      {item.status == "Reject" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                          Rejected
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}

                                    {/* <div className="cell">
                                      <span
                                        onClick={(e) => Deletedata(e, item)}
                                      >
                                        <AiFillDelete />
                                      </span>
                                    </div> */}
                                    {/* <div className="cell" data-title="Active">
                                      <div className="menu-nav">
                                        <div className="dropdown-container">
                                          <div className="three-dots"></div>
                                          <div className="dropdown">
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>

                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                        </div>
                                        </div>
                                      </div>
                                    </div> */}

                                  </div>
                                );
                              })) 
                            }
                          </div>
                          
                          {imgAll?.length <= 0 && (
                            <div className="not-found-div">
                              <h1>No Data Found</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="three-panel">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard">
                          <h3>{formApproveCount ? formApproveCount : 0}</h3>
                          <p>Approved Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-orange">
                          <h3>{formPendingCount ? formPendingCount : 0}</h3>
                          <p>Pending Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-blue">
                          <h3>{allForum ? allForum : 0}</h3>
                          <p>All Forum Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-3">
                        <div className="card-dashboard card-red">
                          <h3>{forumDecline ? forumDecline : 0}</h3>
                          <p>Declined Requests</p>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="wrapper-table">
                  
                          <div className="table">
                            <div className="row header blue row-check">
                              <div className="cell">Name</div>
                              <div className="cell">Date Posted</div>
                              <div className="cell">Status</div>
                              {/* <div className="cell">Actions</div> */}
                            </div>
                            {formdatalist.length > 0 ?
                             (formdatalist.map((item, index) => {
                              return (
                                <div className="row row-check" key={item._id}>
                                  <div className="cell" data-title="Username">
                                    <a>{item.title}</a>
                                  </div>
                                  <div className="cell" data-title="Email">
                                    <span>{moment(item.updatedAt).format('L')}</span>
                                  </div>
                                  {item.status == "Approve" && (
                                    <div className="cell" data-title="Password">
                                      <button className="approve-btn">
                                        {item?.status}
                                      </button>
                                    </div>
                                  )}
                                  {item.status == "Pending" && (
                                    <div className="cell" data-title="Password">
                                      <button className="pending-btn">
                                        Pending
                                      </button>
                                    </div>
                                  )}
                                    {item.status == "Reject" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                          Rejected
                                        </button>
                                      </div>
                                    )}
                                    {item.status == "Delete" && (
                                      <div
                                        className="cell"
                                        data-title="Password"
                                      >
                                        <button className="rejected-btn">
                                           Deleted
                                        </button>
                                      </div>
                                    )}
                                     {/* <div className="cell">
                                      <span
                                        onClick={(e) => Deletedata(e, item)}
                                      >
                                        <AiFillDelete />
                                      </span>
                                    </div> */}
                                  {/* <div className="cell" data-title="Active">
                                    <div className="menu-nav">
                                      <div className="dropdown-container">
                                        <div className="three-dots"></div>
                                        <div className="dropdown">
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>

                                          <a className="a-tag" href="#">
                                            <div className="div-class">
                                              dropdown 1
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                   </div>
                            );
                          })):(<div>No Data Found</div>)}
                                </div>
                            
                         
                          {formdatalist.length <= 0 && (
                            <div className="not-found-div">
                              <h1 style={{textAlign:'center'}}>No Data Found</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Dashboard;
