
import React, { useState,useCallback, useEffect } from "react";
import "../../English/forum/forum.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import "../../English/facilities/facility.css"
import "./Donation.css"
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import useRazorpay from "react-razorpay";
import RazorpayOptions from 'react-razorpay'
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js';


const Donation = () => {
  const history = useHistory();

  const [createOrderData, setCreateOrderData] = useState()

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [donationType, setDonationType] = useState("");
  const [annonymousDonation, setAnnonymousDonation] = useState("");
  const [donationPurpose, setDonationPurpose] = useState("");
  const [hearAbout, setHearAbout] = useState("");
  const [amount, setAmount] = useState(100);

  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [donationpurposeError, setDonationpurposeError] = useState(false);
  const [donationTypeError, setDonationTypeError] = useState(false);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    // Remove non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setAmount(numericValue);
    const isValid = /^\d+$/.test(numericValue) && numericValue[0] !== '0';
    setAmountError(!isValid);
  };

  const handleValidate = () =>{
    let FormValid = true;

    if(name.length === 0){
      FormValid = false;
      setNameError(true);
    }else if(mobile.length === 0){
      FormValid = false;
      setMobileError(true);
    }else if(email.length === 0){
      FormValid = false;
      setEmailError(true);
    }else if(donationType.length === 0){
      FormValid = false;
      setDonationTypeError(true)
    }else if(amount.length === 0){
      FormValid = false;
      setAmountError(true)
    } else if(donationPurpose.length === 0){
      FormValid = false;
      setDonationpurposeError(true)
    }

     return FormValid
  }

  const createOrder = async () => {
    if(handleValidate()){
      try {
        const methods = "razorpay/create-order";
          const createOrderResponse = await new ServiceStore().InsertData(methods, {
            amount: amount,
            name: name,
            mobile: mobile,
            email: email,
            address: address,
            donationType: donationType,
            purpose: donationPurpose,
            isAnonymous: false,
            source: hearAbout,
            paymentType: "donation"
          });

        const options = {
          // rzp_test_Fr5bJUCgd9WQb5
          // rzp_live_cnuR4Ya8EYRJqw
          key: 'rzp_test_Fr5bJUCgd9WQb5',
          amount: amount * 100,
          currency: "INR",
          description: 'Purchase description',
          order_id: createOrderResponse?.id,

          handler: function (response) {
            if(response?.razorpay_payment_id){
              history.push('/English/DonationSuccess');
              updateDonationData("success",createOrderResponse?.response?.donationId)
            }
          },
        };
  
        const rzp1 = new window.Razorpay(options);
        rzp1.on(`payment.failed`, function(response){
          if(response.error){
            history.push('/English/DonationFail')
            updateDonationData("failure",createOrderResponse?.response?.donationId)
          }
        })
        rzp1.open();
  
      } catch (error) {
        console.error('Error creating order:', error);
        // Handle error, e.g., show an error message
      }
    }
   
  };

  const updateDonationData = async(status, Id) =>{
    const methods = "donations/updateDonation"
    const updateSuccess = await new ServiceStore().InsertData(methods,{
      donationId: Id,
      paymentStatus: status
    })
  } 

  return (
    <>
      <div className="outer-changes">
        <section className="facility-section" style={{maxHeight: 'auto', overflowY:"auto"}}>
          <div className="upper-wrapper">
            <NewHeader />
          </div>

          <div className='w-50 m-auto delBox' style={{ padding: "40px" }}>
            <Row md={12} className='d-flex align-items-center justify-content-center mt-3'>
            <Row md={12}>
              <Col md={6} className="mb-4">
                <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Name <span style={{color:"red"}}>*</span></label>
                <input className='contactUsFormInput'
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Your Name"
                  required
                  onChange={(e) =>{
                    setName(e.target.value);
                      e.target.value.length < 0 ? 
                      setNameError(true) :
                       setNameError(false)}}/>
                  {nameError ? <p style={{color:"red", fontSize:"12px"}}>Please Enter your name</p> : ""}
              </Col>

              <Col md={6} className="mb-4">
                <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Contact Number <span style={{color:"red"}}>*</span></label>
                <input className='contactUsFormInput'
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => {
                        if (
                          !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10
                        ) {
                          setMobile(e.target.value)
                        }
                        e.target.value.length < 0 ? setMobileError(true) : setMobileError(false)
                      }} 
                  required
                  placeholder="Your Contact Number" />
                   {mobileError ? <p style={{color:"red", fontSize:"12px"}}>Please Enter valid mobile number</p> : ""}
              </Col>
            </Row>

              <Col md={12} className="mb-4">
                <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Email Address <span style={{color:"red"}}>*</span></label>
                <input className='contactUsFormInput'
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  required
                  onChange={(e) =>{setEmail(e.target.value);
                    e.target.value.length < 0 ? setEmailError(true) : setEmailError(false)}}
                  placeholder="Your Email" />
                  {emailError ? <p style={{color:"red", fontSize:"12px"}}>Please Enter valid email id</p> : ""}
              </Col>

              <Col md={12} className="mb-4">
                <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Mailing Address (Street Address, City, State, PIN Code)</label>
                <input className='contactUsFormInput'
                  type="text"
                  id="address"
                  name="address"
                  value={address}
                  required
                  onChange={(e) =>setAddress(e.target.value)}
                  placeholder="Your Mailing Address" />
              </Col>

              <Row md={12}>
                <Col md={6} className="mb-4">
                  <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Donation Type <span style={{color:"red"}}>*</span></label>
                  <select id="selectBox" value={donationType} className='contactUsFormInput' 
                          onChange={(e) =>{setDonationType(e.target.value);
                                          e.target.value.length < 0 ? setDonationTypeError(true) : setDonationTypeError(false)                 }} >
                    <option value="">Select...</option>
                    <option value="Onetimedonation">One-time donation</option>
                    <option value="Recurringdonation">Recurring donation (monthly/yearly)</option>
                  </select>
                  {donationTypeError ? <p style={{color:"red", fontSize:"12px"}}>Please select donation type</p> : ""}
                </Col>
              
                <Col md={6} className="mb-4">
                  <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Amount <span style={{color:"red"}}>*</span></label>
                  <input className='contactUsFormInput'
                    type="text"
                    id="Amount"
                    name="Amount"
                    required
                    value={amount}
                    onChange={handleAmountChange}
                    // onChange={(e) => {setAmount(e.target.value);
                    //                   e.target.value.length < 0 ? setAmountError(true) : setAmountError(false)}}
                    placeholder="Your Amount" />
                    {amountError ? <p style={{color:"red", fontSize:"12px"}}>Please enter amount</p> : ""}
                </Col>
              </Row>

              <Row md={12}>
                <Col md={6} className="mb-4">
                  <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Anonymous Donation</label>
                  <select id="selectBox" value={annonymousDonation} onChange={(e) =>setAnnonymousDonation(e.target.value)} className='contactUsFormInput'>
                    <option value="">Select...</option>
                    <option value="Yes">Yes, I wish to remain anonymous</option>
                    <option value="No">No, you can acknowledge my donation publicly.</option>
                  </select>
                </Col>

                <Col md={6} className="mb-4">
                  <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Donation Purpose <span style={{color:"red"}}>*</span></label>
                  <select id="selectBox" value={donationPurpose} className='contactUsFormInput'
                  onChange={(e) =>{setDonationPurpose(e.target.value);
                                    e.target.value.length < 0 ? setDonationpurposeError(true) : setDonationpurposeError(false)}}>
                    <option value="">Select...</option>
                    <option value="General">General River Rejuvenation Fund</option>
                    <option value="Specific">Specific Project (please specify)</option>
                  </select>
                  {donationpurposeError ? <p style={{color:"red", fontSize:"12px"}}>Please select donation purpose</p> : ""}
                </Col>
              </Row>

              <Col md={12} className="mb-4">
                <div className="form-group1">
                  <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>How did you hear about our river rejuvenation project?</label>
                  <select id="selectBox" value={hearAbout} onChange={(e)=>setHearAbout(e.target.value)} className='contactUsFormInput'>
                    <option value="">Select...</option>
                    <option value="SocialMedia">Social Media (e.g., Facebook, Twitter)</option>
                    <option value="LocalNews">Local News or Media</option>
                    <option value="Mouth">Word of Mouth</option>
                    <option value="Municipal">Municipal Corporation Website</option>
                    <option value="CommunityEvent">Community Event or Fair</option>
                    <option value="Internet">Internet Search (e.g., Google)</option>
                    <option value="Other">Other (please specify)</option>
                  </select>
                </div>
              </Col>

              <div style={{ display: 'flex', justifyContent: 'center', fontSize: '15px', marginTop: "10px" }}>
                <button type="submit" data-dismiss="modal" aria-label="Close" onClick={createOrder}>Submit</button>
              </div>

            </Row>
          </div>
        </section>
        <Footer className="mt-5" />
      </div>
      
    </>
  );
};

export default Donation;
