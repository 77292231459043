// import React from 'react'

// const PrivacyPolicy = () => {
//   return (
//     <h1>PrivacyPolicy</h1>
//   )
// }

// export default PrivacyPolicy

import React from "react";
import "../../English/forum/forum.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import "../../Marathi/facilities/facility.css"

import { useHistory } from "react-router-dom";

const PrivacyPolicy = () => {
 
 
  return (
    <>
      <div className="outer-changes">
      
        <section className="facility-section">
          <div className="upper-wrapper">
          <NewHeader />
          <div
              className="row header-pad-fac"
              style={{ margin: "0", padding: "0" }}
            >
              </div>
           </div>
           <div className="col-xl-12" style={{ "marginTop": "100px", "marginBottom": "100px" }}>
                    <div className="muthai-para" >
                    <h6 className="sec-left2">Where does it come from?</h6>
                    <span className="class-inter"> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. </span>
                     <span className="class-inter">By creating a meaningful public realm along the river and promoting community activities and programmes that lead to a better quality of life in the City of Pune, the Municipal Corporation of Pune is committed to transforming today's city, built on the motherly laps of the Mula-Mutha riverbanks, into a healthy, vibrant place to live and thrive. The revitalization of the river will contribute to the waterfront becoming a hub for social, cultural, and recreational activities in the city, as well as propose a resilient river edge to mitigate the threat of flooding, making it an appealing place to live for both current and future residents. As a result, it contributes to the city's potential for adding green recreational spaces, as well as providing active citizen partnerships with civic and business organizations, as well as a revitalized sense of community.
                     </span>
                    </div>
                  </div>
          </section>
        </div>
        <Footer />
    </>
  );
};

export default PrivacyPolicy;
