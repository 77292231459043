import React from "react";
import { Route, Switch } from "react-router-dom";
// import AboutUsMarathi from '../Marathi/aboutus/aboutusMarathi';
import AboutUs from "../Marathi/aboutus/aboutus";
import Facility from "../Marathi/facilities/facility";

import FacilityMarathi from "../Marathi/facilities/facilityM";
import HomePage from "../Marathi/homepage/homepage";
import Blog from "../Marathi/blog/blog";
import Forum from "../Marathi/forum/forum";
import Gallery from "../Marathi/gallery/gallery";
import AccountSetting from "../Marathi/accountSetting/accountSetting";
import BlogD from "../Marathi/blogdetail/blogD";
import Blogtopic from "../Marathi/blogTopic/blogtopic";
import ForumBlog from "../Marathi/forumblogs/forumblog";
import SpaceBooking from "../Marathi/spaceBooking/spaceBooking";
import Spacebooking2 from "../Marathi/spaceBooking2/spacebooking2";
import Survey from "../Marathi/Survey/survey";
import SurveyDetail from "../Marathi/SurveyDetail/surveyDetail";
import Uploadstatus from "../Marathi/uploadFeedback/uploadstatus";
import UploadPhoto from "../Marathi/uploadPhoto/uploadPhoto";
import Dashboard from "../Marathi/userdashboard/userdashboard";
import WriteBlog from "../Marathi/writeblog/writeblog";
import Publication from "../Marathi/publication/publication";
import WinnersOfCompetition from "../Marathi/winnersofcompetition/winnerOfCompetition";
import VintagePune from "../Marathi/vintagepune/vintagePune";
import PhotosByPmc from "../Marathi/photosbypmc/photosByPmc";
import Photographs from "../Marathi/photograph/Photographs";
import Drawing from "../Marathi/drawing/Drawing";
import SignUp from "../Marathi/signup/signup";
import LogIn from "../Marathi/logIn/logIn";
import ContactUs from "../Marathi/contactUs/ContactUs";
import Favourites from "../Marathi/favourites/Favourites";
import SavedBlogs from "../Marathi/savedItems/SavedBlogs";
import SavedCommunityForums from "../Marathi/savedItems/SavedCommunityForums";
import PrivacyPolicy from "../Marathi/privacyPolicy/PrivacyPolicy";
import { Suspense } from "react";
import BlogByPmc from "../Marathi/blogByPmc/BlogByPmc";
import Notifications from "../Marathi/notifications/Notifications";
import SAQ from "../Marathi/saq/SAQ";
import Donation from "../Marathi/Donation/Donation";
import CheckAvailability from "../Marathi/CheckAvailability/CheckAvailability";
import CookiePolicy from "../Marathi/CookiePolicy/CookiePolicy";
import CheckOut from "../Marathi/CheckAvailability/CheckOut";




function MarathiRoute() {
  return (
    <>
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        {/* <Route path='/Marathi/Home' element={<HomePage/>}/> */}
        <Route path={`/Marathi/Home`} component={HomePage}></Route>
        <Route path={`/Marathi/AboutUs`} component={AboutUs}></Route>
        <Route path={`/Marathi/Facility`} component={Facility}></Route>
        <Route path={`/Marathi/Blog`} component={Blog}></Route>
        <Route path={`/Marathi/BlogD`} component={BlogD}></Route>
        <Route path={`/Marathi/Gallery`} component={Gallery}></Route>

        <Route path={`/Marathi/Forum`} component={Forum}></Route>
        <Route path={`/Marathi/SurveyDetail`} component={SurveyDetail}></Route>
        <Route path={`/Marathi/Survey`} component={Survey}></Route>
        <Route path={`/Marathi/Blogtopic`} component={Blogtopic}></Route>
        <Route path={`/Marathi/UploadPhoto`} component={UploadPhoto}></Route>
        <Route path={`/Marathi/ForumBlog`} component={ForumBlog}></Route>
        <Route path={`/Marathi/Uploadstatus`} component={Uploadstatus}></Route>
        <Route path={`/Marathi/WriteBlog`} component={WriteBlog}></Route>
        <Route path={`/Marathi/SpaceBooking`} component={SpaceBooking}></Route>
        <Route
          path={`/Marathi/Spacebooking2`}
          component={Spacebooking2}
        ></Route>
        <Route path={`/Marathi/Dashboard`} component={Dashboard}></Route>
        <Route path={`/Marathi/Uploadstatus`} component={Uploadstatus}></Route>
        <Route
          path={`/Marathi/AccountSetting`}
          component={AccountSetting}
        ></Route>
        <Route path={`/Marathi/Publication`} component={Publication}></Route>
        <Route path={`/Marathi/Login`} component={LogIn}></Route>
        <Route path={`/Marathi/Signup`} component={SignUp}></Route>
        {/* <Route path='#' element={<HomePageMarathi/>}/> */}
        {/* <Route path='/Marathi/AboutUs' element={<AboutUs/>}/>
            <Route path='/Marathi/Facility' element={<Facility/>}/>
            <Route path='/Marathi/Blog' element={<Blog/>}/>
      
            <Route path='/Marathi/BlogD' element={<BlogD/>}/>

            <Route path='/Marathi/Gallery' element={<Gallery/>}/>
           
            <Route path='/Marathi/Forum' element={<Forum/>}/>
            <Route path='/Marathi/SurveyDetail' element={<SurveyDetail/>}/>
            <Route path='/Marathi/Survey' element={<Survey/>}/>
            <Route path='/Marathi/Blogtopic' element={<Blogtopic/>}/>
            <Route path='/Marathi/UploadPhoto' element={<UploadPhoto/>}/>
            <Route path='/Marathi/ForumBlog' element={<ForumBlog/>}/>
            <Route path='/Marathi/Uploadstatus' element={<Uploadstatus/>}/>
            <Route path='/Marathi/WriteBlog' element={<WriteBlog/>}/>
            <Route path='/Marathi/SpaceBooking' element={<SpaceBooking/>}/>
            <Route path='/Marathi/Spacebooking2' element={<Spacebooking2/>}/>
            <Route path='/Marathi/Dashboard' element={<Dashboard/>}/>
            <Route path='/Marathi/AccountSetting' element={<AccountSetting/>}/> */}
        <Route
          path={`/Marathi/WinnersOfCompetition`}
          component={WinnersOfCompetition}
        ></Route>
        <Route path={`/Marathi/VintagePune`} component={VintagePune}></Route>
        <Route path={`/Marathi/PhotosByPmc`} component={PhotosByPmc}></Route>
      <Route path={`/Marathi/Photographs`} component={Photographs}></Route>
      <Route path={`/Marathi/Drawing`} component={Drawing}></Route>
      <Route path={`/Marathi/ContactUs`} component={ContactUs}></Route>
      <Route path='/Marathi/Spacebooking2' element={<Spacebooking2/>}></Route>
      <Route path={`/Marathi/Favourites`} component={Favourites}></Route>
      <Route path={`/Marathi/SavedBlogs`} component={SavedBlogs}></Route>
      <Route path={`/Marathi/SavedCommunityForums`} component={SavedCommunityForums}></Route>
      <Route path={`/Marathi/PrivacyPolicy`} component={PrivacyPolicy}></Route>
      <Route path={`/Marathi/BlogByPmc`} component={BlogByPmc}></Route>
      <Route path={`/Marathi/AccountSetting`} component={AccountSetting}></Route>
      <Route path={`/Marathi/Notifications`} component={Notifications}></Route>
      <Route path={`/Marathi/SAQ`} component={SAQ}></Route>
      <Route path={`/Marathi/Donation`} component={Donation}></Route>
      <Route path={`/Marathi/CheckAvailability`} component={CheckAvailability}></Route>
      <Route path={`/Marathi/CookiePolicy`} component={CookiePolicy}></Route>
      <Route path={`/Marathi/CheckOut`} component={CheckOut}></Route>

      
      
      </Switch>
      </Suspense>
    </>
  );
}

export default MarathiRoute;
