// import React from 'react'

// const PrivacyPolicy = () => {
//   return (
//     <h1>PrivacyPolicy</h1>
//   )
// }

// export default PrivacyPolicy

import React from "react";
import "../../English/forum/forum.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import "../../English/facilities/facility.css"
import { useHistory } from "react-router-dom";
import './saq.css'
import AccordionItem from './AccordionItem';

const SAQ = ({  }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const items = [
    { id: 1, title: 'Section 1', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { id: 2, title: 'Section 2', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { id: 3, title: 'Section 2', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
    { id: 4, title: 'Section 2', content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },

    // Add more items as needed
  ];
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
 
  return (
    <>
      <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
          <NewHeader />
           </div>
           <div className="col-xl-12" style={{ "marginTop": "100px", "marginBottom": "100px" }}>
                    <div className="muthai-para">
                    <h6 className="sec-left2">Where can I get some?</h6>
                    <div className="accordion-container">
                        {items.map((item) => (
                          <AccordionItem key={item.id} title={item.title} content={item.content} />
                        ))}
                      </div>
                    </div>
                  </div>
          </section>
        </div>
        <Footer />
    </>
  );
};

export default SAQ;
