import React from 'react';

// const Seat = ({ seatNumber, selected, onSelect }) => {
//   const handleClick = () => {
//     onSelect(seatNumber);
//   };

//   return (
//     <div
//       className={`seats ${selected ? 'selected' : ''}`}
//       onClick={handleClick}
//     >
//       {seatNumber}
//     </div>
//   );
// };




// const Seat = ({ seatNumber, selected, onSelect, disabled, timeSlots }) => {
//   const seatStyle = {
//       backgroundColor: selected ? 'green' : disabled ? 'gray' : 'white',
//       border: selected ? '2px solid yellow' : '1px solid black',
//       padding: '5px',
//       margin: '5px',
//       textAlign: 'center',
//       cursor: disabled ? 'not-allowed' : 'pointer',
//   };

//   const timeSlotIndex = seatNumber - 1; // Assuming seat numbers start from 1

//   return (
//       <div style={seatStyle} onClick={() => !disabled && onSelect(seatNumber)}>
//           {selected && <div>Selected</div>}
//           {!selected && timeSlots && timeSlots[timeSlotIndex] && <div>{timeSlots[timeSlotIndex]}</div>}
//           {!selected && !timeSlots && <div>{seatNumber}</div>}
//       </div>
//   );
// };


// const Seat = ({ seatNumber, selected, onSelect, disabled, timeSlots }) => {
//   const seatClassName = `seats${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`;

//   const timeSlotIndex = seatNumber - 1; // Assuming seat numbers start from 1
// console.log("timeSlots",timeSlots ,seatNumber)
//   return (
//       <div className={seatClassName} onClick={() => !disabled && onSelect(seatNumber)}>
//           {selected && <div>{timeSlots[timeSlotIndex]}</div>}
//           {!selected && timeSlots && timeSlots[seatNumber] && <div className="time-slot-label">{timeSlots[seatNumber]}</div>}
//           {!selected && !timeSlots && <div>{seatNumber}</div>}
//       </div>
//   );
// };

const Seat = ({ seatNumber, selected, onSelect, disabled, timeSlots }) => {
  const seatClassName = `seats${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`;

  const getTimeSlotIndex = (seatNumber) => {
      // Assuming seat numbers start from 1
      return seatNumber > 0 ? (seatNumber - 1) % timeSlots.length : 0;
  };

  const timeSlotIndex = getTimeSlotIndex(seatNumber);

  return (
      <div className={seatClassName} onClick={() => !disabled && onSelect(seatNumber)}>
          {selected && <div>{timeSlots[timeSlotIndex]}</div>}
          {!selected && timeSlots && timeSlots[timeSlotIndex] && (
              <div className="time-slot-label">{timeSlots[timeSlotIndex]}</div>
          )}
          {!selected && !timeSlots && <div>{seatNumber}</div>}
      </div>
  );
};
export default Seat;