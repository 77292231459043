import React from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router-dom";

const DonationSuccess = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
        <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop:"200px", marginBottom:"200px", width:"40%", textAlign:"center",padding:"40px"}}>
            <Icon.CheckCircleFill style={{fontSize:50, color:"green", marginBottom:"20px"}}/>
            <h1 style={{marginBottom:"20px", color:"green", fontWeight:"600",fontSize:"35px"}}>Payment  Successful</h1>
            <h3 style={{color:"grey"}}>Thank You! Your payment is completed</h3>
            <Button style={{ backgroundColor: "green", marginTop: "50px"}} size="lg" onClick={() =>history.push('/English/Donation')}>
                Done
            </Button>
        </div>
        </div>
    </>
   
  )
}

export default DonationSuccess
