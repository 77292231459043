import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blogtopic.css";
import ServiceStore from "../../utils/ServiceStore";
import { useHistory } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { toast } from "react-toastify";


const Blogtopic = () => {
  const [datalist, setdatalist] = React.useState([]);
  const [conservationtopiclist, setconservationtopiclist] = React.useState([]);

  const [language, setlanguage] = React.useState("english");
  const [subtitleError, setSubtitleError] = React.useState("");

  const [categoryId, setCategoryId] = React.useState("");
  const [title, settitle] = React.useState("");
  const [subtitle, setsubtitle] = React.useState("");
  const history = useHistory();
  
useEffect(() => {
  console.log("refreshed")
    getAlldataFile();
  }, []);

  const logEvent = (e) => {
    setsubtitle(e.editor.getData());
    setSubtitleError("");

  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!language) {
      alert("Please select a language.");
      return;
    }

    // if (!image) {
    //   alert("Please select an image.");
    //   return;
    // }

    if (!categoryId) {
      alert("Please select a blog category.");
      return;
    }

    if (!title) {
      alert("Please enter a title.");
      return;
    }
    if (!subtitle) {
      setSubtitleError("Please enter body text.!!"); // Set the subtitle error message
      return;
    } else {
      setSubtitleError(""); // Clear the subtitle error message if the subtitle is not empty
    }
    const auth = JSON.parse(sessionStorage.getItem("items"));

    const methods = "conservationtopic/";
    const data = {
      language: language,
      username: auth.name,
      title: title,
      categoryId: categoryId,
      subtitle: subtitle,
      status: "Pending",
      userID: auth._id,
    };

    new ServiceStore().InsertData(methods, data).then((res) => {
      toast.success("Your topic request sent successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      history.push("/English/Forum");
    });
  };
  const handleIdChange = (event) => {
    const selectedValue = event.target.value;
    setCategoryId(selectedValue);
  };
  const getAlldataFile = (language) => {
    const methods = `communitytopic/${language ? language : "english"}`;
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };
  const langaugeChange = (event) => {
    const language = event.target.value;
    setlanguage(language);
    getAlldataFile(language)

  };
  return (
    <div className="container-fluid Btp-body">
      <div className="row">
        <div className="Btp-header">
          <span className="Btp-upload">Create Topic</span>
          <span className="Btp-cross">
            <Link to="/English/Forum">
              <img src="../../images/crossArr.png" />
            </Link>
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row Btp-row">
            <div className="col-xl-3 col-md-3">
              <div className="form-group">
                <label>
                  Language
                  <select className="form-control" onChange={langaugeChange} required>
                    <option value="">Select Language</option>

                    <option value="english">English</option>
                    <option value="marathi">Marathi</option>
                  </select>
                </label>
              </div>
            </div>

            <div className="col-xl-3 col-md-3">
              <div className="form-group">
                <label>
                  Community Topic
                  <select className="form-control" onChange={handleIdChange} required>
                    <option value="">Select Community Topic</option>
                    {datalist.length > 0 && datalist.map((item) => (
                      <option
                        key={item._id}
                        value={item._id}
                        data-tag={item._id}
                      >
                        {item.category}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="form-group">
                <label>Title</label>

                <input
                  className="form-control"
                  type="text"
                  name="otp"
                  id="otp"
                  placeholder="Enter Topic Title"
                  required
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="Btp-editorheader">Body Text</div>
          <div className="row Btp-row">
            <div className="col-xl-12 col-md-12">
              <div className="form-group">
                <CKEditor onSelectionChange={logEvent} data={subtitle} />
              </div>
              {subtitleError && (
                <div className="error-message" style={{fontSize:"16px", color:"red"}}>{subtitleError}</div>
              )}
            </div>
          </div>


          <div className="Btp-button">
            {/* <Link to="/English/Gallery">
                <button className="Btp-btn1"> Save</button>
              </Link> */}
            <button className="Btp-btn2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Blogtopic;
