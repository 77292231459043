import React from 'react'
import './ContactUs.css';
import NewHeader from '../NewHeader/NewHeader';
import Footer from '../footer/footer';
import { useState } from 'react';
import ServiceStore from '../../utils/ServiceStore';

const ContactUs = () => {
  const [name,setName] = useState("")
  const [mobile,setMobile] = useState("")
  const [comment,setComment] = useState("")

  const handleSubmitForm=(e)=>{
    e.preventDefault();
    const body ={
      name:name,
      mobile:mobile,
      comment:comment,
    }
    const method ="admin/contactus/add"
    console.log("data :", body)
    new ServiceStore().InsertData(method,body).then((res)=>{
      console.log(res)
    })
  }

  const handleInputChange=(e)=>{
    console.log("Input changed:", e.target.name, e.target.value);

    const { name, value } = e.target;
  
    if (name === "name") {
      console.log(/[^\d]/.test(value))
      if(/[^\d]/.test(value)){
        setName(value);
      }
    } else if (name === "mobile") {
      
      if(value.length <= 10)
      {
        console.log(value.length)
        setMobile(value);
      }
    } else if (name === "comment") {
      setComment(value);
    }
  }
  return (
    <>
     <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper-for-contactUs">
    <NewHeader/>
    <div className="cntainer" style={{padding:'4rem'}}>
    <div className="contact-us1">
      <h2 style={{fontSize:'3rem'}}>Contact Us</h2><br></br>
      <form>
        <div className="form-group1">
          <label className='contactUsFormLabels'style={{fontSize:'16px'}}>Name</label>
          <input className='contactUsFormInput' 
          type="text" 
          id="name" 
          name="name" 
          placeholder="Your Name" 
          required
          value={name}
          onChange={handleInputChange}
          />
        </div>

        <div className="form-group1">
          <label className='contactUsFormLabels'style={{fontSize:'16px'}}>Mobile Number</label>
          <input className='contactUsFormInput' 
          type="text" 
          id="mobile" 
          name="mobile" 
          onChange={handleInputChange}
          required
          value={mobile}
          placeholder="Your Mobile Number" />
        </div>

        <div className="form-group1">
          <label className='contactUsFormLabels'style={{fontSize:'16px'}}>Commentjhdhddddddddddddddddd Here</label>
          <textarea className='contactUsFormInput' 
          type="text" 
          id="comment" 
          name="comment" 
          required
          value={comment}
          onChange={handleInputChange}
          placeholder="Comment Here" ></textarea>
        </div>

        <div style={{display:'flex',justifyContent:'end',fontSize:'15px'}}>
        <button type="submit" onClick={handleSubmitForm}>Submit</button>
        </div>
      </form>
    </div>
    </div>
    <Footer/>
    </div>
    </section>
    </div>
    </>
  )
}

export default ContactUs