import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../English/signup/signup.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
// import { format, parseISO, isAfter } from "date-fns";
import { format, parseISO, isBefore, isAfter, isToday } from "date-fns";
import { toast } from "react-toastify";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const currentDate = new Date();
  const maxDate = format(currentDate, "yyyy-MM-dd");
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  const [isEmailErrorMessage, setIsEmailErrorMessage] = useState("");
  const [isMobileErrorMessage, setIsMobileErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [emailTextfiled, setEmailTextfiled] = useState(false);
  const [mobileTextfiled, setMobileTextfiled] = useState(false);
  const [Email, setEmail] = useState("");
  const [EmailText, setEmailText] = useState("Verify Email");
  const [MobileText, setMobileText] = useState("Verify Phone");
  const [EmailOTP, setEmailOTP] = useState();
  const [MobileOTP, setMobileOTP] = useState();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => { }, [isEmailError, isMobileError]);
  const handleDateChange = (e) => {
    const selectedDate = parseISO(e.target.value);
    if (isAfter(selectedDate, currentDate) || isToday(selectedDate)) {
      setIsInvalidDate(true); // Date is in the future or today, show error
    } else {
      setIsInvalidDate(false); // Date is in the past, no error
      setValue("birth", e.target.value); // Update the form value for birth date
    }
  };
  const onSubmit = (e) => {
    if (EmailText === "Verified" && MobileText === "Verified") {
      // const data = e;
      const data = e;
      const birthDate = parseISO(data.birth);
      if (isAfter(birthDate, currentDate)) {
        setIsInvalidDate(true);
        return; // Do not proceed with the form submission if birth date is in the future
      }
      const body = {
        birth: data.birth,
        email: data.email,
        gender: data.gender,
        mobile: data.mobile,
        name: data.name,
        userType: data.userType,
        password: password
      }
      const method = "user/register";
      new ServiceStore()
        .InsertData(method, body)
        .then((res) => {
          if (res.response.status === 1) {
            toast.success("User Register successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history.push("/LogIn");
          } else {
            throw new Error("An error occurred during registration");
          }
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 500") {
            toast.warn("Network error!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          let string = error.response.data.message;
          if (string.search("Email and Mobile") == 0) {
            setIsEmailError(true);
            setIsEmailErrorMessage(
              error.response.data.message ? error.response.data.message : error
            );
            setIsMobileError(true);
            setIsMobileErrorMessage(
              error.response.data.message ? error.response.data.message : error
            );
          } else if (string.search("Mobile") == 0) {
            setIsMobileError(true);
            setIsEmailError(false);
            setIsMobileErrorMessage(
              error.response.data.message ? error.response.data.message : error
            );
          } else {
            setIsEmailError(true);
            setIsMobileError(false);
            setIsEmailErrorMessage(
              error.response.data.message ? error.response.data.message : error
            );
          }
        });
    }
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 32
    ) {
      return true; // Allow alphabetic characters and space
    } else {
      e.preventDefault();
      return false; // Prevent input of other characters
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]{0,10}$/.test(inputValue) || inputValue.lenght === 10) {
      setMobile(inputValue);
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const formClass = classNames({
    "form-validate": true,
  });

  const handleVerify = (text) => {
    if (text === "email") {
      const method = "user/verifyUser";
      const data = {
        email: Email,
      };
      new ServiceStore()
        .InsertData(method, data)
        .then((res) => {
          if (res.response.status === 1) {
            toast.success("OTP sent Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            sessionStorage.setItem("verifyEmailOTP", res.response.data);
            setEmailTextfiled(true);
            setMobileTextfiled(false);
          } else {
            throw new Error("An error occurred during registration");
          }
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 500") {
            toast.warn("Network error!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } else {
      const method = "user/verifyUser";
      const data = {
        email: Mobile,
      };
      new ServiceStore()
        .InsertData(method, data)
        .then((res) => {
          if (res.response.status === 1) {
            toast.success("OTP sent Successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            sessionStorage.setItem("verifyMobileOTP", res.response.data);
            setMobileTextfiled(true);
            setEmailTextfiled(false);
          } else {
            throw new Error("An error occurred during registration");
          }
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 500") {
            toast.warn("Network error!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };

  const handleOTPChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "mobileOtp") {
      setMobileOTP(value);
    } else {
      setEmailOTP(value);
    }
  };

  const verifyOTP = (text) => {
    if (text === "email") {
      if (sessionStorage.getItem("verifyEmailOTP") === EmailOTP) {
        toast.success("OTP Verified!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEmailText("Verified");
        setEmailTextfiled(false);
      } else {
        toast.error("Invalid OTP!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      if (sessionStorage.getItem("verifyMobileOTP") === MobileOTP) {
        toast.success("OTP Verified!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setMobileText("Verified");
        setMobileTextfiled(false);
      } else {
        toast.error("Invalid OTP!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };



  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <>
      <div className="container-fluid class-sign">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="logo-head">
                      <Link to="/">
                        <img src="../../images/logo.png" />
                      </Link>
                    </div>
                    <div className="form-div">
                      <h5 className="signup-head">Sign Up</h5>
                      <Form
                        className={formClass}
                        onSubmit={handleSubmit(onSubmit)}
                      >

                        <div className="form-group">
                          <label>User Type</label>
                          <select
                            className="form-control narrow-dropdown"
                            name="userType"
                            id="userType"
                            {...register("userType", {
                              required: "User Type Required",
                            })}
                          >
                            <option value="">Select User Type</option>
                            <option value="citizen">Citizen</option>
                            <option value="merchant">Merchant</option>
                          </select>
                          {errors.userType && errors.userType.message}
                        </div>

                        <div className="form-group">
                          <label>Full Name</label>
                          <div className="form-control-wrap d-flex first-container">
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              id="name"
                              onKeyPress={handleKeyPress}
                              placeholder="Enter Full Name"
                              {...register("name", {
                                required: "Name Required",
                              })}
                            />
                          </div>
                          {errors.message && errors.message.message}
                        </div>
                        <div className="form-group">
                          <label>Email Id</label>
                          <div className="email-container">
                            <div className="first-container">
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email ID"
                                {...register("email", {
                                  required: "Email Required",
                                })}
                                onChange={handleEmailChange}
                              />
                            </div>
                            <div className="second-container">
                              {!emailTextfiled && (
                                <button disabled={EmailText === 'Verified'} onClick={() => handleVerify("email")}>
                                  {EmailText}
                                </button>
                              )}
                            </div>
                          </div>
                          {errors.message && errors.message.message}
                          <span className="errorMessage">
                            {isEmailError && isEmailErrorMessage}
                          </span>
                        </div>
                        {emailTextfiled && (
                          <div className="form-group">
                            <label>Enter Email OTP</label>
                            <div className="email-container">
                              <div className="first-container">
                                <input
                                  className="form-control"
                                  type="text"
                                  name="emailOtp"
                                  placeholder="Enter Email OTP"
                                  onChange={handleOTPChange}
                                />
                              </div>
                              <div className="second-container">
                                <button onClick={() => verifyOTP("email")}>
                                  Verify OTP
                                </button>
                              </div>
                            </div>
                            {errors.message && errors.message.message}
                          </div>
                        )}
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <div className="email-container">
                            <div className="first-container">
                              <input
                                className="form-control"
                                type="tel"
                                name="mobile"
                                id="mobile"
                                value={Mobile}
                                pattern="[0-9]{10}"
                                placeholder="1234567890"
                                {...register("mobile", {
                                  required: "Mobile Required",
                                })}
                                onInput={(e) => {
                                  e.target.value = e.target.value.slice(0, 10); // Limit the input to 10 digits
                                }}
                                onChange={handleMobileNumberChange}
                              />
                            </div>
                            <div className="second-container">
                              {!mobileTextfiled && (
                                <button disabled={MobileText === 'Verified'} onClick={() => handleVerify("mobile")}>
                                  {MobileText}
                                </button>
                              )}
                            </div>
                          </div>
                          {errors.message && errors.message.message}
                          {/* <span className="errorMessage">
                            {isMobileError && isMobileErrorMessage}
                          </span> */}
                          <span className="errorMessage">
                            {isMobile && "Enter Valid Mobile Number"}
                          </span>
                        </div>
                        {mobileTextfiled && (
                          <div className="form-group">
                            <label>Enter Mobile OTP</label>
                            <div className="email-container">
                              <div className="first-container">
                                <input
                                  className="form-control"
                                  type="text"
                                  name="mobileOtp"
                                  placeholder="Enter Mobile OTP"
                                  onChange={handleOTPChange}
                                />
                              </div>
                              <div className="second-container">
                                <button onClick={() => verifyOTP("mobile")}>
                                  Verify OTP
                                </button>
                              </div>
                            </div>
                            {errors.message && errors.message.message}
                            <span className="errorMessage">
                              {isEmailError && isEmailErrorMessage}
                            </span>
                          </div>
                        )}
                        <div className="form-group">
                          <label>Birth Date</label>
                          <div className="form-control-wrap d-flex first-container">
                            <input
                              className="form-control"
                              type="date"
                              name="birth"
                              id="birth"
                              placeholder="DD/MM/YYYY"
                              {...register("birth", {
                                required: "Birth Date Required",
                              })}
                              onChange={handleDateChange}
                              max={maxDate}
                            />
                          </div>
                          {isInvalidDate && (
                            <span className="errorMessage">
                              Plesae select correct birth Date.
                            </span>
                          )}
                          {errors.message && errors.message.message}
                        </div>
                        <div className="form-group">
                          <label>Gender</label>
                          <div className="gender-label">
                            <input
                              type="radio"
                              id="Male"
                              name="gender"
                              value="Male"
                              defaultChecked="true"
                              {...register("gender")}
                            />
                            <label className="label-radio">Male</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              type="radio"
                              id="Female"
                              name="gender"
                              value="Female"
                              {...register("gender")}
                            />
                            <label className="label-radio">Female</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              type="radio"
                              id="Other"
                              name="gender"
                              value="Other"
                              {...register("gender")}
                            />
                            <label className="label-radio">Other</label>
                          </div>
                        </div>

                        <div>
                          <div className="form-group password-input">
                            <label className="form-label" htmlFor="fv-full-name">
                              Password
                            </label>
                            <div className="form-control-wrap d-flex first-container">
                              <div className="password-input-container">
                                <input
                                  type={passwordVisible ? 'text' : 'password'}
                                  id="fv-full-name"
                                  name="password"
                                  className="form-control"
                                  value={password}
                                  onChange={handlePasswordChange}
                                />
                                <span
                                  className={`password-toggle-icon ${passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                                  onClick={() => setPasswordVisible(!passwordVisible)}
                                ></span>
                              </div>
                              {errors.message && errors.message.message}
                            </div>
                          </div>

                          <div className="form-group password-input">
                            <label className="form-label" htmlFor="fv-confirm-password">
                              Confirm Password
                            </label>
                            <div className="form-control-wrap d-flex first-container">
                              <div className="password-input-container">
                                <input
                                  type={confirmPasswordVisible ? 'text' : 'password'}
                                  id="fv-confirm-password"
                                  name="confirmPassword"
                                  className={`form-control ${confirmPassword === password && confirmPassword !== '' ? 'is-valid' : 'is-invalid'}`}
                                  value={confirmPassword}
                                  onChange={handleConfirmPasswordChange}
                                />
                                <span
                                  className={`password-toggle-icon2 ni ${confirmPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                ></span>
                              </div>
                              {errors.password && <span className="invalid">Password and confirm password do not match</span>}
                            </div>
                          </div>

                        </div>

                        <button className="account-btn">Create Account</button>
                        <div className="login-bottom">
                          <p>Already have an account?</p>
                          <Link to="/LogIn">Login</Link>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pad-none">
                <div className="right-side-image">
                  <div className="quotes-class">
                    {/* <span>
                      The earth, the air, the land and the water are<br></br>
                      not an inheritance<br></br>
                      from our fore fathers but on loan from our children.
                      <br></br>
                      So we have to handover to them at<br></br>
                      least as it was handed over to us.<br></br>
                      <span className="gandhi">- Mahatma Gandhi</span>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
