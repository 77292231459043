import React from "react";
import "./homepage.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { useHistory } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const HomePage = () => {
  const [datalist, setdatalist] = React.useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderContent = [
    { id: 1,image:"../../images/Wada.png",name:"Doa Alex",date:"21/11/2023", content: 'Slide 1 Here is a list of content sliders to display images, video, or HTML content. There are standard sliders to customize with themes, animations, and style elements. There are also alternative sliders to display images in interesting ways. All of these content sliders are free, though some also offer premium versions.' },
    { id: 2,image:"../../images/zoo.png",name:"Rojaa yala",date:"23/10/2023", content: 'Slide 2 Here is a list of content sliders to display images, video, or HTML content. There are standard sliders to customize with themes, animations, and style elements. There are also alternative sliders to display images in interesting ways. All of these content sliders are free, though some also offer premium versions.' },
    { id: 3,image:"../../images/Group (5).png",name:"Neil patel",date:"02/11/2023", content: 'Slide 3 Here is a list of content sliders to display images, video, or HTML content. There are standard sliders to customize with themes, animations, and style elements. There are also alternative sliders to display images in interesting ways. All of these content sliders are free, though some also offer premium versions.' },
    // Add more slides as needed
  ];
  React.useEffect(() => {
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    const methods = "home/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };
  return (
    <>
      <div className="outer-changes">
        <section className="header-wrap">
          <div className="upper-wrapper">
            {/* <Header/> */}
            <NewHeader />
            <div className="row header-pad" style={{ margin: "0" }}>
              <div className="col-xl-12 top-class">
                <div>
                  <p className="future-p1">{datalist[0]?.line1} </p>
                  <p className="water-p1">{datalist[0]?.line2}</p>
                  <p className="our-p1">{datalist[0]?.line3} </p>
                </div>
                {/* <div>
                  <button className="btn-lets">{datalist[0]?.line4} </button>
                </div> */}
              </div>
            </div>
            <div className="row" style={{ margin: "0" }}>
              <div className="col-xl-12">
                <div className="row color-check">
                  <div className="col-xl-3 col-md-6 col-lg-3 col-sm-6">
                    <div className="margin-check">
                      <div className="distance-top">{datalist[0]?.line5}</div>
                      <div className="decs-bot">{datalist[0]?.line9}</div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-3 col-sm-6">
                    <div className="margin-check">
                      <div className="distance-top">{datalist[0]?.line6} </div>
                      <div className="decs-bot">{datalist[0]?.line10} </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-3 col-sm-6">
                    <div className="margin-check">
                      <div className="distance-top">{datalist[0]?.line7}</div>
                      <div className="decs-bot">{datalist[0]?.line11}</div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-3 col-sm-6">
                    <div className="margin-check">
                      <div className="distance-top">{datalist[0]?.line8}</div>
                      <div className="decs-bot">{datalist[0]?.line12}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="header-wrap2">
          <div className="container-fluid bottom-check">
            <div className="row">
              <div className="col-xl-12">
                <h5 className="sec-left3">{datalist[0]?.line22}</h5>
                <div className="row align-items2">
                  <div className="col-xl-6">
                    <div>
                      <h6 className="detail-h6">{datalist[0]?.line23}</h6>
                      <p className="detail-p"> {datalist[0]?.line25}</p>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div>
                      <h6 className="detail-h6">{datalist[0]?.line26}</h6>
                      <p className="detail-p">{datalist[0]?.line27}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items2">
              <div className="resolution-sec">
                <div className="col-xl-3">
                  <div className="chacha-image">
                    <img src="../../images/chacha left.png" />
                  </div>
                </div>
                <div className="col-xl-9 pad-check">
                  <h6>{datalist[0]?.line28}</h6>
                  <p>{datalist[0]?.line29}</p>
                  {/* <p>The objective of this project is to improve the existing condition of rivers and make it more accessible and enjoyable for the people of Pune.</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <h5 className="sec-left">{datalist[0]?.line13}</h5>
                <div className="row align-items">
                  <div className="col-xl-6">
                    <div className="muthai-para">
                      <h6 className="sec-left2">{datalist[0]?.line14}</h6>

                      <p className="p-tag-sec">{datalist[0]?.line15} </p>
                      <div className="pink-img">
                        <img
                          src="../../images/Polygon-2.png"
                          alt=""
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="image-muthai">
                      <img src={imgeurl + datalist[0]?.line16} />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="muthai-para">
                      <h6 className="sec-left2">{datalist[0]?.line17} </h6>
                      <p className="p-tag-sec">{datalist[0]?.line18} </p>
                      <div className="pink-img2">
                        <img
                          src="../../images/Polygon-2.png"
                          alt=""
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="image-muthai">
                      <img src={imgeurl + datalist[0]?.line20} />
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="muthai-para">
                      <h6 className="sec-left2">{datalist[0]?.line19}</h6>
                      <p className="p-tag-sec">{datalist[0]?.line21}</p>
                      <div className="pink-img3">
                        <img
                          src="../../images/Polygon-2.png"
                          alt=""
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="image-muthai">
                      <img src={imgeurl + datalist[0]?.line24} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mision-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <h5 className="sec-left4">{datalist[0]?.line30}</h5>
                <p className="sec-left-5">{datalist[0]?.line31}</p>
                {/* <p className="sec-left-5">The vision framed for the project addresses overarching and crucial aspects including socio cultural profile, <br></br> heritage structures and precincts, environmentally sensitive areas, quality of life, infrastructure, etc. The vision of <br></br> the Pune River 
                        Rejuvenation Project is to create a “Safe, Clean, Beautiful and Integrated River edge for Pune”.</p> */}
                <div className="row align-items2 new-align">
                  <div className="col-xl-2 col-lg-2 col-md-4">
                    <div className="cards-vision">
                      <div className="top-part">
                        <img src="../../images/Group (5).png" />
                        <h5>{datalist[0]?.line32}</h5>
                      </div>
                      <div className="top-part1">
                        <p>{datalist[0]?.line37}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4">
                    <div className="cards-vision">
                      <div className="top-part">
                        <img src="../../images/Group 2.png" />
                        <h5>{datalist[0]?.line33}</h5>
                      </div>
                      <div className="top-part1">
                        <p>{datalist[0]?.line38}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4">
                    <div className="cards-vision">
                      <div className="top-part">
                        <img src="../../images/new.png" />
                        <h5>{datalist[0]?.line34}</h5>
                      </div>
                      <div className="top-part1">
                        <p>{datalist[0]?.line39}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4">
                    <div className="cards-vision">
                      <div className="top-part">
                        <img src="../../images/Group (6).png" />
                        <h5>{datalist[0]?.line35}</h5>
                      </div>
                      <div className="top-part1">
                        <p>{datalist[0]?.line40}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4">
                    <div className="cards-vision">
                      <div className="top-part">
                        <img src="../../images/new2.png" />
                        <h5>{datalist[0]?.line36} </h5>
                      </div>
                      <div className="top-part1">
                        <p>{datalist[0]?.line41}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mision-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
               
              <div className="slider-container">
                <Slider {...settings}>
                  {sliderContent.map((slide) => (
                    <div key={slide.id} className="slider-item">
                            <div className="row align-items2">
                          <div className="resolution-sec">
                            <div className="col-xl-4">
                              <div >
                                {/* <img src="../../images/chacha left.png" /> */}
                                <img src={slide.image} alt={`Slide ${slide.id}`} className="slider-images" />
                              
                              </div>
                              <p className="chacha-names">{slide.name}</p>
                            </div>
                            <div className="col-xl-8">
                              <span className="chacha-texts">{slide.content}</span>
                              <p className="chacha-right">{slide.date}</p>
                              {/* <p>The objective of this project is to improve the existing condition of rivers and make it more accessible and enjoyable for the people of Pune.</p> */}
                            </div>
                          </div>
                        </div>
                      {/* <h6 className="chacha-text">{slide.id}</h6>
                      <p className="chacha-text">{slide.content}</p>
                      <img src={slide.image} alt={`Slide ${slide.id}`} className="slider-image" />
                      <p className="chacha-name">{slide.name}</p>
                      <p className="chacha-right">{slide.date}</p> */}

                    </div>
                  ))}
                </Slider>
              </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
