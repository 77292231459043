import React, { useState } from "react";
import "./publication.css";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { useHistory } from "react-router-dom";
import Link from "antd/es/typography/Link";
import { Document, Page } from "react-pdf";
// const pdfurl = "http://localhost:3001/uploads/pdf/";
const pdfurl = "https://api.puneri.foxberry.link/public/uploads/image/";
const Publication = () => {
  const [datalist, setdatalist] = React.useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  React.useEffect(() => {
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    const methods = "publication/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div className="outer-changes">
        <div className="upper-wrapper">
          {/* <Header/> */}
          <NewHeader />
          {/* <div class="pdf-block">
                        <p>PDF Block 1</p>
                           
                                
                               <a href="../../images/my_pdf.pdf.pdf" target="blank">pdf open</a>
                           
                        </div> */}
          {/* <div class="pdf-block">
                            <embed src="pdf2.pdf" type="application/pdf" />
                            <p>PDF Block 2</p>
                        </div>
                        <div class="pdf-block">
                            <embed src="pdf3.pdf" type="application/pdf" />
                            <p>PDF Block 3</p>
                        </div> */}
          <section>
            <div className="container-fluid bottom-check">
              <div className="row">
                <div className="col-xl-12">
                  <h5 className="sec-left-open">प्रकाशने</h5>
                  <div className="row">
                    {datalist.length > 0 ? (
                      datalist.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12"
                          >
                            <div className="openpdf">
                              <a
                                href={pdfurl + data?.pdfname}
                                className="nonebackground"
                                target="blank"
                              >
                                <iframe
                                  className="iframe"
                                  src={pdfurl + data?.pdfname}
                                  title="PDF Preview"
                                />
                              </a>
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                  display: "flex",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {data?.title}
                                </b>
                              </div>
                              <br></br>
                              <a href={pdfurl + data?.pdfname} target="blank">
                              पीडीएफ उघडा
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="nodata">
                        <h1>माहिती आढळली नाही</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Publication;
