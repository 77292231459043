import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./Drawing.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Button } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageGallery from "react-image-gallery";
import { IoArrowBackOutline, IoArrowUp } from "react-icons/io5";
import { useEffect } from "react";
import { useState } from "react";

const image11 = "/drive/drawing/IMG_6397.JPG";
const image12 = "/drive/drawing/IMG_6398.JPG";
const image13 = "/drive/drawing/IMG_6399.JPG";
const image14 = "/drive/drawing/IMG_6400.JPG";
const image15 = "/drive/drawing/IMG_6401.JPG";
const image16 = "/drive/drawing/IMG_6402.JPG";
const image17 = "/drive/drawing/IMG_6403.JPG";
const image18 = "/drive/drawing/IMG_6404.JPG";
const image19 = "/drive/drawing/IMG_6405.JPG";
const image20 = "/drive/drawing/IMG_6406.JPG";
const image21 = "/drive/drawing/IMG_6413.JPG";
const image22 = "/drive/drawing/IMG_6414.JPG";
const image23 = "/drive/drawing/IMG_6415.JPG";
const image24 = "/drive/drawing/IMG_6748.JPG";
const image25 = "/drive/drawing/IMG_6751.JPG";
const image26 = "/drive/drawing/IMG_6752.JPG";
const image27 = "/drive/drawing/IMG_6753.JPG";
const image28 = "/drive/drawing/IMG_6754.JPG";
const image29 = "/drive/drawing/IMG_6755.JPG";
const image31 = "/drive/drawing/IMG_6756.JPG";
const image32 = "/drive/drawing/IMG_6757.JPG";
const image33 = "/drive/drawing/IMG_6758.JPG";
const image34 = "/drive/drawing/IMG_6759.JPG";
const image35 = "/drive/drawing/IMG_6763.JPG";
const image36 = "/drive/drawing/IMG_6764.JPG";
const image37 = "/drive/drawing/IMG_6765.JPG";
const image38 = "/drive/drawing/IMG_6766.JPG";
const image39 = "/drive/drawing/IMG_6767.JPG";
const image40 = "/drive/drawing/IMG_6768.JPG";
const image41 = "/drive/drawing/IMG_6769.JPG";
const image42 = "/drive/drawing/IMG_6770.JPG";
const image43 = "/drive/drawing/IMG_6771.JPG";
const image44 = "/drive/drawing/IMG_6772.JPG";
const image45 = "/drive/drawing/IMG_6774.JPG";

function Drawing() {
  const [datalist, setdatalist] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);
  const pageSize = 3;

  const [gallary, setgallery] = React.useState([
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image45,
  ]);
  const [itemsToShow, setItemsToShow] = useState(4);

  React.useEffect(() => {
    getAlldataFile();
    if (datalist.length > 0) {
      fetchData();
    }
  }, [page]);
  // React.useEffect(() => {
  //   fetchData();
  // },[page])
  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollHeight + 1 >=
        document.documentElement.scrollTop
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
  }, []);
  const getAlldataFile = () => {
    setIsLoading(true);
    const methods = "gallary/Marathi";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data[0].line6);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = res.data[0].line6.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    });
  };

  const fetchData = () => {
    setIsLoading(true);
    setTimeout(() => {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = datalist.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    }, 500);
  };
  const imagePerRow = 3;
  const [next, setNext] = React.useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This smooth scroll is optional, you can remove it if you want an instant scroll
    });
  };

  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          <section className="gal-sec-1">
            <div className="headerDiv">
              <div>
                <p className="gal-p3">
                  <span>पुणेरे रेखाचित्रे स्पर्धेतील उत्कृष्ट रेखाचित्रे</span>
                </p>
              </div>
              <div>
                <Link to="/English/Gallery">
                  <div>
                    <IoArrowBackOutline
                      style={{
                        float: "right",
                        height: "5em",
                        width: "3em",
                        marginRight: "10em",
                      }}
                    />
                    <span className="back-btn1">मागे</span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>

        <div className="container-flex">
          <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {/* {gallary?.map((image, index) => (
              <div className="col-md-4">
                <img
                  // src={image4}
                  // className="gallery-images"
                />
              </div>
            ))} */}
            {gallary.slice(0, itemsToShow).map((image) => {
              return (
                <div className="col-xl-3 col-md-3 col-sm-2">
                  <img style={{ width: "100%" }} src={image} />
                </div>
              );
            })}
          </div>
        </div>

        {/* {isLoading && (
          <div className="spinnerDiv">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
        <div className="btn-group-div">
          <div
            onClick={scrollToTop}
            // className="Ups-button"
            style={{ marginBottom: "10px", cursor: "pointer" }}
          >
            <IoArrowUp
              style={{
                float: "right",
                height: "5em",
                width: "3em",
                marginRight: "10em",
              }}
            />
            <span className="back-btn1">वर</span>
            {/* <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button> */}
          </div>
          <div>
            {itemsToShow < gallary.length && (
              <button
                onClick={() => {
                  setItemsToShow(itemsToShow + 4);
                }}
                className="view-more-btn"
              >
                अधिक पहा
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Drawing;
