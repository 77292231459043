import React from "react";
import { Link } from "@mui/material";
import { Form } from "antd";
import "./Verify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ServiceStore from "../../utils/ServiceStore";

const Verify = () => {
  const history = useHistory();

  const [emailId, setEmailId] = React.useState("");

  const handleShow = (id) => {
    document.getElementById(`${id}`).style.display = "block";
  };

  const handleOtp = () => {
    const method = "user/login";
    new ServiceStore().InsertData(method, emailId).then((res) => {
      // setOtpGet(true);
      // sessionStorage.setItem("items", JSON.stringify(res.response.data));
    });
  };

  return (
    <>
      <div className="container-fluid class-sign">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div
                      onClick={() => {
                        history.push("/");
                      }}
                      className="logo-head"
                    >
                      <Link to="/">
                        <img src="../../images/logo.png" />
                      </Link>
                    </div>
                    <div className="form-div">
                      <h5 className="signup-head">Verify</h5>
                      <Form
                        className={"formClass"}
                        // onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-group">
                          <label>Email Id</label>
                          <input
                            className="form-control"
                            type="email"
                            name="emial"
                            id="email"
                            placeholder="Enter Email ID"
                            onChange={(e) => setEmailId(e.target.value)}
                            //   {...register("email", {
                            //     required: "Email Required",
                            //   })}
                          />
                          {/* {errors.message && errors.message.message} */}
                        </div>
                        <button
                          className="account-btn"
                          style={{ marginBottom: "-10px" }}
                          onClick={() => {
                            handleShow("emailOtp");
                            handleOtp();
                          }}
                        >
                          {" "}
                          Send OTP
                        </button>
                        <br></br>
                        <br></br>

                        <div id="emailOtp">
                          <div className="  form-group">
                            <label>Enter OTP</label>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <input
                                className="form-control"
                                type="email"
                                name="emial"
                                id="email"
                                placeholder="Enter OTP"
                                //   {...register("email", {
                                //     required: "Email Required",
                                //   })}
                              />
                              {/* {errors.message && errors.message.message} */}
                            </div>
                            <div
                              className="col-6"
                              style={{ alignSelf: "center" }}
                            >
                              <button className="account-btn">
                                Verify Email
                              </button>
                            </div>
                          </div>
                          {/* <div className="login-bottom">
                          <p>Haven't Receiverd OTP?</p>
                          <a href="#">Re-send OTP</a>
                        </div> */}
                        </div>

                        <br></br>

                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            className="form-control"
                            type="number"
                            name="mobile"
                            id="mobile"
                            placeholder="+91 - 000 000 0000"
                            //   {...register("mobile", {
                            //     required: "Mobile Required",
                            //   })}
                          />
                          {/* {errors.message && errors.message.message} */}
                        </div>

                        <button
                          className="account-btn"
                          style={{ marginBottom: "-10px" }}
                          onClick={() => {
                            handleShow("mobileOtp");
                          }}
                        >
                          Send OTP
                        </button>
                        <br></br>
                        <br></br>

                        <div id="mobileOtp">
                          <div className="  form-group">
                            <label>Enter OTP</label>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <input
                                className="form-control"
                                type="email"
                                name="emial"
                                id="email"
                                placeholder="Enter OTP"
                                //   {...register("email", {
                                //     required: "Email Required",
                                //   })}
                              />
                              {/* {errors.message && errors.message.message} */}
                            </div>
                            <div
                              className="col-6"
                              style={{ alignSelf: "center" }}
                            >
                              <button className="account-btn">
                                Verify Mobile
                              </button>
                            </div>
                          </div>
                          {/* <div className="login-bottom">
                          <p>Haven't Receiverd OTP?</p>
                          <a href="">Re-send OTP</a>
                        </div> */}
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pad-none">
                <div className="right-side-image">
                  <div className="quotes-class">
                    {/* <span>
                        The earth, the air, the land and the water are<br></br>
                        not an inheritance<br></br>
                        from our fore fathers but on loan from our children.
                        <br></br>
                        So we have to handover to them at<br></br>
                        least as it was handed over to us.<br></br>
                        <span className="gandhi">- Mahatma Gandhi</span>
                      </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Verify;
