import React,{useState} from 'react'
import Footer from '../footer/footer';
import ServiceStore from '../../utils/ServiceStore';
import NewHeader from "../NewHeader/NewHeader";
import { useHistory } from "react-router-dom";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TelegramShareButton,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    TelegramIcon,
  } from "react-share";
  
  import {
    AiOutlineLike,
    AiTwotoneLike,
    AiOutlineComment,
    AiOutlineWechat,
  } from "react-icons/ai";

const BlogByPmc = () => {
    const history = useHistory();
    const [detailsList, setDetailsList] = React.useState({});
    const [isLikeshow, setisLikeshow] = React.useState(true);
    const [iserror, setiserror] = React.useState("");
    const [comment, setcomment] = React.useState("");
    const [likeCount, setLikeCount] = React.useState(477);
  
    const [likecommentwriteblogtopic, setlikecommentwriteblogtopic] =
      React.useState([]);
  
    const [LikeList, setLikeList] = React.useState({});
    const [likewriteblogtopiclist, setlikewriteblogtopiclist] = React.useState(1);
    const [isshowcomment, setisshowcomment] = React.useState(false);
    const [Commentlist, setCommentlist] = React.useState([]);
    const [iscommentLikeshow, setiscommentLikeshow] = React.useState(undefined);
    const [isshowcommentReply, setisshowcommentReply] = React.useState(undefined);
    const [reply, setreply] = React.useState("");
    const [newDate, setNewDate] = React.useState("");
    const insertcommentReply = (id) => setisshowcommentReply(id);
    const [commentsToShow, setCommentsToShow] = useState(4);
    const [relatedData, setRelatedData] = useState([]);
    const [saveContentIcon, setSaveContentIcon] = useState()
    const [isfav, setisfav] = useState("");
    const [isreportabusecommentid, setisreportabusecommentid] = React.useState(undefined);

    const reportabusecommentid = (id) => setisreportabusecommentid(id);

    React.useEffect(() => {
      if (JSON.parse(sessionStorage.getItem("items"))) {
        
        const dataid = history.location.state?.id || history.location.state?._id
        const hisData = history.location.state;
        getcommentwriteblogtopic();
        if (dataid) {
          getLikelist();
  
          new ServiceStore()
            .GetDataByID("blog/", dataid)
            .then((res) => {
              const dates = res.data.updatedAt;
              setNewDate(
                res.data.updatedAt.split("T")[0].split("-").reverse().join("/")
              );
              console.log(res.data)
              setDetailsList(res.data);
              if (res.data) {
                AlreadySaveOrNot();
              }
            });
          // new ServiceStore().GetAllData('likewriteblogtopic/English').then((res) => {
          //   setlikewriteblogtopiclist(res.data)
          // });
          const auth = sessionStorage.getItem("items");
          if (auth) {
            const data = {
              writeblogtopicId: dataid,
              userID: JSON.parse(auth)._id,
            };
            new ServiceStore()
              .GetDataBYFilter("likewriteblogtopic/EnglishListByID", data)
              .then((res) => {
                if (res.response.data.length == 1) {
                  setisLikeshow(false);
                }
                setLikeList(res.response.data);
              });
          }
        }
  
        new ServiceStore().GetAllData("writeblogtopic/English").then((res) => {
          const array = [];
          res.data.filter((data) => {
            if (data.categoryId === hisData.categoryId) {
              data.createdAt = data.createdAt
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/");
              array.push(data);
            }
          });
          setRelatedData(array);
        });
      }
      else {
        alert("Please log in or sign in")
        history.push("/English/Blog")
      }
    }, [saveContentIcon]);
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const shareUrl = window.location.href;
    const h = date.getHours();
    const m = date.getMinutes();
  
    const getLikelist = () => {
      const dataid = history.location.state?.id || history.location.state?._id
      new ServiceStore()
        .GetDataByID("likewriteblogtopic/English/", dataid)
        .then((res) => {
          setlikewriteblogtopiclist(res.data ? res.data : 0);
        });
    };
    const addLike = (index, e) => {
      setlikewriteblogtopiclist(
        (likewriteblogtopiclist ? parseInt(likewriteblogtopiclist) : 1) + 1
      );
      const auth = sessionStorage.getItem("items");
      if (auth) {
        const methods = "likewriteblogtopic/";
        const data = {
          language: "english",
          username: JSON.parse(auth).name,
          count: 1,
          writeblogtopicId: detailsList.id,
          like: 1,
          status: "IsLike",
          userID: JSON.parse(auth)._id,
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          setisLikeshow(false);
          getLikelist();
        });
      } else {
        setiserror("Please Log in First");
      }
    };
  
    const AlreadySaveOrNot = () => {
      const auth = sessionStorage.getItem("items");
      const dataid = history.location.state?.id || history.location.state?._id
      console.log("dataid",dataid)
      if (auth) {
        const methods = "user/favourite/statuscheck";
        const data = {
          userId: JSON.parse(auth)._id,
          id: dataid,
          type: "adminblog"
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", res)
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", res.response.data.isFav)
          // if(res.response.data.status === 1)
          if (res.response.data.isFav === "False") {
            setSaveContentIcon(false)
          }
          else if (res.response.data.isFav === "True") {
            console.log(">>>>>>>>>>>>>>>>>>>true")
            setSaveContentIcon(true)
          }
          else {
            setSaveContentIcon(false)
  
          }
        });
      } else {
        setiserror("Please Log in First");
      }
    }
  
    const saveData = () => {
      console.log("detailsList",detailsList)
      const auth = sessionStorage.getItem("items");
      if (auth) {
        const methods = "user/favourite/add";
        const data = {
          userId: JSON.parse(auth)._id,
          id: detailsList._id,
          type: "adminblog"
        };
        console.log("data:::::::",data)
        new ServiceStore().UpdateData(methods, data).then((res) => {
          console.log(res)
        });
        setSaveContentIcon(true)
      } else {
        setiserror("Please Log in First");
      }
    }
    const handleSubmitForm = (e) => {
      e.preventDefault();
    console.log("reportabusecommentid",isreportabusecommentid ,detailsList)
    const auth = sessionStorage.getItem("items");
    const dataid = history.location.state?.id || history.location.state?._id

      if (auth) {
        const methods = "reportabusecomment";
        const data = {
          count:1,
          blogId: dataid,
          userID: JSON.parse(auth)._id,
          commentId:isreportabusecommentid.id,
          comment:isreportabusecommentid.comment,
          status:"Report Abuse",
          username:isreportabusecommentid.username,
          language:isreportabusecommentid.language,
          blogname:detailsList?.line1
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          
        });
    
  
      }
    };
    const unsaveData = () => {
      const auth = sessionStorage.getItem("items");
      if (auth) {
        const methods = "user/favourite/remove";
        const data = {
          userId: JSON.parse(auth)._id,
          id: detailsList._id,
          type: "adminblog"
        };
        new ServiceStore().UpdateData(methods, data).then((res) => {
          console.log(res)
        });
        setSaveContentIcon(false)
      } else {
        setiserror("Please Log in First");
      }
    }
  
    const AddComment = (index, e) => {
      setisshowcomment(true);
    };
  
    const deleteLike = (index, e) => {
      const auth = sessionStorage.getItem("items");
      if (auth) {
        const methods = "likewriteblogtopic/deletelike";
        const data = {
          writeblogtopicId: detailsList.id,
          userID: JSON.parse(auth)._id,
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          setisLikeshow(true);
          getLikelist();
        });
      } else {
        setiserror("Please Log in First");
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      if (comment != undefined) {
        const auth = JSON.parse(sessionStorage.getItem("items"));
        const methods = "commentwriteblogtopic/";
        const data = {
          likecount: 0,
          language: "english",
          username: auth.name,
          comment: comment,
          writeblogtopicId: detailsList.id,
          status: "IsComment",
          userID: auth._id,
        };
        console.log("datalist :", detailsList)
        console.log("data :", data)
        new ServiceStore().InsertData(methods, data).then((res) => {
          getcommentwriteblogtopic();
        });
      }
      setisshowcomment(false);
    };
  
    const getcommentwriteblogtopic = () => {
      const dataid = history.location.state?.id;
      const auth = JSON.parse(sessionStorage.getItem("items"));
      console.log("data :", auth)
      const data = { writeblogtopicId: dataid, userID: auth._id };
      new ServiceStore()
        .GetDataBYFilter("commentwriteblogtopic/EnglishListByID", data)
        .then((res) => {
          console.log(res);
          res.response.data.filter((data) => {
            const d = data.createdAt.split("T")[0].split("-").reverse().join("/");
            const t = data.createdAt
              .split("T")[1]
              .split("-")
              .reverse()
              .join("/")
              .split(":");
            const full = d + " " + t[0].concat(":", t[1]);
  
            data.createdAt = full;
          });
          setCommentlist(res.response.data);
        });
    };
  
    const updatecommentLike = (index, e) => {
      const auth = JSON.parse(sessionStorage.getItem("items"));
      if (auth) {
        const methods = "likecommentwriteblogtopic";
        const data = {
          likecount: 1,
          language: "english",
          username: auth.name,
          writeblogtopicId: index.writeblogtopicId,
          status: "IsLike",
          userID: auth._id,
          commentId: index.id,
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          getcommentwriteblogtopic();
        });
      } else {
        setiserror("Please Log in First");
      }
    };
    const deletecommentLike = (index, u, e) => {
      const auth = sessionStorage.getItem("items");
      const likecount = Number(index.likecount) - 1;
      if (auth) {
        const methods = "likecommentwriteblogtopic/";
  
        new ServiceStore().DeleteData(methods, u._id).then((res) => {
          getcommentwriteblogtopic();
        });
      } else {
        setiserror("Please Log in First");
      }
    };
  
    const SubmitReply = (item, e) => {
      e.preventDefault();
      const auth = JSON.parse(sessionStorage.getItem("items"));
      if (reply != "") {
        const methods = "replaycommentwriteblogtopic/";
        const data = {
          likecount: 0,
          language: "english",
          username: auth.name,
          reply: reply,
          writeblogtopicId: item.writeblogtopicId,
          status: "IsReply",
          userID: auth._id,
          commentId: item.id,
        };
        new ServiceStore().InsertData(methods, data).then((res) => {
          getcommentwriteblogtopic();
          setisshowcommentReply(undefined);
          // history.push("/English/Forum");
        });
      }
    };
  
    const postTitle = "Amazing Post Title";
    const postUrl = "https://example.com/posts/123";
    const [showPopup, setShowPopup] = useState(false);
    const handleShareClick = () => {
      setShowPopup(true);
    };
  
    const handleClosePopup = () => {
      setShowPopup(false);
    };
    return (
      <>
        <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
            {/* <Header /> */}
            <NewHeader />
            <div className="row header-pad" style={{ justifyContent: 'center' }}>
              <div className="col-xl-9">
                <div className="left-side-details" style={{ background: 'none' }}>

                  <div className="headings2">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <h4>{detailsList?.line1}</h4><br></br><br></br><br></br><br></br>
                      </div>
                      <div>
                      <div style={{display:'flex'}}>
                        <h5>
                          Add to Favourite
                        </h5>
                        <div>
                          {(saveContentIcon) ? <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50" onClick={(e) => {
                            unsaveData(detailsList, e);

                          }}>
                            <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                          </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50"
                              onClick={(e) => {
                                saveData(detailsList, e);
                              }}
                            >
                              <path d="M 12.8125 2 C 12.335938 2.089844 11.992188 2.511719 12 3 L 12 47 C 11.996094 47.359375 12.1875 47.691406 12.496094 47.871094 C 12.804688 48.054688 13.1875 48.054688 13.5 47.875 L 25 41.15625 L 36.5 47.875 C 36.8125 48.054688 37.195313 48.054688 37.503906 47.871094 C 37.8125 47.691406 38.003906 47.359375 38 47 L 38 3 C 38 2.449219 37.550781 2 37 2 L 13 2 C 12.96875 2 12.9375 2 12.90625 2 C 12.875 2 12.84375 2 12.8125 2 Z M 14 4 L 36 4 L 36 45.25 L 25.5 39.125 C 25.191406 38.945313 24.808594 38.945313 24.5 39.125 L 14 45.25 Z"></path>
                            </svg>}
                        </div>
                      </div>
                        {/* <div>
                          {(saveContentIcon) ? <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50" onClick={(e) => {
                            unsaveData(detailsList, e);

                          }}>
                            <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                          </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50"
                              onClick={(e) => {
                                saveData(detailsList, e);
                              }}
                            >
                              <path d="M 12.8125 2 C 12.335938 2.089844 11.992188 2.511719 12 3 L 12 47 C 11.996094 47.359375 12.1875 47.691406 12.496094 47.871094 C 12.804688 48.054688 13.1875 48.054688 13.5 47.875 L 25 41.15625 L 36.5 47.875 C 36.8125 48.054688 37.195313 48.054688 37.503906 47.871094 C 37.8125 47.691406 38.003906 47.359375 38 47 L 38 3 C 38 2.449219 37.550781 2 37 2 L 13 2 C 12.96875 2 12.9375 2 12.90625 2 C 12.875 2 12.84375 2 12.8125 2 Z M 14 4 L 36 4 L 36 45.25 L 25.5 39.125 C 25.191406 38.945313 24.808594 38.945313 24.5 39.125 L 14 45.25 Z"></path>
                            </svg>}
                        </div> */}
                      </div>
                    </div>
                    <div className="left-side-info">
                      <div className="blogd-top2" style={{ display: 'contents' }}>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <img src="../../images/profile4.png" alt="" />
                          </div>
                          <div><span>{detailsList.username}</span></div>
                        </div>
                        <div className="date-check">
                          {newDate ? newDate : ""}
                        </div>
                      </div>
                    </div>
                    <br></br><br></br><br></br><br></br>
                    <p>
                      <div
                        // dangerouslySetInnerHTML={{
                        //   __html: detailsList?.line2,
                        // }}
                      >
                        <span>{detailsList.line2}</span>
                      </div>
                    </p>
                  </div>
                  <div className="top-a-class" style={{ display: 'none' }}>
                    <span>Category</span>
                    <a href="" className="margin-span">
                      {detailsList.categoryname
                        ? detailsList.categoryname
                        : "Unknown"}
                    </a>
                  </div>
                  
                  <div className="blogd-comments" style={{ display: 'none' }}>
                    <span>
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_702_1990)">
                          <path
                            d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
                            fill="url(#paint0_linear_702_1990)"
                          />
                          <path
                            d="M10.9158 5.04888L11.1904 3.51969C11.3356 2.71119 10.8384 1.92596 10.0455 1.71148L9.80909 1.64751C9.57371 1.58386 9.32539 1.68968 9.20832 1.90359L6.42208 6.99411C6.32951 7.16326 6.15196 7.2685 5.95911 7.2685H4.39399C4.1025 7.2685 3.86621 7.50479 3.86621 7.79628V13.773C3.86621 14.02 4.03747 14.234 4.27846 14.288L8.55916 15.2484C8.59711 15.2569 8.63584 15.2612 8.67469 15.2612H13.5309C14.3383 15.2612 15.0164 14.6536 15.1047 13.8511L15.8118 7.42652C15.915 6.48913 15.181 5.66997 14.2379 5.66997H11.4353C11.1065 5.66997 10.8577 5.37251 10.9158 5.04888Z"
                            fill="url(#paint1_linear_702_1990)"
                          />
                          <path
                            d="M4.74984 14.7777H3.69428C3.40279 14.7777 3.1665 14.5414 3.1665 14.2499V6.86103C3.1665 6.56954 3.40279 6.33325 3.69428 6.33325H4.74984C5.04133 6.33325 5.27762 6.56954 5.27762 6.86103V14.2499C5.27762 14.5414 5.04133 14.7777 4.74984 14.7777Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_702_1990"
                            x1="2.7825"
                            y1="16.2175"
                            x2="16.2175"
                            y2="2.7825"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#5558FF" />
                            <stop offset="1" stop-color="#00C0FF" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_702_1990"
                            x1="5.0697"
                            y1="15.1951"
                            x2="14.7444"
                            y2="5.5205"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#ADDCFF" />
                            <stop offset="1" stop-color="#EAF6FF" />
                          </linearGradient>
                          <clipPath id="clip0_702_1990">
                            <rect width="19" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>
                        {likewriteblogtopiclist
                          ? parseInt(likewriteblogtopiclist)
                          : 1}
                      </span>
                    </span>
                    <span className="span-comment">
                      {Commentlist.length} Comments
                    </span>
                    <span>85 Shares</span>
                  </div>
                  <div className="lowerLine-1"></div>
                  <div className="blogd-comments2" style={{ justifyContent: 'start' }}>
                    <span>
                      {isLikeshow && (
                        <AiOutlineLike
                          style={{ cursor: "pointer" }}
                          size={25}
                          onClick={(e) => {
                            addLike(detailsList, e);
                          }}
                        ></AiOutlineLike>
                      )}
                      {!isLikeshow && (
                        <AiTwotoneLike
                          style={{ cursor: "pointer" }}
                          color="blue"
                          onClick={(e) => {
                            deleteLike(detailsList, e);
                            setlikewriteblogtopiclist(
                              parseInt(likewriteblogtopiclist) - 1
                            );
                          }}
                          size={25}
                        ></AiTwotoneLike>
                      )}
                      <span>Like</span>
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => AddComment(detailsList, e)}
                    >
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.8 1.1001L16.2 1.1001C17.1756 1.1001 18 1.9245 18 2.9001V12.8001C18 13.7757 17.1756 14.6001 16.2 14.6001H7.6326L4.722 17.802C4.6638 17.8656 4.5828 17.9001 4.5 17.9001C4.4637 17.9001 4.4271 17.8935 4.3917 17.88C4.2762 17.8353 4.2 17.724 4.2 17.6001V14.6001H1.8C0.8244 14.6001 0 13.7757 0 12.8001L0 2.9001C0 1.9245 0.8244 1.1001 1.8 1.1001Z"
                          fill="#0A0A13"
                        />
                      </svg>

                      <span onClick={(e) => AddComment(detailsList, e)}>
                        Comment
                      </span>
                    </span>
                    <span
                      className="share-forum-main"
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_702_2055)">
                          <path
                            d="M9.5332 4.76783C4.25256 5.04282 0.00585937 9.42586 0.00585937 14.7734L0.00585937 18.5L1.33916 15.3961C2.91606 12.2428 6.04472 10.2266 9.5332 10.0413V14.3064L17.9935 7.39063L9.5332 0.5V4.76783Z"
                            fill="#0A0A13"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_702_2055">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Share
                      </span>
                    </span>
                  </div>
                  <div className="lowerLine-1"></div>

                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "40%",
                    }}
                  >
                    {iserror}
                  </span>

                  <div
                    class="modal fade"
                    style={{ opacity: "1", marginTop: "32rem" }}
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Share Via
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <TwitterShareButton className="mx-3" url={shareUrl}>
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={40} round />
                          </LinkedinShareButton>
                          <WhatsappShareButton className="mx-3" url={shareUrl}>
                            <WhatsappIcon size={40} round />
                          </WhatsappShareButton>
                          <EmailShareButton url={shareUrl}>
                            <EmailIcon size={40} round />
                          </EmailShareButton>
                          <TelegramShareButton className="mx-3" url={shareUrl}>
                            <TelegramIcon size={40} round />
                          </TelegramShareButton>
                        </div>
                        {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
                      </div>
                    </div>
                  </div>

                  {isshowcomment && (
                    <div className="commentStart first-cmt">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-11">
                            <div className="form-group">
                              <label
                                className="first-cmt1"
                                style={{ display: "block", fontSize: "20px" }}
                              >
                                Comment
                              </label>
                              <textarea
                                className="fb-textarea"
                                required
                                onChange={(e) => setcomment(e.target.value)}
                                id="comment"
                                name="comment"
                              >
                                {" "}
                              </textarea>
                              {/* <input
                                  className="form-control"
                                  type="text"
                                  name="comment"
                                  id="comment"
                                  placeholder=""
                                  required
                                  onChange={(e) => setcomment(e.target.value)} 
                                /> */}
                            </div>
                          </div>
                          <div className="col-xl-1">
                            <div className="Btp-button btp-btn-new">
                              <button
                                className="Btp-btn2 textarea-btn1 me-5"
                                type="submit"
                                style={{ padding: "4px 6px !important" }}
                              >
                                Post
                              </button>

                              <button
                                className="Btp-btn textarea-btn"
                                onClick={() => setisshowcomment(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {Commentlist.slice(0, commentsToShow).map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div className="commentStart">
                          <img src="../../images/profile4.png"></img>
                          &nbsp;&nbsp;
                          <div className="comment1P">
                            <div className="comment1">
                              {" "}
                              <h6 className="Forumname1">{item.username} </h6>
                              <span className="commentTime">
                                {item.createdAt}

                                {/* 8h &nbsp;
                                  <FiberManualRecordIcon className="dotIcon" />
                                  <FiberManualRecordIcon className="dotIcon" />
                                  <FiberManualRecordIcon className="dotIcon" /> */}
                              </span>
                              
                            </div>
                            <div>
                              <p className="comment1fourm">{item.comment}</p>
                            </div>
                            <div className="commentLike">
                              <span className="commentLikeT">
                                Like &nbsp;
                                {item?.likelist.length == 0 && (
                                  <AiOutlineLike
                                    size={20}
                                    onClick={(e) => updatecommentLike(item, e)}
                                  ></AiOutlineLike>
                                )}
                              </span>
                              {item?.likelist.map((u, i) => {
                                return (
                                  <span className="commentLikeT" key={u.id}>
                                    {JSON.parse(sessionStorage.getItem("items"))
                                      ._id == u.userID && (
                                        <AiTwotoneLike
                                          color="blue"
                                          onClick={(e) =>
                                            deletecommentLike(item, u, e)
                                          }
                                          size={20}
                                        ></AiTwotoneLike>
                                      )}
                                  </span>
                                );
                              })}
                              <span className="commentLikeT">
                                {item.likeBYcount}
                              </span>
                              <span className="commentReplay">
                                {" "}
                                <AiOutlineComment
                                  size={20}
                                  onClick={(e) => insertcommentReply(item.id)}
                                ></AiOutlineComment>{" "}
                                Reply{" "}
                              </span>
                              <span>
                              <button style={{"marginLeft":"490px"}} onClick={(e) => reportabusecommentid(item)}   data-toggle="modal" data-target="#exampleModal1">Report Abuse </button>
                                  
                              </span>
                            </div>
                            
                            {isshowcommentReply == item.id && (
                              <div className="commentStart">
                                <form>
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="form-group">
                                        <label>Add Reply</label>

                                        <input
                                          className="form-control"
                                          type="text"
                                          name="reply"
                                          id="reply"
                                          placeholder=""
                                          required
                                          onChange={(e) =>
                                            setreply(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Btp-button ">
                                    <button
                                      className="Btp-btn2"
                                      type="button"
                                      onClick={(e) => SubmitReply(item, e)}
                                    >
                                      post
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        </div>
                        {item?.replylist.map((u, i) => {
                          return (
                            <div className="commentStart commentStart2">
                              <img src="../../images/Ellipse 9.png"></img>
                              &nbsp;&nbsp;
                              <div className="comment1P">
                                <div className="comment1">
                                  {" "}
                                  <h6 className="Forumname1">{u.username}</h6>
                                  <span className="commentTime">
                                    {day}/{month}/{year}&nbsp;&nbsp;{h}:{m}
                                    {/* <FiberManualRecordIcon className="dotIcon" />
                                    <FiberManualRecordIcon className="dotIcon" />
                                    <FiberManualRecordIcon className="dotIcon" /> */}
                                  </span>
                                </div>
                                <div>
                                  <p className="comment1fourm">{u.reply}</p>
                                </div>
                                {/* <div className="commentLike">
                                  <span className="commentLikeT">
                                    Like &nbsp;
                                    <img src="../../images/Like&N.png"></img>
                                  </span>
                                  <span className="commentReplay">Reply 1</span>
                                </div> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {commentsToShow < Commentlist.length && (
                    <button
                      onClick={() => setCommentsToShow(commentsToShow + 4)}
                      className="view-more-btn-fb"
                    >
                      View More
                    </button>
                  )}
                  {/* <div className="commentLoadM">
                   <Link to="#"> <p className="commentLoad">Load More Comments</p> </Link>
                  </div> */}
                </div>
              </div>

            </div>
          </div>
          <div  class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="cntainer" style={{ padding: '1rem' }}>
                <div className="contact-us1" >
                  <h2 style={{"color":"red"}}>Are You Sure You Want To Report And Abuse This Commnet ?</h2> <br></br>
                 
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', fontSize: '15px' }}>
                      <button style={{"marginTop":"10px" ,"marginRight":"5%"}} type="submit" data-dismiss="modal" aria-label="Close" onClick={handleSubmitForm}>Yes</button>

                      <button style={{"marginTop":"10px"}}  type="button" data-dismiss="modal" aria-label="Close">No</button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </section>
        <Footer />
      </div>
      </>
    );
}

export default BlogByPmc