import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ServiceStore from '../../utils/ServiceStore';
import { useHistory } from "react-router-dom";

const Notifications = () => {
    const [activeTab, setActiveTab] = useState("one");
    const [user, setUser] = useState();
    const [notifications, setNotifications] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const history = useHistory();


    useEffect(() => {
        const localSTorageData = JSON.parse(sessionStorage.getItem("items"));


        const methods = "/user";
        new ServiceStore().GetAllData(methods).then((res) => {
            // console.log("---new header---",res);
            res.data.filter((data) => {
                if (localSTorageData) {
                    // console.log("---new header---",data);
                    if (localSTorageData._id === data._id) {
                        setUser(data);
                    }
                }
            });
        });


        if (localSTorageData) {
            new ServiceStore()
                .GetAllData(`notification/${localSTorageData._id}`)
                .then((res) => {
                    // console.log(res.data)
                    if (res.data) {
                        if (res.data.count > 0) {
                            console.log("notification : ", res.data.list[0].notifications);
                            let array = [];
                            res.data.list[0].notifications.map((item, index) => {
                                console.log(`${index} : ${item}`);
                                array.push(item);
                            });

                            console.log("array : ", array);

                            setNotifications(array);
                        } else {
                            console.log("Notifications deleted");
                            setNotifications();
                        }
                    } else {
                        setNotifications();
                    }
                });
        }

        if (localSTorageData) {
           const body = {
                "page":"1", 
                "limit":"10"
              }
            new ServiceStore()
              .InsertData(`/announcement/list`,body)
              .then((res) => {
                console.log(res.response.data)
                setAnnouncements(res.response.data.rows)
                // if (res.response.data) {
                //   if (res.response.data.count > 0) {
                //     let array = [];
                //     res.response.data.rows.map((item, index) => {
                //       console.log(`${index} : ${item}`);
                //       array.push(item);
                //     });     
                //     console.log("array : ", array);
                //     setNotifications(array);
                //   } else {
                //     console.log("Announcement deleted");
                //     setAnnouncements();
                //   }
                // } else {
                //   setAnnouncements();
                // }
              });
          }

    }, [])

    const deleteAnnouncement = ()=>{
        new ServiceStore()
        .DeleteData("announcement/delete/", user._id)
        .then((res) => {
            console.log("response : ", res);
        });
    history.push("/English/Dashboard");
    }

    const NavigateToDashboard = () => {
        new ServiceStore()
            .DeleteData("notification/deletenotification/", user._id)
            .then((res) => {
                console.log("response : ", res);
            });
        history.push("/English/Dashboard");
    };

    const NavigateToDashboard2=()=>{
        NavigateToDashboard();
        deleteAnnouncement();
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
            <div className="container-fluid Btp-body">
                <div className="row">
                    <div className="Btp-header">
                        <span className="Btp-upload">Announcements By PMC</span>
                        <span className="Btp-cross">
                            <Link to="/">
                                <img src="../../images/crossArr.png" onClick={NavigateToDashboard2} />
                            </Link>
                        </span>
                    </div>
                    <div className="warpper-dash">
                        <input
                            className="radio"
                            id="one"
                            name="group"
                            type="radio"
                            value="blogs"
                            // checked
                            checked={activeTab === "one"}
                            onChange={() => handleTabChange("one")}
                        />
                        <input
                            className="radio"
                            id="two"
                            name="group"
                            type="radio"
                            value="images"
                            checked={activeTab === "two"}
                            onChange={() => handleTabChange("two")}
                        />

                        <div className="tabs">
                            <label className="tab" id="one-tab" for="one">
                                Notifications
                            </label>
                            <label className="tab" id="two-tab" for="two">
                                Announcement
                            </label>
                        </div>

                        <div className="panels">
                            <div className="panel" id="one-panel">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="wrapper-table">
                                                    <div className="table">
                                                        <div className="row header blue row-check">
                                                            <div className="cell">Notifications</div>
                                                        </div>
                                                        {notifications &&
                                                            notifications.map((item, index) => (
                                                                <div className="row row-check" key={index}>
                                                                        <div className="cell" data-title="Username">
                                                                            <a className="dropdown-item" onClick={NavigateToDashboard}>{item}</a>
                                                                        </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    {notifications <= 0 && (
                                                    <div className="not-found-div">
                                                        <h1>No Data Found</h1>
                                                    </div>
                                                   )} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel" id="two-panel">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-12">
                                            <div className="wrapper-table">
                                                    <div className="table">
                                                        <div className="row header blue row-check">
                                                            <div className="cell">Announements</div>
                                                        </div>
                                                        {announcements &&
                                                            announcements.map((item, index) => (
                                                                <div className="row row-check" key={index} >
                                                                        <div className="cell" data-title="Username">
                                                                            <a className="dropdown-item" >{item.title}</a>
                                                                        </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    {announcements <= 0 && (
                                                    <div className="not-found-div">
                                                        <h1>No Data Found</h1>
                                                    </div>
                                                     )} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notifications