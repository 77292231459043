import React from "react";
import { Link } from "react-router-dom";
import "./logIn.css";
import { useHistory } from "react-router-dom";
import ServiceStore from "../../utils/ServiceStore";
import { Row, Col, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useState } from "react";
import { toast } from "react-toastify";


const LogIn = () => {
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showErrorOTP, setShowErrorOTP] = useState(false);
  const [showErrorOTPMessage, setShowErrorOTPMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [isSendingOTP ,setIsSendingOTP]=useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [otpGet, setOtpGet] = React.useState(false);

  // const onSubmit = (e) => {
  //   const data = e;
  //   const method = "user/login";
  //   new ServiceStore().InsertData(method, data).then((res) => {
  //     if (res.response.data.status === 0) {
  //       setShowErrorMessage(res.response.data.message);
  //       setShowError(true);
  //     } else {
  //       setOtpGet(true);
  //       toast.success("Otp Send!", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       sessionStorage.setItem("items", JSON.stringify(res.response.data));
  //     }
  //   }).catch((err)=>{
  //     if(err.message==="Request failed with status code 500"){
  //       toast.warn("Network error!", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   })
  // };
  
  const onSubmit = (e) => {
    if (cooldown) {
      toast.warn("कृपया दुसरा ओटीपी पाठवण्यापूर्वी प्रतीक्षा करा.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Don't proceed if cooldown is active
    }
  
    setIsSendingOTP(true);
    setCooldown(true); // Activate the cooldown
  
    const data = e;
    const method = "user/login";
    new ServiceStore().InsertData(method, data)
      .then((res) => {
        if (res.response.data.status === 0) {
          let msg;
          if(res.response.data.message === 'Mobile number is not registered, Kindly register!')
          {
            msg = 'भ्रमणध्वनी क्रमांक नोंदणीकृत नाही, कृपया नोंदणी करा!'
          }
          else if(res.response.data.message === 'Email id is not registered, Kindly register!')
          {
            msg = 'ईमेल आयडी नोंदणीकृत नाही, कृपया नोंदणी करा!'
          }
          else
          {
            msg = res.response.data.message
          }
          toast.warn(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsSendingOTP(false);
          setCooldown(false); // Deactivate the cooldown after a certain time

        } else {
          setOtpGet(true);
          toast.success("ओटीपी यशस्वीरित्या पाठवले!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          sessionStorage.setItem("items", JSON.stringify(res.response.data));
          setIsSendingOTP(false);
          setTimeout(() => {
            setCooldown(false); // Deactivate the cooldown after a certain time
          }, 60000); // Set the cooldown duration in milliseconds (e.g., 1 minute)
        }
      })
      .catch((err) => {
        // Handle network error
        setIsSendingOTP(false);
        setCooldown(false); // Reset cooldown on error as well
      });
  };
  
  
  const resendOtp = () => {
    const body = {
      email:email
    }
    const method = "user/login";
     new ServiceStore().InsertData(method, body).then((res) => {
      if (res.response.data.status === 0) {
        setShowErrorMessage(res.response.data.message);
        setShowError(true);
      } else {
        setOtpGet(true);
        toast.success("ओटीपी पुन्हा पाठवला!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        sessionStorage.setItem("items", JSON.stringify(res.response.data));
      }
    }).catch((err)=>{
      if(err.message==="Request failed with status code 500"){
        toast.warn("नेटवर्क त्रुटी!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const onOtpSubmit = (e) => {
    const data = e;
    const method = "user/matchotp";
    new ServiceStore().InsertData(method, data).then((res) => {
      if (res.response.status == 1) {
        sessionStorage.setItem("token", JSON.stringify(res?.response?.token));
        toast.success("यशस्वीरित्या लॉग इन केले!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/Marathi/Home");
        setOtpGet(false);
      } else {
        setShowErrorOTPMessage(res.response.data.message);
        setShowErrorOTP(true);
      }     
    });
  };
  const formClass = classNames({
    "form-validate": true,
  });

  const handleInputChange = (event) => {
    // Clear the error message and set showError to false when input field is cleared
    if (event.target.value === "") {
      setShowError(false);
      setShowErrorMessage("");
      setShowErrorOTPMessage("res.response.data.message");
      setShowErrorOTP(false);
    }
    else{
      setEmail(event.target.value)
    }
  };
  return (
    <>
      <div className="container-fluid class-sign">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 login-height">
                    <div className="logo-head">
                      <Link to="/">
                        <img src="../../images/logo.png" />
                      </Link>
                    </div>
                    {!otpGet && (
                      <div className="form-divpm">
                        <h5 className="login-head">लॉगिन करा</h5>
                        <Form
                          className={formClass}
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="form-grouppm">
                            <label>ईमेल आयडी / भ्रमणध्वनी क्रमांक</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              id="email"
                              placeholder="ईमेल आयडी / भ्रमणध्वनी क्रमांक"
                              {...register("email", {
                                required: "ईमेल / भ्रमणध्वनी आवश्यक",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.message && errors.message.message}
                            {showError && (
                              <h5 className="errroMessage">
                                {showErrorMessage}
                              </h5>
                            )}
                          </div>

                          {/* <Link to="/Loginotp"> */}
                          <button type="submit" className="account-btnpm" disabled={isSendingOTP}>
                          {isSendingOTP ? "ओटीपी पाठवत आहे..." : "ओटीपी पाठवा"}
                          </button>
                          {/* </Link> */}
                          <div className="login-bottompm">
                            <span>खाते नाही  ? </span>
                            <Link to="/SignUp">साइन अप करा</Link>
                          </div>
                        </Form>
                      </div>
                    )}
                    {otpGet && (
                      <div className="lo-form-div">
                        <Form
                          className={formClass}
                          onSubmit={handleSubmit(onOtpSubmit)}
                        >
                          <div className="form-group">
                            <label>ओटीपी</label>
                            <input
                              className="form-control"
                              type="text"
                              name="otp"
                              id="otp"
                              placeholder="ओटीपी प्रविष्ट करा"
                              {...register("otp", {
                                required: "ओटीपी आवश्यक",
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.message && errors.message.message}
                            {showErrorOTP && (
                              <h5 className="errroMessage">
                                {showErrorOTPMessage}
                              </h5>
                            )}
                          </div>

                          <button type="submit" className="loginotp-btn">
                          लॉगिन करा
                          </button>

                          <div className="login-bottom">
                            <p>ओटीपी प्राप्त झाला नाही ? </p>
                            <p onClick={resendOtp} style={{color:"#1C21AB",cursor:"pointer"}}>&nbsp;ओटीपी पुन्हा पाठवा</p>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pad-none">
                <div className="right-side-imagepm">
                  <div className="quotes-classpm">                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogIn;
