import React from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const DonationFail = () => {
  const history = useHistory();

  return (
    <>
        <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop:"200px", marginBottom:"200px", width:"40%", textAlign:"center",padding:"40px"}}>
            <Icon.ExclamationTriangleFill style={{fontSize:50, color:"red", marginBottom:"20px"}}/>
            <h1 style={{marginBottom:"20px", color:"grey", fontWeight:"600",fontSize:"35px"}}>Transaction Failed</h1>
            <h3 style={{color:"grey"}}>Please try a different payment method</h3>
            <div>
            <button type="button" class="btn btn-outline-primary" style={{marginTop: "50px", fontSize:"15px", padding:"5px"}} size="lg" onClick={() =>history.push('/English/Donation')}>
                Try Again
            </button>
            </div>
            
        </div>
        </div>
    </>
  )
}

export default DonationFail
