import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./uploadPhoto.css";
import ServiceStore from "../../utils/ServiceStore";
import axios from "axios";
import { useHistory } from "react-router-dom";

const UploadPhoto = () => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [inpVal, setInpVal] = useState([]);
  const [uploadImg, setUploadImg] = useState([]);
  const isFirstRender = useRef(true);
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    description: "",
    file: [],
  });

  const history = useHistory();

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    const totalPhotos = selectedPhotos.length + files.length;

    if (totalPhotos > 5) {
      alert("Please select only 5 photos at a time");
      return;
    }

    setSelectedPhotos([...selectedPhotos, ...files]);
    setFormData({
      ...formData,
      file: Array.from(event.target.files).slice(0, 5),
    });
  };

  const handleDeletePhoto = (index) => {
    setSelectedPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos.splice(index, 1);
      return updatedPhotos;
    });
  };

  const handleInputChange = (e) => {
    setInpVal({ ...inpVal, [e.target.name]: e.target.value });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const language = sessionStorage.getItem("selectedLanguage");
    const user = JSON.parse(sessionStorage.getItem("items"));

    const userId = user._id;

    const data = new FormData();
    data.append("title", formData.title);
    data.append("location", formData.location);
    data.append("description", formData.description);
    formData.file.forEach((image, index) => {
      data.append("file", image);
    });
    data.append("status", "Pending");
    data.append("userId", userId);

    new ServiceStore().InsertData("UserUploadImage", data).then((res) => {
      if (res) {
        alert("Successfully Uploaded!");
        history.push('/English/Gallery')
      }
    });
    //Trying to add in local
    // axios
    //   .post("http://localhost:3001/admin/UserUploadImage", data)
    //   .then((res) => {
    //   });
  };

  return (
    <div>
      <div className="container-fluid mainUpload">
        <div className="row">
          <div className="col-xl-12 uploadFirst">
            <span className="uploadText">Upload Photo</span>
            <span className="uploadArrow">
              <Link to="/English/Gallery">
                <img src="../../images/crossArr.png"></img>
              </Link>
            </span>
          </div>
          <div className="uploadSecond">
            <div className="col-xl-5 col-md-5">
              <div className="uploadRect">
                <p className="uploadText1">
                  Drag and Drop Photos Here
                  <br />
                  <br />
                  Or
                </p>
                <div className="uploadmainB">
                  <input
                    type="file"
                    name="file"
                    className="uploadButton1"
                    multiple
                    onChange={handlePhotoUpload}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-3 uplaodAllI">
              {selectedPhotos.map((photo, index) => (
                <div key={index} className="pb-2">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={`Uploaded photo ${index}`}
                  />
                  <button
                    onClick={() => handleDeletePhoto(index)}
                    className="ms-2 delete-btn"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="uploadForm">
                <form>
                  <div className="form-group uploadTitle">
                    <label for="exampleFormControlInput1">Photo Title</label>
                    <input
                      type="email"
                      name="title"
                      className="form-control uploadInput"
                      placeholder="Title"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group uploadTitle">
                    <label for="exampleFormControlSelect1">Location</label>
                    <input
                      type="text"
                      name="location"
                      className="form-control uploadInput"
                      placeholder="Enter Location"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group uploadTitle">
                    <label for="exampleFormControlTextarea1">Description</label>
                    <input
                      type="text"
                      name="description"
                      className="form-control uploadInput uploadDes"
                      placeholder="Add Description"
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="">
            <div className="uploadCol">
              <div className="col-sm-6 col-md-3 col-lg-3">
                <h6 className="uploadReq">Requirements</h6>
                <ol className="uploadReq1">
                  <li>
                    A maximum of five images can be uploaded at once per user.{" "}
                  </li>
                  <li>Only JPEG and PNG image formats are accepted.</li>
                  <li>The upload size limit is 15MB or less.</li>
                </ol>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3">
                <h6 className="uploadReq3">Rules</h6>
                <ol className="uploadReq2">
                  <li>
                    Your images will be made visible to the public only after
                    they have been reviewed and approved by an administrator.
                  </li>
                  <li>
                    We reserve the right to remove images after a specified
                    period of time.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="">
            <div className=" col-xl-6 uploadLastB">
              <Link to="/English/Gallery">
                <button className=" uploadCancle1 uploadCancle">Cancel</button>
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link>
                {/* to="/English/Uploadstatus" */}
                <button className="uploadAm uploadA" onClick={handleSubmit}>
                  Agree and Upload{" "}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPhoto;
