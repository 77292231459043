import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./gallery.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { useState } from "react";
import UploadPhoto from "../uploadPhoto/uploadPhoto";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const image1 = "/drive/Photography/amit deshpande.jpeg";
const image2 = "/drive/Photography/yash deshmukh.jpeg";
const image3 = "/drive/Photography/vilas tolsankar.jpeg";
const image4 = "/drive/Photography/uday patil.jpeg";
const image5 = "/drive/Photography/shivaji dhute.jpeg";
const image6 = "/drive/Photography/sayali mohite.jpeg";
const image7 = "/drive/Photography/mahendra kolhe.jpeg";
const image8 = "/drive/Photography/kalpesh nukte.jpeg";
const image9 = "/drive/Photography/chetan kawre.jpeg";
const image10 = "/drive/Photography/Amit darunkar.jpeg";

const image11 = "/drive/drawing/IMG_6397.JPG";
const image12 = "/drive/drawing/IMG_6398.JPG";
const image13 = "/drive/drawing/IMG_6399.JPG";
const image14 = "/drive/drawing/IMG_6400.JPG";
const image15 = "/drive/drawing/IMG_6401.JPG";
const image16 = "/drive/drawing/IMG_6402.JPG";
const image17 = "/drive/drawing/IMG_6403.JPG";
const image18 = "/drive/drawing/IMG_6404.JPG";
const image19 = "/drive/drawing/IMG_6405.JPG";
const image20 = "/drive/drawing/IMG_6406.JPG";
const image21 = "/drive/drawing/IMG_6413.JPG";
const image22 = "/drive/drawing/IMG_6414.JPG";
const image23 = "/drive/drawing/IMG_6415.JPG";
const image24 = "/drive/drawing/IMG_6748.JPG";
const image25 = "/drive/drawing/IMG_6751.JPG";
const image26 = "/drive/drawing/IMG_6752.JPG";
const image27 = "/drive/drawing/IMG_6753.JPG";
const image28 = "/drive/drawing/IMG_6754.JPG";
const image29 = "/drive/drawing/IMG_6755.JPG";
const image31 = "/drive/drawing/IMG_6756.JPG";
const image32 = "/drive/drawing/IMG_6757.JPG";
const image33 = "/drive/drawing/IMG_6758.JPG";
const image34 = "/drive/drawing/IMG_6759.JPG";
const image35 = "/drive/drawing/IMG_6763.JPG";
const image36 = "/drive/drawing/IMG_6764.JPG";
const image37 = "/drive/drawing/IMG_6765.JPG";
const image38 = "/drive/drawing/IMG_6766.JPG";
const image39 = "/drive/drawing/IMG_6767.JPG";
const image40 = "/drive/drawing/IMG_6768.JPG";
const image41 = "/drive/drawing/IMG_6769.JPG";
const image42 = "/drive/drawing/IMG_6770.JPG";
const image43 = "/drive/drawing/IMG_6771.JPG";
const image44 = "/drive/drawing/IMG_6772.JPG";
const image45 = "/drive/drawing/IMG_6774.JPG";

function Gallery() {
  const [datalist, setdatalist] = React.useState([]);
  const [lang, setLang] = React.useState([]);
  const [token, setToken] = React.useState();
  const [rotateClass, setRotateClass] = useState("");

  React.useEffect(() => {
    const token = sessionStorage.getItem("token");
    setToken(token);
    getAlldataFile();
    // Apply the rotation class after a delay to trigger the animation
    const rotationTimeout = setTimeout(() => {
      setRotateClass("rotated-image");
    }, 1000); // Adjust the delay (1000ms = 1 second) as needed

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(rotationTimeout);
  }, []);
  // http://localhost:3001/admin/UserUploadImage
  const getAlldataFile = () => {
    const methods = "gallary/English";
    new ServiceStore().GetAllData(methods).then((res) => {
      console.log("res : ", res.data);
      setLang(res.data[0].language);
      setdatalist(res.data);
    });
    const method = "UserUploadImage";
    new ServiceStore().GetAllData(method).then((res) => {});
  };

  const [gallary, setgallery] = React.useState([
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image45,
  ]);

  const [itemsToShow, setItemsToShow] = useState(4);

  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          {/* <Header /> */}
          <NewHeader />
          <div className="row header-pad-blog">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 top-class header-pad2">
              <div>
                <p className="future-p9">
                  {datalist[0]?.line1}
                  {/* Ready to make an impact? <br></br> Upload a photo and join
                  <br></br>the Community */}
                </p>

                {/* <p className="gal-p1">
                <span>Photo Subject:</span>
                <span>Water Bodies of Pune</span>
              </p> */}
                {/* <p className="gal-p2">16-02-2023 - 16-03-2023</p> */}
              </div>
              <div className="gal-btn">
                {!token ? (
                  <Link
                    onClick={() => {
                      alert("Please login!");
                    }}
                  >
                    <button className="btn-lets2">{datalist[0]?.line2} </button>
                  </Link>
                ) : (
                  <Link to="/English/UploadPhoto">
                    <button className="btn-lets2">{datalist[0]?.line2} </button>
                  </Link>
                )}
                {/* <button className="btn-lets">Know About Competition</button> */}
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 top-class new-class">
              <div className="about-first-sec1">
                <img
                  src={imgeurl + datalist[0]?.line3}
                  className="rotated-image"
                />
              </div>
              <div className="about-first-img">
                {/* <img src="../../images/about poly.png" /> */}
              </div>
            </div>
          </div>
          {/* <div>
            <section className="gal-sec-1">
              <div className="row">
                <p className="gal-p3">
                  <span>Winners of Competition</span>
                  <Link>
                    <span className="gal-p4">View Album</span>
                  </Link>
                </p>
              </div>
              <div className="row" style={{ marginTop: "-4rem" }}>
                <li style={{ listStyleType: "none" }} className="nav-item">
                  <Link to="/English/WinnersOfCompetition">
                    <a>
                      <span className="gal-p4" href="#">
                        View Album
                      </span>
                    </a>
                  </Link>
                </li>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                {datalist[0] && (
                  <div className="container-flex">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="row g-overlay">
                          <div className="col-md-6 middle">
                            <img
                              src={
                                imgeurl +
                                datalist[0]?.line4[
                                  datalist[0]?.line4.length - 1
                                ]?.originalname
                              }
                              alt={
                                datalist[0]?.line4[
                                  datalist[0]?.line4.length - 1
                                ]?.originalname
                                  ? datalist[0]?.line4[
                                      datalist[0]?.line4.length - 1
                                    ]?.originalname
                                  : "unknown.jpg"
                              }
                              className="gallery-images"
                            />
                          </div>
                          <div className="col-md-6 paddingAlldiv">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-6">
                                    <img
                                      src={
                                        imgeurl +
                                        datalist[0]?.line4[
                                          datalist[0]?.line4.length - 2
                                        ]?.originalname
                                      }
                                      alt={
                                        datalist[0]?.line4[
                                          datalist[0]?.line4.length - 2
                                        ]?.originalname
                                          ? datalist[0]?.line4[
                                              datalist[0]?.line4.length - 2
                                            ]?.originalname
                                          : "unknown.jpg"
                                      }
                                      className="gallery-images"
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div className="row">
                                      <div className="col-xl-12">
                                        <img
                                          src={
                                            imgeurl +
                                            datalist[0]?.line4[
                                              datalist[0]?.line4.length - 3
                                            ]?.originalname
                                          }
                                          alt={
                                            datalist[0]?.line4[
                                              datalist[0]?.line4.length - 3
                                            ]?.originalname
                                              ? datalist[0]?.line4[
                                                  datalist[0]?.line4.length - 3
                                                ]?.originalname
                                              : "unknown.jpg"
                                          }
                                          className="gallery-images"
                                        />
                                      </div>
                                      <div className="col-xl-12">
                                        <img
                                          src={
                                            imgeurl +
                                            datalist[0]?.line4[
                                              datalist[0]?.line4.length - 4
                                            ]?.originalname
                                          }
                                          alt={
                                            datalist[0]?.line4[
                                              datalist[0]?.line4.length - 4
                                            ]?.originalname
                                              ? datalist[0]?.line4[
                                                  datalist[0]?.line4.length - 4
                                                ]?.originalname
                                              : "unknown.jpg"
                                          }
                                          className="gallery-images gal-dhol"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div> */}

          {/* <section className="gal-sec-2">
            <div className="row">
              <p className="gal-p3">
                <span>Vintage Pune</span>
                <Link>
                <span className="gal-p4">View Album</span>
              </Link>
              </p>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/English/VintagePune">
                  <a>
                    <span className="gal-p4" href="#">
                      View Album
                    </span>
                  </a>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle">
                          <img
                            src={
                              imgeurl +
                              datalist[0]?.line5[datalist[0]?.line5.length - 1]
                                ?.originalname
                            }
                            alt={
                              datalist[0]?.line5[datalist[0]?.line5.length - 1]
                                ?.originalname
                                ? datalist[0]?.line5[
                                    datalist[0]?.line5.length - 1
                                  ]?.originalname
                                : "unknown.jpg"
                            }
                            className="gallery-images"
                          />
                        </div>
                        <div className="col-md-6 paddingAlldiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={
                                      imgeurl +
                                      datalist[0]?.line5[
                                        datalist[0]?.line5.length - 2
                                      ]?.originalname
                                    }
                                    alt={
                                      datalist[0]?.line5[
                                        datalist[0]?.line5.length - 2
                                      ]?.originalname
                                        ? datalist[0]?.line5[
                                            datalist[0]?.line5.length - 2
                                          ]?.originalname
                                        : "unknown.jpg"
                                    }
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={
                                          imgeurl +
                                          datalist[0]?.line5[
                                            datalist[0]?.line5.length - 3
                                          ]?.originalname
                                        }
                                        alt={
                                          datalist[0]?.line5[
                                            datalist[0]?.line5.length - 3
                                          ]?.originalname
                                            ? datalist[0]?.line5[
                                                datalist[0]?.line5.length - 3
                                              ]?.originalname
                                            : "unknown.jpg"
                                        }
                                        className="gallery-images"
                                      />
                                    </div>
                                    <div className="col-xl-12">
                                      <img
                                        src={
                                          imgeurl +
                                          datalist[0]?.line5[
                                            datalist[0]?.line5.length - 4
                                          ]?.originalname
                                        }
                                        alt={
                                          datalist[0]?.line5[
                                            datalist[0]?.line5.length - 4
                                          ]?.originalname
                                            ? datalist[0]?.line5[
                                                datalist[0]?.line5.length - 4
                                              ]?.originalname
                                            : "unknown.jpg"
                                        }
                                        className="gallery-images gal-dhol"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section> */}

          {/* <section className="gal-sec-1">
            <p className="gal-p3">
              <span>Photos By Citizens</span>
              <Link>
                <span className="gal-p4">View Album</span>
              </Link>
            </p>

            <div className="container-flex">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src="../../images/market.png"
                        className="gallery-images"
                      />
                    </div>
                    <div className="col-md-6 paddingAlldiv">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-6">
                              <img
                                src="../../images/ganesh.png"
                                className="gallery-images"
                              />
                            </div>
                            <div className="col-6">
                              <div className="row">
                                <div className="col-xl-12">
                                  <img
                                    src="../../images/road.png"
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-xl-12">
                                  <img
                                    src="../../images/dhol.png"
                                    className="gallery-images gal-dhol"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="gal-sec-2">
            <div className="row">
              <p className="gal-p3">
                <span>Photos by PMC</span>
                <Link>
                <span className="gal-p4">View Album</span>
              </Link>
              </p>
            </div>
            <div
              className="row"
              style={{ marginTop: "-4rem", marginBottom: "3rem" }}
            >
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/English/PhotosByPmc">
                  <a>
                    <span className="gal-p4" href="#">
                      View Album
                    </span>
                  </a>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle">
                          <img
                            src={
                              imgeurl +
                              datalist[0]?.line6[datalist[0]?.line6.length - 1]
                                ?.originalname
                            }
                            alt={
                              datalist[0]?.line5[datalist[0]?.line6.length - 1]
                                ?.originalname
                                ? datalist[0]?.line5[
                                    datalist[0]?.line6.length - 1
                                  ]?.originalname
                                : "unknown.jpg"
                            }
                            className="gallery-images"
                          />
                        </div>
                        <div className="col-md-6 paddingAlldiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={
                                      imgeurl +
                                      datalist[0]?.line6[
                                        datalist[0]?.line6.length - 2
                                      ]?.originalname
                                    }
                                    alt={
                                      datalist[0]?.line5[
                                        datalist[0]?.line6.length - 2
                                      ]?.originalname
                                        ? datalist[0]?.line5[
                                            datalist[0]?.line6.length - 2
                                          ]?.originalname
                                        : "unknown.jpg"
                                    }
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={
                                          imgeurl +
                                          datalist[0]?.line6[
                                            datalist[0]?.line6.length - 3
                                          ]?.originalname
                                        }
                                        alt={
                                          datalist[0]?.line5[
                                            datalist[0]?.line6.length - 3
                                          ]?.originalname
                                            ? datalist[0]?.line5[
                                                datalist[0]?.line6.length - 3
                                              ]?.originalname
                                            : "unknown.jpg"
                                        }
                                        className="gallery-images"
                                      />
                                    </div>
                                    <div className="col-xl-12">
                                      <img
                                        src={
                                          imgeurl +
                                          datalist[0]?.line6[
                                            datalist[0]?.line6.length - 4
                                          ]?.originalname
                                        }
                                        alt={
                                          datalist[0]?.line5[
                                            datalist[0]?.line6.length - 4
                                          ]?.originalname
                                            ? datalist[0]?.line5[
                                                datalist[0]?.line6.length - 4
                                              ]?.originalname
                                            : "unknown.jpg"
                                        }
                                        className="gallery-images gal-dhol"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section> */}

          {/* <section className="gal-sec-2" >
            <div className="row">
              <h1>Top Photographs from the PuneRé Photography Competition</h1>
              {gallary.slice(0, itemsToShow).map((image) => {
                return (
                  <div className="col-xl-3 col-md-3 col-sm-2">
                    <img style={{ width: '100%' }} src={image} />
                  </div>
                )
              })}
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
                <li style={{ listStyleType: "none" }} className="nav-item">
                  <Link to="/English/photographs">
                    <a>
                      <span className="gal-p4" href="#">
                        View Album
                      </span>
                    </a>
                  </Link>
                </li>
              </div>
          </section> */}

          {/* Top Photographs from the PuneRé Photography Competition */}
          <section className="gal-sec-1">
            <div className="row">
              <p>
                <span className="custom-span-title">
                  Top Photographs from the PuneRé Photography Competition
                </span>
              </p>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/English/Photographs">
                  <span className="gal-p4 padding-Top">View Album</span>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle col-xs-12 col-sm-12">
                          <img src={image1} className="gallery-images" />
                        </div>
                        <div className="col-md-6 paddingAlldiv col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={image2}
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={image3}
                                        className="gallery-images"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* Top Drawings from the PuneRé Drawing Competition */}
          <section className="gal-sec-1">
            <div className="row">
              <p>
                <span className="custom-span-title">
                  Top Drawings from the PuneRé Drawing Competition
                </span>
              </p>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <li style={{ listStyleType: "none" }} className="nav-item">
                <Link to="/English/Drawing">
                  <span className="gal-p4 padding-Top">View Album</span>
                </Link>
              </li>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              {datalist[0] && (
                <div className="container-flex">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row g-overlay">
                        <div className="col-md-6 middle">
                          <img src={image11} className="gallery-images" />
                        </div>
                        <div className="col-md-6 paddingAlldiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={image12}
                                    className="gallery-images"
                                  />
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <img
                                        src={image13}
                                        className="gallery-images"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* News & Updates */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>News & Updates</h1>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Inauguration of Mula Mutha River Rejuvenation Project by Prime
                  Minister Narendra Modi
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NikxIJtA3t0"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी यांच्या हस्ते उद्घाटन"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  PuneRé logo and mascot
                  unveiled&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color: "rgb(222 215 222)" }}>.</span>
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/I4UJ7eiGptk"
                  title="PuneRé लोगो व मॅसकॉट अनावरण"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </section> */}

          {/* News & Updates Carousel */}
          <section>
            <div className="row">
              <h1 className="padding-left">News & Updates</h1>
              <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
              >
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/NikxIJtA3t0"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे पंतप्रधान नरेंद्र मोदी यांच्या हस्ते उद्घाटन"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Inauguration of Mula Mutha River Rejuvenation Project by
                    Prime Minister Narendra Modi
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/I4UJ7eiGptk"
                    title="PuneRé लोगो व मॅसकॉट अनावरण"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">PuneRé logo and mascot unveiled</p>
                </div>
              </Carousel>
            </div>
          </section>

          {/* Engagement */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>Engagement</h1>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Painting Competition</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ahH0lHVGaxg"
                  title="PuneRé चित्रकला स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé organizes 'Olva of memories'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ySC5hVeROTc"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé organizes 'Olva of memories'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/zuHPYHzOxOw"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé organizes 'Olva of memories'</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bCu71cddo_Y"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Photography Competition</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/8zgp7MGX0M4"
                  title="PuneRé फोटोग्राफी स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bzOpGosv7hU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/s94rzVTEsKc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/5mnFMgcQVeU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/se0IY1gFXpc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/J9sIXb1ADlM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/kVv-Go1Agig"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/gs2tTLeo-So"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/x3wmwEd_7-4"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NlU_Uk_If0A"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ay-27y7fUqI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/4eIEJ0lSYWY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Photography Competition</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/l30y4yoGNvI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/pr1LNbkDD3w"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/aH1PrJjBxYQ"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/3yEgq7rTmVM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/9awZi5s3VhY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/tEK4V32kMbw"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Omw9W0SoPWc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/cvBwCnzZyHY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé Reel Competition- Shortlisted candidates</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/1s00ArncAXs"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </section> */}

          {/* Engagement Carousel */}
          <section>
            <h1 className="padding-left">Engagement</h1>
            <Carousel
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              stopOnHover={true}
            >
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ahH0lHVGaxg"
                  title="PuneRé चित्रकला स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">PuneRé Painting Competition</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ySC5hVeROTc"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">PuneRé organizes 'Olva of memories'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/zuHPYHzOxOw"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">PuneRé organizes 'Olva of memories'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bCu71cddo_Y"
                  title="PuneRé आयोजित ‘आठवणीचा ओलवा’"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">PuneRé organizes 'Olva of memories'</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/8zgp7MGX0M4"
                  title="PuneRé फोटोग्राफी स्पर्धा"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">PuneRé Photography Competition</p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bzOpGosv7hU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/s94rzVTEsKc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/5mnFMgcQVeU"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/se0IY1gFXpc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/J9sIXb1ADlM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/kVv-Go1Agig"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/gs2tTLeo-So"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/1s00ArncAXs"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/cvBwCnzZyHY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Omw9W0SoPWc"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/tEK4V32kMbw"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/9awZi5s3VhY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/3yEgq7rTmVM"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/aH1PrJjBxYQ"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/pr1LNbkDD3w"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/l30y4yoGNvI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/4eIEJ0lSYWY"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ay-27y7fUqI"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/NlU_Uk_If0A"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/x3wmwEd_7-4"
                  title="PuneRé रील स्पर्धा- शॉर्टलिस्ट केलेले उमेदवार"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p className="legend">
                  PuneRé Reel Competition- Shortlisted candidates
                </p>
              </div>
            </Carousel>
          </section>

          {/* Awarness */}
          {/* <section className="gal-sec-2">
            <div className="row">
              <h1>Awarness</h1>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>What exactly is Mula Mutha River Rejuvenation Project?</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/iURHI49mFSM"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प नेमका काय आहे?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Do you know 'this' about Mula Mutha River Rejuvenation
                  Project?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/ZVwgMPsych8"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती आहे का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Know in detail the main objectives of Mula Mutha River
                  Rejuvenation Project.
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/bX0ic2UncGo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर जाणून घ्या."
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  How was the preparation done before starting work on the River
                  Rejuvenation Project?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/11k2X3NwJCI"
                  title="नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी करण्यात आली होती?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Will Mula Mutha River Rejuvenation Project Cause Floods?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/fNeZSN6stgw"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  How will the river water be cleaned due to Mula Mutha River
                  Rejuvenation Project?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/qr1zrGQ0tzw"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा पद्धतीने केले जाणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Pune residents, know how many kilometers of river bank Pune
                  city has got
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/jtxW6iyFlp8"
                  title="पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ मिळालाय"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Will the Mula Mutha River Rejuvenation Project involve
                  large-scale construction of crancrete?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/yLrEEiCZdJo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात क्राँक्रिटचे बांधकाम होणार का?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  How will Mula Mutha River Rejuvenation Project reduce flood
                  risk?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Qa2NBybscbo"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा होणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  While knowing about Mula Mutha river revival project, this
                  information about Pune Municipal Corporation is necessary!
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/SCcHjruoedg"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे महापालिकेबाबत ही माहिती हवीच!"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  How will the banks of Mula Mutha river be fully developed?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/E3HbbIxLPCo"
                  title="मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Due to this reason, the Pune Municipal Corporation undertook
                  the Mula Mutha River Rejuvenation Project
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/Lnpv0ObqAIs"
                  title="या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी पुनरुज्जीवन प्रकल्प"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Puneers, know the important objectives of Mula Mutha River
                  Rejuvenation Project
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/7MtPaG2oJeI"
                  title="पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे महत्त्वाचे उद्देश"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>PuneRé pledge initiative</h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/R-gA9qKSDRw"
                  title="PuneRé  प्रतिज्ञा उपक्रम"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  Information regarding Mula Mutha river revival project will be
                  provided from time to time by the Municipal Corporation
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/rFu1PSUy3yQ"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून वेळोवेळी दिली जाणार माहिती"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  How will Mula Mutha River Rejuvenation Project sustain water
                  in the river?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/RvJZUP-vQBE"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h3>
                  What technical studies have been done for Mula Mutha River
                  Rejuvenation Project?
                </h3>
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/7WXhsB6iLGE"
                  title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक अभ्यास केले गेले आहेत?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </section> */}

          {/* Awarness Carousel */}
          <section>
            <div className="row">
              <h1 className="padding-left">Awareness</h1>
              <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
              >
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/iURHI49mFSM"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प नेमका काय आहे?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    What exactly is Mula Mutha River Rejuvenation Project?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/ZVwgMPsych8"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत तुम्हाला ‘हे’ माहिती आहे का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Do you know 'this' about Mula Mutha River Rejuvenation
                    Project?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/bX0ic2UncGo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे प्रमुख उद्देश सविस्तर जाणून घ्या."
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Know in detail the main objectives of Mula Mutha River
                    Rejuvenation Project.
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/11k2X3NwJCI"
                    title="नदी पुनरुज्जीवन प्रकल्पाचे काम सुरू करण्यापूर्वी कशी तयारी करण्यात आली होती?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    How was the preparation done before starting work on the
                    River Rejuvenation Project?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/fNeZSN6stgw"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पूर येईल का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Will Mula Mutha River Rejuvenation Project Cause Floods?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/qr1zrGQ0tzw"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीचे पाणी स्वच्छ कशा पद्धतीने केले जाणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    How will the river water be cleaned due to Mula Mutha River
                    Rejuvenation Project?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/jtxW6iyFlp8"
                    title="पुणेकरांनो, जाणून घ्या पुणे शहराला किती किलोमीटरचा नदी काठ मिळालाय"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Pune residents, know how many kilometers of river bank Pune
                    city has got
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/yLrEEiCZdJo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पात मोठ्या प्रमाणात क्राँक्रिटचे बांधकाम होणार का?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Will the Mula Mutha River Rejuvenation Project involve
                    large-scale construction of crancrete?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/Qa2NBybscbo"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे पुराचा धोका कमी कसा होणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    How will Mula Mutha River Rejuvenation Project reduce flood
                    risk?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/SCcHjruoedg"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्प जाणून घेताना पुणे महापालिकेबाबत ही माहिती हवीच!"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    While knowing about Mula Mutha river revival project, this
                    information about Pune Municipal Corporation is necessary!
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/E3HbbIxLPCo"
                    title="मुळा मुठा नदीचा नदीकाठ पूर्णपणे कसा विकसित केला जाणार?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    How will the banks of Mula Mutha river be fully developed?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/Lnpv0ObqAIs"
                    title="या कारणामुळे पुणे महानगरपालिकेने हाती घेतला मुळा मुठा नदी पुनरुज्जीवन प्रकल्प"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Due to this reason, the Pune Municipal Corporation undertook
                    the Mula Mutha River Rejuvenation Project
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/7MtPaG2oJeI"
                    title="पुणेकरांनो, जाणून घ्या मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाचे महत्त्वाचे उद्देश"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Puneers, know the important objectives of Mula Mutha River
                    Rejuvenation Project
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/R-gA9qKSDRw"
                    title="PuneRé  प्रतिज्ञा उपक्रम"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">PuneRé pledge initiative</p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/rFu1PSUy3yQ"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पाबाबत महानगरपालिकेकडून वेळोवेळी दिली जाणार माहिती"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    Information regarding Mula Mutha river revival project will
                    be provided from time to time by the Municipal Corporation
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/RvJZUP-vQBE"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पामुळे नदीत पाणी कसे टिकेल?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    How will Mula Mutha River Rejuvenation Project sustain water
                    in the river?
                  </p>
                </div>
                <div>
                  <iframe
                    width="100%"
                    height="480"
                    src="https://www.youtube.com/embed/7WXhsB6iLGE"
                    title="मुळा मुठा नदी पुनरुज्जीवन प्रकल्पासाठी कोणकोणते तांत्रिक अभ्यास केले गेले आहेत?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p className="legend">
                    What technical studies have been done for Mula Mutha River
                    Rejuvenation Project?
                  </p>
                </div>
              </Carousel>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
