import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
// eslint-disable-next-line no-unused-vars
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./photosByPmc.css";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Button } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoArrowUp } from "react-icons/io5";
import { Prev } from "react-bootstrap/esm/PageItem";

function PhotosByPmc() {
  const [datalist, setdatalist] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);
  const pageSize = 3;

  React.useEffect(() => {
    getAlldataFile();
    if (datalist.length > 0) {
      fetchData();
    }
  }, [page]);
  // React.useEffect(() => {
  //   fetchData();
  // },[page])
  const handleInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollHeight + 1 >=
        document.documentElement.scrollTop
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
  }, []);
  const getAlldataFile = () => {
    setIsLoading(true);
    const methods = "gallary/English";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = res.data[0].line6.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    });
  };

  const fetchData = () => {
    setIsLoading(true);
    setTimeout(() => {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const newData = datalist.slice(startIndex, endIndex);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
    }, 500);
  };
  const imagePerRow = 3;
  const [next, setNext] = React.useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This smooth scroll is optional, you can remove it if you want an instant scroll
    });
  };
  return (
    <>
      <div className="outer-changes" style={{ background: "#DED7DE" }}>
        <div className="upper-wrapper">
          <section className="gal-sec-1">
            <div className="headerDiv">
              <div>
                <p className="gal-p3">
                  <span>Photos By Pmc</span>
                </p>
              </div>
              <div>
                <Link to="/English/Gallery">
                  <div>
                    <IoArrowBackOutline
                      style={{
                        float: "right",
                        height: "5em",
                        width: "3em",
                        marginRight: "10em",
                      }}
                    />
                    <span className="back-btn1">Back</span>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
        {/* <Link to="/English/Gallery">
          <div className="Ups-button" style={{ marginBottom: "10px" }}>
            <IoArrowBackOutline
              style={{
                float: "right",
                height: "5em",
                width: "3em",
                marginRight: "10em",
              }}
            /> */}
        {/* <span className="back-btn1">Back</span> */}
        {/* <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button> */}
        {/* </div>
        </Link> */}
        <div className="container-flex">
          <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {datalist[0]?.line6?.map((image, index) =>
              // image.line6.map((imageData, index) => (
                <div className="col-md-4" key={index}>
                  <img
                    src={imgeurl + image?.originalname}
                    style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "16px",
                    }}
                    alt={image?.originalname}
                  />
                </div>
              // ))
            )}
          </div>
          {/* <div className="text-center">
            {next < datalist?.length && (
              <Button
                variant="primary"
                size="lg"
                onClick={handleMoreImage}
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  textAlign: "center",
                }}
              >
                Load more
              </Button>
            )}
          </div> */}
        </div>
        {/* <div className="container-flex">
        <div
            className="row"
            style={{ marginLeft: "16px", marginRight: "16px" }}
          >
            {data?.map((image, index)=>{
              <div className="col-md-4">
              <img
                src={imgeurl + datalist[index].originalname}
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: "16px",
                }}
              />
            </div>
            })}
            </div>
        </div> */}
        {isLoading && (
          <div className="spinnerDiv">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div
          onClick={scrollToTop}
          className="Ups-button"
          style={{ marginBottom: "10px", cursor: "pointer" }}
        >
          <IoArrowUp
            style={{
              float: "right",
              height: "5em",
              width: "3em",
              marginRight: "10em",
            }}
          />
          <span className="back-btn1">Top</span>
          {/* <button style={{ float: "right" }} className="btn-1">
              Back to Gallery
            </button> */}
        </div>
      </div>
    </>
  );
}

export default PhotosByPmc;
