import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import "./survey.css";
import ServiceStore from "../../utils/ServiceStore";
import { useState } from "react";
import imgeurl from "../../utils/constant";

function Survey() {
  const [getData, setGetData] = useState([]);
  const surveyListRef = useRef(null);

  useEffect(() => {
    getAlldataFile();
  }, []);

  const getAlldataFile = () => {
    new ServiceStore().GetAllData("survey/surveylist").then((res) => {
      setGetData(res.data.list);
    });
  };

  const handleRoute = () => {
    window.open("https://www.surveymonkey.com/r/8RR2GJV", "_blank");
  };

  const scrollToList = () => {
    surveyListRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const openAlert = () => {
    alert("पृष्ठ गेले आहे त्याबद्दल क्षमस्व");
  };

  return (
    <>
      <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
            {/* <Header /> */}
            <NewHeader />
            <div className="row header-pad-fac">
              <div className="col-xl-6 col-lg-6">
                <div>
                  <h5 className="blog-h5">
                    तुमचा आवाज महत्त्वाचा:<br></br> आमचे सर्वेक्षण करून पुण्याची
                    राहणीमान सुधारण्यास मदत करा
                  </h5>
                </div>
                <div>
                  <Link>
                    {/* to='/English/Blogtopic' */}
                    <button className="btn-lets2" onClick={scrollToList}>
                      नवीनतम सर्वेक्षण घ्या
                    </button>
                  </Link>
                  <Link onClick={openAlert}>
                    <button className="btn-lets">
                      खुली सर्वेक्षणे अन्वेषण करा{" "}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 new-class new-class2">
                <div className="blog-first">
                  <img
                    src="../../images/survey-hed-img.png"
                    className="survey-rotated-image"
                  />
                </div>
              </div>
            </div>
            <div className="row header-pad header-margin">
              <div className="col-xl-12">
                <h5 className="latestblog1">नुकतेच प्रक्षेपण केले </h5>
                <div className="row" ref={surveyListRef}>
                  {/* <Link to="/English/SurveyDetail"> */}
                  {getData.map((data, index) => {
                    if (data.language === "marathi") {
                      return (
                        <div className="col-xl-4 col-md-4">
                          <div
                            onClick={() => {
                              window.open(`${data.link}`);
                            }}
                            className="card-blogs sur"
                          >
                            <img
                              src={imgeurl + data?.image}
                              className="survey-img-card"
                            />
                            <div className="container-fluid">
                              <p className="others">{data.title}</p>
                              <div className="bottom-card">
                                <span>शेवटची तारीख {data.endDate} </span>
                                <span className="pb-4">
                                  अंदाजे वेळ 2 मिनिटे
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}

                  {/* </Link> */}

                  {/* <div className="col-xl-4 col-md-4">
                    <Link to="#">
                      <div className="card-blogs sur">
                        <img
                          src="../../images/s-2.png"
                          className="survey-img-card"
                        />
                        <div className="container-fluid">
                          <p className="others">
                            Municipal corporation performance
                          </p>
                          <div className="bottom-card ">
                            <span>End date 31-03-2023 </span>
                            <span className="pb-4">Approx. Time 2 Min</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div> */}

                  {/* <div className="col-xl-4"></div> */}
                </div>
              </div>
            </div>

            {/* <div className="row header-pad header-margin-line2">
              <div className="col-xl-12">
                <h5 className="latestblog1">2 दिवसात संपेल</h5>
                <div className="row">
                  <div className="col-xl-4 col-md-4">
                    <Link to="#">
                      <div className="card-blogs sur">
                        <img
                          src="../../images/s-1.png"
                          className="survey-img-card"
                        />
                        <div className="container-fluid">
                          <p className="others">महापालिकेची कामगिरी</p>
                          <div className="bottom-card">
                            <span>शेवटची तारीख ३१-०३-२०२३ </span>
                            <span className="pb-4">अंदाजे वेळ 2 मिनिटे</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Survey;
