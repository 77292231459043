// import React, { useState } from "react";
// import "../../Marathi/forumblogs/forumblog.css";
// import Header from "../header/header";
// import Footer from "../footer/footer";
// import { Link } from "react-router-dom";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import NewHeader from "../NewHeader/NewHeader";
// import { useHistory } from "react-router-dom";
// import ServiceStore from "../../utils/ServiceStore";
// import imgeurl from "../../utils/constant";

// import {
//   AiOutlineLike,
//   AiTwotoneLike,
//   AiOutlineComment,
//   AiOutlineWechat,
// } from "react-icons/ai";
// import { red } from "@mui/material/colors";

// const ForumBlog = () => {
//   const history = useHistory();
//   const [detailsList, setDetailsList] = React.useState({});
//   const [isLikeshow, setisLikeshow] = React.useState(true);
//   const [iserror, setiserror] = React.useState("");
//   const [comment, setcomment] = React.useState("");

//   const [likecommentcommunityforum, setlikecommentcommunityforum] =
//     React.useState([]);

//   const [LikeList, setLikeList] = React.useState({});
//   const [likecommunityforumlist, setlikecommunityforumlist] = React.useState(1);
//   const [isshowcomment, setisshowcomment] = React.useState(false);
//   const [Commentlist, setCommentlist] = React.useState([]);
//   const [iscommentLikeshow, setiscommentLikeshow] = React.useState(undefined);
//   const [isshowcommentReply, setisshowcommentReply] = React.useState(undefined);
//   const [reply, setreply] = React.useState("");
//   const [relatedData, setRelatedData] = useState([]);

//   const insertcommentReply = (id) => setisshowcommentReply(id);

//   React.useEffect(() => {
//     const dataid = history.location.state?.detail.id;
//     const hisData = history.location.state;

//     if (dataid) {
//       getcommentcommunityforum();
//       getLikelist();
//       new ServiceStore()
//         .GetDataByID("conservationtopic/", dataid)
//         .then((res) => {
//           setDetailsList(res.data);
//         });

//       const auth = sessionStorage.getItem("items");
//       if (auth) {
//         const data = {
//           communitytopicId: dataid,
//           userID: JSON.parse(auth)._id,
//         };
//         new ServiceStore()
//           .GetDataBYFilter("likecommunityforum/MarathiListByID", data)
//           .then((res) => {
//             if (res.response.data.length == 1) {
//               setisLikeshow(false);
//             }
//             setLikeList(res.response.data);
//           });
//       }
//     }

//     new ServiceStore().GetAllData("conservationtopic/Marathi").then((res) => {
//       const array = [];
//       res.data.filter((data) => {
//         if (data.categoryId === hisData.categoryId) {
//           data.createdAt = data.createdAt
//             .split("T")[0]
//             .split("-")
//             .reverse()
//             .join("/");
//           array.push(data);
//         }
//       });
//       setRelatedData(array);
//     });
//   }, []);
//   const getLikelist = () => {
//     const dataid = history.location.state?.detail.id;
//     new ServiceStore()
//       .GetDataByID("likecommunityforum/Marathi/", dataid)
//       .then((res) => {
//         setlikecommunityforumlist(res.data);
//       });
//   };
//   const addLike = (index, e) => {
//     const auth = sessionStorage.getItem("items");
//     if (auth) {
//       const methods = "likecommunityforum/";
//       const data = {
//         language: "marathi",
//         username: JSON.parse(auth).name,
//         count: 1,
//         communitytopicId: detailsList.id,
//         like: 1,
//         status: "IsLike",
//         userID: JSON.parse(auth)._id,
//       };
//       new ServiceStore().InsertData(methods, data).then((res) => {
//         setisLikeshow(false);
//         getLikelist();
//       });
//     } else {
//       setiserror("Please Log in First");
//     }
//   };
//   const AddComment = (index, e) => {
//     setisshowcomment(true);
//   };

//   const deleteLike = (index, e) => {
//     const auth = sessionStorage.getItem("items");
//     if (auth) {
//       const methods = "likecommunityforum/deletelike";
//       const data = {
//         communitytopicId: detailsList.id,
//         userID: JSON.parse(auth)._id,
//       };
//       new ServiceStore().InsertData(methods, data).then((res) => {
//         setisLikeshow(true);
//         getLikelist();
//       });
//     } else {
//       setiserror("Please Log in First");
//     }
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (comment != undefined) {
//       const auth = JSON.parse(sessionStorage.getItem("items"));
//       const methods = "commentcommunityforum/";
//       const data = {
//         likecount: 0,
//         language: "marathi",
//         username: auth.name,
//         comment: comment,
//         communitytopicId: detailsList.id,
//         status: "IsComment",
//         userID: auth._id,
//       };
//       new ServiceStore().InsertData(methods, data).then((res) => {
//         getcommentcommunityforum();
//       });
//     }
//   };

//   const getcommentcommunityforum = () => {
//     const dataid = history.location.state?.detail.id;
//     const auth = JSON.parse(sessionStorage.getItem("items"));
//     const data = { communitytopicId: dataid, userID: auth._id };
//     new ServiceStore()
//       .GetDataBYFilter("commentcommunityforum/MarathiListByID", data)
//       .then((res) => {
//         setCommentlist(res.response.data);
//       });
//   };

//   const updatecommentLike = (index, e) => {
//     const auth = JSON.parse(sessionStorage.getItem("items"));
//     if (auth) {
//       const methods = "likecommentcommunityforum";
//       const data = {
//         likecount: 1,
//         language: "marathi",
//         username: auth.name,
//         communitytopicId: index.communitytopicId,
//         status: "IsLike",
//         userID: auth._id,
//         commentId: index.id,
//       };
//       new ServiceStore().InsertData(methods, data).then((res) => {
//         getcommentcommunityforum();
//       });
//     } else {
//       setiserror("Please Log in First");
//     }
//   };
//   const deletecommentLike = (index, u, e) => {
//     const auth = sessionStorage.getItem("items");
//     const likecount = Number(index.likecount) - 1;
//     if (auth) {
//       const methods = "likecommentcommunityforum/";

//       new ServiceStore().DeleteData(methods, u._id).then((res) => {
//         getcommentcommunityforum();
//       });
//     } else {
//       setiserror("Please Log in First");
//     }
//   };

//   const SubmitReply = (item, e) => {
//     e.preventDefault();
//     const auth = JSON.parse(sessionStorage.getItem("items"));
//     if (reply != "") {
//       const methods = "replaycommentcommunityforum/";
//       const data = {
//         likecount: 0,
//         language: "marathi",
//         username: auth.name,
//         reply: reply,
//         communitytopicId: item.communitytopicId,
//         status: "IsReply",
//         userID: auth._id,
//         commentId: item.id,
//       };
//       new ServiceStore().InsertData(methods, data).then((res) => {
//         getcommentcommunityforum();
//         setisshowcommentReply(undefined);
//         // history.push("/Marathi/Forum");
//       });
//     }
//   };
//   return (
//     <>
//       <div className="outer-changes">
//         <section className="facility-section">
//           <div className="upper-wrapper">
//             {/* <Header /> */}
//             <NewHeader />
//             <div className="row header-pad">
//               <div className="col-xl-9">
//                 <div className="left-side-details">
//                   <div className="left-side-info">
//                     <div className="blogd-top2">
//                       <img src="../../images/blog-icon.png" alt="" />
//                       <div>
//                         <span>{detailsList?.username}</span>
//                         <div className="date-check">
//                           {detailsList?.updatedAt}
//                         </div>
//                       </div>
//                     </div>
//                     <div>
//                       <svg
//                         width="14"
//                         height="18"
//                         viewBox="0 0 14 18"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           opacity="0.7"
//                           d="M12.0599 0L1.99376 0C1.17394 0 0.454102 0.699726 0.454102 1.52797L0.454102 16.9774C0.454102 17.2547 0.528474 17.4858 0.648338 17.6643C0.791683 17.8777 1.02249 18 1.26999 17.9999C1.50399 17.9999 1.75313 17.8919 1.9832 17.688L6.4868 13.7207C6.62589 13.5975 6.82569 13.5269 7.03343 13.5269C7.24108 13.5269 7.44047 13.5975 7.57997 13.7211L12.0685 17.6874C12.2994 17.8919 12.5314 18 12.765 18C13.16 18 13.545 17.6839 13.545 16.9775L13.545 1.52797C13.545 0.699726 12.8797 0 12.0599 0Z"
//                           fill="#0A0A13"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                   <div className="headings2">
//                     <h4>{detailsList?.title}</h4>
//                     <p>
//                       <div
//                         dangerouslySetInnerHTML={{
//                           __html: detailsList?.subtitle,
//                         }}
//                       ></div>
//                     </p>
//                   </div>
//                   <div className="top-a-class">
//                     <span>Category</span>
//                     <a href="" className="margin-span">
//                       {detailsList?.categoryname}
//                     </a>
//                   </div>
//                   <div className="blogd-comments">
//                     <span>
//                       <svg
//                         width="19"
//                         height="19"
//                         viewBox="0 0 19 19"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <g clipPath="url(#clip0_702_1990)">
//                           <path
//                             d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
//                             fill="url(#paint0_linear_702_1990)"
//                           />
//                           <path
//                             d="M10.9158 5.04888L11.1904 3.51969C11.3356 2.71119 10.8384 1.92596 10.0455 1.71148L9.80909 1.64751C9.57371 1.58386 9.32539 1.68968 9.20832 1.90359L6.42208 6.99411C6.32951 7.16326 6.15196 7.2685 5.95911 7.2685H4.39399C4.1025 7.2685 3.86621 7.50479 3.86621 7.79628V13.773C3.86621 14.02 4.03747 14.234 4.27846 14.288L8.55916 15.2484C8.59711 15.2569 8.63584 15.2612 8.67469 15.2612H13.5309C14.3383 15.2612 15.0164 14.6536 15.1047 13.8511L15.8118 7.42652C15.915 6.48913 15.181 5.66997 14.2379 5.66997H11.4353C11.1065 5.66997 10.8577 5.37251 10.9158 5.04888Z"
//                             fill="url(#paint1_linear_702_1990)"
//                           />
//                           <path
//                             d="M4.74984 14.7777H3.69428C3.40279 14.7777 3.1665 14.5414 3.1665 14.2499V6.86103C3.1665 6.56954 3.40279 6.33325 3.69428 6.33325H4.74984C5.04133 6.33325 5.27762 6.56954 5.27762 6.86103V14.2499C5.27762 14.5414 5.04133 14.7777 4.74984 14.7777Z"
//                             fill="white"
//                           />
//                         </g>
//                         <defs>
//                           <linearGradient
//                             id="paint0_linear_702_1990"
//                             x1="2.7825"
//                             y1="16.2175"
//                             x2="16.2175"
//                             y2="2.7825"
//                             gradientUnits="userSpaceOnUse"
//                           >
//                             <stop stop-color="#5558FF" />
//                             <stop offset="1" stop-color="#00C0FF" />
//                           </linearGradient>
//                           <linearGradient
//                             id="paint1_linear_702_1990"
//                             x1="5.0697"
//                             y1="15.1951"
//                             x2="14.7444"
//                             y2="5.5205"
//                             gradientUnits="userSpaceOnUse"
//                           >
//                             <stop stop-color="#ADDCFF" />
//                             <stop offset="1" stop-color="#EAF6FF" />
//                           </linearGradient>
//                           <clipPath id="clip0_702_1990">
//                             <rect width="19" height="19" fill="white" />
//                           </clipPath>
//                         </defs>
//                       </svg>
//                       <span>
//                         {likecommunityforumlist
//                           ? parseInt(likecommunityforumlist)
//                           : 1}
//                       </span>
//                     </span>
//                     <span className="span-comment">
//                       {Commentlist.length} Comments
//                     </span>
//                     <span>85 Shares</span>
//                   </div>
//                   <div className="lowerLine-1"></div>
//                   <div className="blogd-comments2">
//                     <span>
//                       {isLikeshow && (
//                         <AiOutlineLike
//                           size={35}
//                           onClick={(e) => {
//                             addLike(detailsList, e);
//                             setlikecommunityforumlist(
//                               parseInt(likecommunityforumlist) + 1
//                             );
//                           }}
//                         ></AiOutlineLike>
//                       )}
//                       {!isLikeshow && (
//                         <AiTwotoneLike
//                           color="blue"
//                           onClick={(e) => {
//                             deleteLike(detailsList, e);
//                             setlikecommunityforumlist(
//                               parseInt(likecommunityforumlist) - 1
//                             );
//                           }}
//                           size={35}
//                         ></AiTwotoneLike>
//                       )}
//                       <span>Like</span>
//                     </span>
//                     <span onClick={(e) => AddComment(detailsList, e)}>
//                       <svg
//                         width="18"
//                         height="19"
//                         viewBox="0 0 18 19"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           d="M1.8 1.1001L16.2 1.1001C17.1756 1.1001 18 1.9245 18 2.9001V12.8001C18 13.7757 17.1756 14.6001 16.2 14.6001H7.6326L4.722 17.802C4.6638 17.8656 4.5828 17.9001 4.5 17.9001C4.4637 17.9001 4.4271 17.8935 4.3917 17.88C4.2762 17.8353 4.2 17.724 4.2 17.6001V14.6001H1.8C0.8244 14.6001 0 13.7757 0 12.8001L0 2.9001C0 1.9245 0.8244 1.1001 1.8 1.1001Z"
//                           fill="#0A0A13"
//                         />
//                       </svg>

//                       <span>Comment</span>
//                     </span>
//                     <span>
//                       <svg
//                         width="18"
//                         height="19"
//                         viewBox="0 0 18 19"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <g clipPath="url(#clip0_702_2055)">
//                           <path
//                             d="M9.5332 4.76783C4.25256 5.04282 0.00585937 9.42586 0.00585937 14.7734L0.00585937 18.5L1.33916 15.3961C2.91606 12.2428 6.04472 10.2266 9.5332 10.0413V14.3064L17.9935 7.39063L9.5332 0.5V4.76783Z"
//                             fill="#0A0A13"
//                           />
//                         </g>
//                         <defs>
//                           <clipPath id="clip0_702_2055">
//                             <rect
//                               width="18"
//                               height="18"
//                               fill="white"
//                               transform="translate(0 0.5)"
//                             />
//                           </clipPath>
//                         </defs>
//                       </svg>

//                       <span>Share</span>
//                     </span>
//                   </div>
//                   <div className="lowerLine-1"></div>
//                   <span
//                     style={{
//                       color: "red",
//                       fontSize: "20px",
//                       marginLeft: "40%",
//                     }}
//                   >
//                     {iserror}
//                   </span>

//                   {isshowcomment && (
//                     <div className="commentStart">
//                       <form onSubmit={handleSubmit}>
//                         <div className="row">
//                           <div className="col-xl-12">
//                             <div className="form-group">
//                               <label>Comment</label>

//                               <input
//                                 className="form-control"
//                                 type="text"
//                                 name="comment"
//                                 id="comment"
//                                 placeholder=""
//                                 required
//                                 onChange={(e) => setcomment(e.target.value)}
//                               />
//                             </div>
//                           </div>
//                         </div>
//                         <div className="Btp-button">
//                           <button className="Btp-btn2" type="submit">
//                             post
//                           </button>
//                         </div>
//                       </form>
//                     </div>
//                   )}

//                   {Commentlist.map((item, index) => {
//                     return (
//                       <div key={item.id}>
//                         <div className="commentStart">
//                           <img src="../../images/profile4.png"></img>
//                           &nbsp;&nbsp;
//                           <div className="comment1P">
//                             <div className="comment1">
//                               {" "}
//                               <h6 className="Forumname1">{item.username} </h6>
//                               <span className="commentTime">
//                                 8h &nbsp;
//                                 <FiberManualRecordIcon className="dotIcon" />
//                                 <FiberManualRecordIcon className="dotIcon" />
//                                 <FiberManualRecordIcon className="dotIcon" />
//                               </span>
//                             </div>
//                             <div>
//                               <p className="comment1fourm">{item.comment}</p>
//                             </div>
//                             <div className="commentLike">
//                               <span className="commentLikeT">
//                                 Like &nbsp;
//                                 {item?.likelist.length == 0 && (
//                                   <AiOutlineLike
//                                     size={20}
//                                     onClick={(e) => updatecommentLike(item, e)}
//                                   ></AiOutlineLike>
//                                 )}
//                               </span>
//                               {item?.likelist.map((u, i) => {
//                                 return (
//                                   <span className="commentLikeT" key={u.id}>
//                                     {JSON.parse(sessionStorage.getItem("items"))
//                                       ._id == u.userID && (
//                                       <AiTwotoneLike
//                                         color="blue"
//                                         onClick={(e) =>
//                                           deletecommentLike(item, u, e)
//                                         }
//                                         size={20}
//                                       ></AiTwotoneLike>
//                                     )}
//                                   </span>
//                                 );
//                               })}
//                               <span className="commentLikeT">
//                                 {item.likeBYcount}
//                               </span>
//                               <span className="commentReplay">
//                                 {" "}
//                                 <AiOutlineComment
//                                   size={20}
//                                   onClick={(e) => insertcommentReply(item.id)}
//                                 ></AiOutlineComment>{" "}
//                                 Reply{" "}
//                               </span>
//                             </div>
//                             {isshowcommentReply == item.id && (
//                               <div className="commentStart">
//                                 <form>
//                                   <div className="row">
//                                     <div className="col-xl-12">
//                                       <div className="form-group">
//                                         <label>Add Reply</label>

//                                         <input
//                                           className="form-control"
//                                           type="text"
//                                           name="reply"
//                                           id="reply"
//                                           placeholder=""
//                                           required
//                                           onChange={(e) =>
//                                             setreply(e.target.value)
//                                           }
//                                         />
//                                       </div>
//                                     </div>
//                                   </div>
//                                   <div className="Btp-button">
//                                     <button
//                                       className="Btp-btn2"
//                                       type="button"
//                                       onClick={(e) => SubmitReply(item, e)}
//                                     >
//                                       post
//                                     </button>
//                                   </div>
//                                 </form>
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                         {item?.replylist.map((u, i) => {
//                           return (
//                             <div
//                               className="commentStart commentStart2"
//                               key={u._id}
//                             >
//                               <img src="../../images/Ellipse 9.png"></img>
//                               &nbsp;&nbsp;
//                               <div className="comment1P">
//                                 <div className="comment1">
//                                   {" "}
//                                   <h6 className="Forumname1">{u.username}</h6>
//                                   <span className="commentTime">
//                                     8h &nbsp;
//                                     <FiberManualRecordIcon className="dotIcon" />
//                                     <FiberManualRecordIcon className="dotIcon" />
//                                     <FiberManualRecordIcon className="dotIcon" />
//                                   </span>
//                                 </div>
//                                 <div>
//                                   <p className="comment1fourm">{u.reply}</p>
//                                 </div>
//                               </div>
//                             </div>
//                           );
//                         })}
//                       </div>
//                     );
//                   })}

//                   {/* <div className="commentLoadM">
//                    <Link to="#"> <p className="commentLoad">Load More Comments</p> </Link>
//                   </div> */}
//                 </div>
//               </div>
//               <div className="col-xl-3 relatedTopics">
//                 <h5 className="trending2">Related Topics</h5>
//                 <div className="right-side-details">
//                   <div className="row">
//                     {relatedData.length > 0 &&
//                       relatedData.map((data, index) => {
//                         return (
//                           <div key={index} className="col-xl-12">
//                             <div className="card-forum">
//                               <div className="container-fluid">
//                                 <div className="top-a-class">
//                                   <a href="">
//                                     {data?.topic ? data.topic : "Unknown"}
//                                   </a>
//                                 </div>
//                                 <div className="headings">
//                                   <h4>{data?.title ? data.title : "Title"}</h4>
//                                   <p
//                                     dangerouslySetInnerHTML={{
//                                       __html: data.subtitle,
//                                     }}
//                                   ></p>
//                                 </div>
//                                 <div className="DisViw">
//                                   <div className="disc-1">
//                                     <svg
//                                       width="18"
//                                       height="16"
//                                       viewBox="0 0 18 16"
//                                       fill="none"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                       <path
//                                         d="M7.13672 5.71552V4.69598C7.13672 3.72673 7.92527 2.93817 8.89453 2.93817H13.9043V1.00458C13.9043 0.616242 13.5895 0.301453 13.2012 0.301453H4.79883C4.41049 0.301453 4.0957 0.616242 4.0957 1.00458V5.71552H7.13672Z"
//                                         fill="#1C21AB"
//                                       />
//                                       <path
//                                         d="M8.89453 12.0436C7.92527 12.0436 7.13672 11.2551 7.13672 10.2858V6.7702H0.703125C0.314789 6.7702 0 7.08499 0 7.47333V13.0632C0 13.4515 0.314789 13.7663 0.703125 13.7663H2.56641V15.3463C2.56641 15.5546 2.7373 15.6985 2.91941 15.6985C2.99095 15.6985 3.06418 15.6763 3.12954 15.6271L5.59902 13.7663H9.10547C9.4938 13.7663 9.80859 13.4515 9.80859 13.0631V12.0436H8.89453V12.0436Z"
//                                         fill="#1C21AB"
//                                       />
//                                       <path
//                                         d="M17.2969 3.99286H8.89453C8.5062 3.99286 8.19141 4.30765 8.19141 4.69598V10.2858C8.19141 10.6742 8.5062 10.989 8.89453 10.989H12.401L14.8705 12.8498C14.9358 12.899 15.009 12.9212 15.0806 12.9212C15.2627 12.9212 15.4336 12.7773 15.4336 12.569V10.989H17.2969C17.6852 10.989 18 10.6742 18 10.2859V4.69602C18 4.30765 17.6852 3.99286 17.2969 3.99286Z"
//                                         fill="#1C21AB"
//                                       />
//                                     </svg>
//                                     <p>35 Discussing</p>
//                                   </div>
//                                   <div className="disc-1">
//                                     <svg
//                                       width="18"
//                                       height="12"
//                                       viewBox="0 0 18 12"
//                                       fill="none"
//                                       xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                       <path
//                                         d="M9 0.635193C5.56091 0.635193 2.44216 2.51675 0.140841 5.5729C-0.0469469 5.82329 -0.0469469 6.17309 0.140841 6.42347C2.44216 9.48331 5.56091 11.3649 9 11.3649C12.4391 11.3649 15.5578 9.48331 17.8592 6.42715C18.0469 6.17677 18.0469 5.82697 17.8592 5.57659C15.5578 2.51675 12.4391 0.635193 9 0.635193ZM9.2467 9.77787C6.96379 9.92148 5.07855 8.03992 5.22215 5.75333C5.33998 3.86809 6.86806 2.34001 8.7533 2.22218C11.0362 2.07858 12.9214 3.96014 12.7778 6.24673C12.6563 8.12829 11.1283 9.65636 9.2467 9.77787ZM9.13256 8.03255C7.90273 8.10988 6.88647 7.0973 6.96747 5.86747C7.03007 4.85121 7.85486 4.0301 8.87113 3.96382C10.101 3.8865 11.1172 4.89908 11.0362 6.1289C10.9699 7.14885 10.1451 7.96996 9.13256 8.03255Z"
//                                         fill="#1C21AB"
//                                       />
//                                     </svg>
//                                     <p>1558 Views</p>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className="bottom-contain">
//                                 <div className="blogd-top2">
//                                   <img src="../../images/profile4.png" alt="" />

//                                   <span>
//                                     {data?.username ? data.username : "Unknown"}
//                                   </span>
//                                 </div>
//                                 <div>
//                                   <span>
//                                     {data?.createdAt ? data.createdAt : ""}
//                                   </span>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         );
//                       })}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         <Footer />
//       </div>
//     </>
//   );
// };

// export default ForumBlog;

import React, { useState } from "react";
import "../../Marathi/forumblogs/forumblog.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NewHeader from "../NewHeader/NewHeader";
import { useHistory } from "react-router-dom";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
  TelegramIcon,
} from "react-share";
import {
  AiOutlineLike,
  AiTwotoneLike,
  AiOutlineComment,
  AiOutlineWechat,
} from "react-icons/ai";
import { red } from "@mui/material/colors";

const ForumBlog = () => {
  const history = useHistory();
  const [detailsList, setDetailsList] = React.useState({});
  const [isLikeshow, setisLikeshow] = React.useState(true);
  const [iserror, setiserror] = React.useState("");
  const [comment, setcomment] = React.useState("");
  const [likeCount, setLikeCount] = React.useState(477);

  const [likecommentcommunityforum, setlikecommentcommunityforum] =
    React.useState([]);

  const [LikeList, setLikeList] = React.useState({});
  const [likecommunityforumlist, setlikecommunityforumlist] = React.useState(1);
  const [isshowcomment, setisshowcomment] = React.useState(false);
  const [Commentlist, setCommentlist] = React.useState([]);
  const [iscommentLikeshow, setiscommentLikeshow] = React.useState(undefined);
  const [isshowcommentReply, setisshowcommentReply] = React.useState(undefined);
  const [reply, setreply] = React.useState("");
  const [newDate, setNewDate] = React.useState("");
  const insertcommentReply = (id) => setisshowcommentReply(id);
  const [commentsToShow, setCommentsToShow] = useState(4);
  const [relatedData, setRelatedData] = useState([]);
  const [saveContentIcon, setSaveContentIcon] = useState(false)


  React.useEffect(() => {
    const dataid = history.location.state?.id || history.location.state?._id
    const hisData = history.location.state;
    getcommentcommunityforum();
    if (dataid) {
      getLikelist();
      new ServiceStore()
        .GetDataByID("conservationtopic/", dataid)
        .then((res) => {
          const dates = res.data.updatedAt;
          setNewDate(
            res.data.updatedAt.split("T")[0].split("-").reverse().join("/")
          );
          setDetailsList(res.data);
        });
      // new ServiceStore().GetAllData('likecommunityforum/Marathi').then((res) => {
      //   setlikecommunityforumlist(res.data)
      // });
      const auth = sessionStorage.getItem("items");
      if (auth) {
        const data = {
          communitytopicId: dataid,
          userID: JSON.parse(auth)._id,
        };
        new ServiceStore()
          .GetDataBYFilter("likecommunityforum/MarathiListByID", data)
          .then((res) => {
            if (res.response.data.length == 1) {
              setisLikeshow(false);
            }
            setLikeList(res.response.data);
          });
      }
    }

    new ServiceStore().GetAllData("conservationtopic/Marathi").then((res) => {
      const array = [];
      res.data.filter((data) => {
        if (data.categoryId === hisData.categoryId) {
          data.createdAt = data.createdAt
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
          array.push(data);
        }
      });
      setRelatedData(array);
      if (res.data) {
        AlreadySaveOrNot();
      }
    });
  }, []);
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const shareUrl = window.location.href;

  const h = date.getHours();
  const m = date.getMinutes();

  const getLikelist = () => {
    const dataid = history.location.state?.id;
    new ServiceStore()
      .GetDataByID("likecommunityforum/Marathi/", dataid)
      .then((res) => {
        setlikecommunityforumlist(res.data ? res.data : 1);
      });
  };

  const AlreadySaveOrNot = () => {
    const auth = sessionStorage.getItem("items");
    const dataid = history.location.state?.id || history.location.state?._id
    if (auth) {
      const methods = "user/favourite/statuscheck";
      const data = {
        userId: JSON.parse(auth)._id,
        id: dataid,
        type: "topic"
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",res.response.data.isFav)
        if(res.response.data.isFav === "False")
        {
          setSaveContentIcon(false)
        }
        else{
          setSaveContentIcon(true)
        }
      });
    } else {
      setiserror("Please Log in First");
    }
  }

  const saveData = () => {
    const auth = sessionStorage.getItem("items");
    if (auth) {
      const methods = "user/favourite/add";
      const data = {
        userId: JSON.parse(auth)._id,
        id: detailsList.id,
        type: "topic"
      };
      new ServiceStore().UpdateData(methods, data).then((res) => {
       
        console.log(res)
      });
      setSaveContentIcon(true)
    } else {
      setiserror("Please Log in First");
    }

  }

  const unsaveData = () => {
    const auth = sessionStorage.getItem("items");
    if (auth) {
      const methods = "user/favourite/remove";
      const data = {
        userId: JSON.parse(auth)._id,
        id: detailsList.id,
        type: "topic"
      };
      new ServiceStore().UpdateData(methods, data).then((res) => {
       
        console.log(res)
      });
      setSaveContentIcon(false)
    } else {
      setiserror("Please Log in First");
    }
  }


  const addLike = (index, e) => {
    setlikecommunityforumlist(
      (likecommunityforumlist ? parseInt(likecommunityforumlist) : 1) + 1
    );
    const auth = sessionStorage.getItem("items");
    if (auth) {
      const methods = "likecommunityforum/";
      const data = {
        language: "marathi",
        username: JSON.parse(auth).name,
        count: 1,
        communitytopicId: detailsList.id,
        like: 1,
        status: "IsLike",
        userID: JSON.parse(auth)._id,
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        setisLikeshow(false);
        getLikelist();
      });
    } else {
      setiserror("Please Log in First");
    }
  };



  const AddComment = (index, e) => {
    setisshowcomment(true);
  };

  const deleteLike = (index, e) => {
    const auth = sessionStorage.getItem("items");
    if (auth) {
      const methods = "likecommunityforum/deletelike";
      const data = {
        communitytopicId: detailsList.id,
        userID: JSON.parse(auth)._id,
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        setisLikeshow(true);
        getLikelist();
      });
    } else {
      setiserror("Please Log in First");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (comment != undefined) {
      const auth = JSON.parse(sessionStorage.getItem("items"));
      const methods = "commentcommunityforum/";
      const data = {
        likecount: 0,
        language: "marathi",
        username: auth.name,
        comment: comment,
        communitytopicId: detailsList.id,
        status: "IsComment",
        userID: auth._id,
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        getcommentcommunityforum();
      });
    }
    setisshowcomment(false);
  };

  const getcommentcommunityforum = () => {
    const dataid = history.location.state?.id;
    const auth = JSON.parse(sessionStorage.getItem("items"));
    const data = { communitytopicId: dataid, userID: auth._id };
    new ServiceStore()
      .GetDataBYFilter("commentcommunityforum/MarathiListByID", data)
      .then((res) => {
        res.response.data.forEach(item => {
          const createdAt = new Date(item.createdAt);
          const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true 
          };
          const formattedDate = createdAt.toLocaleString('en-US', options);
          item.createdAt = formattedDate;
        });
        setCommentlist(res.response.data);
      });
  };

  const updatecommentLike = (index, e) => {
    const auth = JSON.parse(sessionStorage.getItem("items"));
    if (auth) {
      const methods = "likecommentcommunityforum";
      const data = {
        likecount: 1,
        language: "marathi",
        username: auth.name,
        communitytopicId: index.communitytopicId,
        status: "IsLike",
        userID: auth._id,
        commentId: index.id,
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        getcommentcommunityforum();
      });
    } else {
      setiserror("Please Log in First");
    }
  };
  const deletecommentLike = (index, u, e) => {
    const auth = sessionStorage.getItem("items");
    const likecount = Number(index.likecount) - 1;
    if (auth) {
      const methods = "likecommentcommunityforum/";

      new ServiceStore().DeleteData(methods, u._id).then((res) => {
        getcommentcommunityforum();
      });
    } else {
      setiserror("Please Log in First");
    }
  };

  const SubmitReply = (item, e) => {
    e.preventDefault();
    const auth = JSON.parse(sessionStorage.getItem("items"));
    if (reply != "") {
      const methods = "replaycommentcommunityforum/";
      const data = {
        likecount: 0,
        language: "marathi",
        username: auth.name,
        reply: reply,
        communitytopicId: item.communitytopicId,
        status: "IsReply",
        userID: auth._id,
        commentId: item.id,
      };
      new ServiceStore().InsertData(methods, data).then((res) => {
        getcommentcommunityforum();
        setisshowcommentReply(undefined);
        // history.push("/Marathi/Forum");
      });
    }
  };
  return (
    <>
      <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
            {/* <Header /> */}
            <NewHeader />
            <div className="row header-pad">
              <div className="col-xl-9">
                <div className="left-side-details">
                  <div className="left-side-info">
                    <div className="blogd-top2">
                      <img src="../../images/profile4.png" alt="" />
                      <div>
                        <span>{detailsList?.username}</span>
                        <div className="date-check">
                          {newDate ? newDate : ""}
                        </div>
                      </div>
                    </div>
                    <div style={{display:'flex'}}>
                    <h5>
                          Add to Favourite
                        </h5>
                    {(saveContentIcon) ? <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50" onClick={(e) => {
                            unsaveData(detailsList, e);

                          }}>
                            <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                          </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50"
                              onClick={(e) => {
                                saveData(detailsList, e);
                              }}
                            >
                              <path d="M 12.8125 2 C 12.335938 2.089844 11.992188 2.511719 12 3 L 12 47 C 11.996094 47.359375 12.1875 47.691406 12.496094 47.871094 C 12.804688 48.054688 13.1875 48.054688 13.5 47.875 L 25 41.15625 L 36.5 47.875 C 36.8125 48.054688 37.195313 48.054688 37.503906 47.871094 C 37.8125 47.691406 38.003906 47.359375 38 47 L 38 3 C 38 2.449219 37.550781 2 37 2 L 13 2 C 12.96875 2 12.9375 2 12.90625 2 C 12.875 2 12.84375 2 12.8125 2 Z M 14 4 L 36 4 L 36 45.25 L 25.5 39.125 C 25.191406 38.945313 24.808594 38.945313 24.5 39.125 L 14 45.25 Z"></path>
                            </svg>}
                    </div>
                  </div>
                  <div className="headings2">
                    <h4>{detailsList?.title}</h4>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: detailsList?.subtitle,
                        }}
                      ></div>
                    </p>
                  </div>
                  <div className="top-a-class">
                    <span>Category</span>
                    <a href="" className="margin-span">
                      {detailsList.categoryname
                        ? detailsList.categoryname
                        : "Unknown"}
                    </a>
                  </div>
                  <div className="blogd-comments" style={{display:'flex',gap:'3rem'}}>
                    <div>
                      <span style={{ display: 'flex' }}>
                        <div>
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_702_1990)">
                              <path
                                d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
                                fill="url(#paint0_linear_702_1990)"
                              />
                              <path
                                d="M10.9158 5.04888L11.1904 3.51969C11.3356 2.71119 10.8384 1.92596 10.0455 1.71148L9.80909 1.64751C9.57371 1.58386 9.32539 1.68968 9.20832 1.90359L6.42208 6.99411C6.32951 7.16326 6.15196 7.2685 5.95911 7.2685H4.39399C4.1025 7.2685 3.86621 7.50479 3.86621 7.79628V13.773C3.86621 14.02 4.03747 14.234 4.27846 14.288L8.55916 15.2484C8.59711 15.2569 8.63584 15.2612 8.67469 15.2612H13.5309C14.3383 15.2612 15.0164 14.6536 15.1047 13.8511L15.8118 7.42652C15.915 6.48913 15.181 5.66997 14.2379 5.66997H11.4353C11.1065 5.66997 10.8577 5.37251 10.9158 5.04888Z"
                                fill="url(#paint1_linear_702_1990)"
                              />
                              <path
                                d="M4.74984 14.7777H3.69428C3.40279 14.7777 3.1665 14.5414 3.1665 14.2499V6.86103C3.1665 6.56954 3.40279 6.33325 3.69428 6.33325H4.74984C5.04133 6.33325 5.27762 6.56954 5.27762 6.86103V14.2499C5.27762 14.5414 5.04133 14.7777 4.74984 14.7777Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_702_1990"
                                x1="2.7825"
                                y1="16.2175"
                                x2="16.2175"
                                y2="2.7825"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#5558FF" />
                                <stop offset="1" stop-color="#00C0FF" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_702_1990"
                                x1="5.0697"
                                y1="15.1951"
                                x2="14.7444"
                                y2="5.5205"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#ADDCFF" />
                                <stop offset="1" stop-color="#EAF6FF" />
                              </linearGradient>
                              <clipPath id="clip0_702_1990">
                                <rect width="19" height="19" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div>
                          <span style={{ fontSize: '16px' }}>
                            {likecommunityforumlist
                              ? parseInt(likecommunityforumlist)
                              : 1}
                          </span>
                        </div>
                      </span>
                    </div>
                    <div>
                    <span className="span-comment" style={{fontSize:'16px'}}>
                      {Commentlist.length} Comments
                    </span>
                    </div> 
                    <span style={{ fontSize: '16px' }}>85 Shares</span>
                  </div>
                  <div className="lowerLine-1"></div>
                  <div className="blogd-comments2">
                    <span>
                      {isLikeshow && (
                        <AiOutlineLike
                          style={{ cursor: "pointer" }}
                          size={35}
                          onClick={(e) => {
                            addLike(detailsList, e);
                          }}
                        ></AiOutlineLike>
                      )}
                      {!isLikeshow && (
                        <AiTwotoneLike
                          style={{ cursor: "pointer" }}
                          color="blue"
                          onClick={(e) => {
                            deleteLike(detailsList, e);
                            setlikecommunityforumlist(
                              parseInt(likecommunityforumlist) - 1
                            );
                          }}
                          size={35}
                        ></AiTwotoneLike>
                      )}
                      <span>Like</span>
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => AddComment(detailsList, e)}
                    >
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.8 1.1001L16.2 1.1001C17.1756 1.1001 18 1.9245 18 2.9001V12.8001C18 13.7757 17.1756 14.6001 16.2 14.6001H7.6326L4.722 17.802C4.6638 17.8656 4.5828 17.9001 4.5 17.9001C4.4637 17.9001 4.4271 17.8935 4.3917 17.88C4.2762 17.8353 4.2 17.724 4.2 17.6001V14.6001H1.8C0.8244 14.6001 0 13.7757 0 12.8001L0 2.9001C0 1.9245 0.8244 1.1001 1.8 1.1001Z"
                          fill="#0A0A13"
                        />
                      </svg>

                      <span onClick={(e) => AddComment(detailsList, e)}>
                        Comment
                      </span>
                    </span>
                    <span style={{ cursor: "pointer" }} data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_702_2055)">
                          <path
                            d="M9.5332 4.76783C4.25256 5.04282 0.00585937 9.42586 0.00585937 14.7734L0.00585937 18.5L1.33916 15.3961C2.91606 12.2428 6.04472 10.2266 9.5332 10.0413V14.3064L17.9935 7.39063L9.5332 0.5V4.76783Z"
                            fill="#0A0A13"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_702_2055">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <span >Share</span>
                    </span>
                  </div>
                  <div className="lowerLine-1"></div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "40%",
                    }}
                  >
                    {iserror}
                  </span>
                  <div
                    class="modal fade"
                    style={{ opacity: "1", marginTop: "32rem" }}
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Share Via
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <TwitterShareButton className="mx-3" url={shareUrl}>
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={40} round />
                          </LinkedinShareButton>
                          <WhatsappShareButton className="mx-3" url={shareUrl}>
                            <WhatsappIcon size={40} round />
                          </WhatsappShareButton>
                          <EmailShareButton url={shareUrl}>
                            <EmailIcon size={40} round />
                          </EmailShareButton>
                          <TelegramShareButton className="mx-3" url={shareUrl}>
                            <TelegramIcon size={40} round />
                          </TelegramShareButton>
                        </div>
                        {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
                      </div>
                    </div>
                  </div>
                  {isshowcomment && (
                    <div className="commentStart first-cmt">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-11">
                            <div className="form-group">
                              <label
                                className="first-cmt1"
                                style={{ display: "block", fontSize: "20px" }}
                              >
                                Comment
                              </label>
                              <textarea
                                className="fb-textarea"
                                required
                                onChange={(e) => setcomment(e.target.value)}
                                id="comment"
                                name="comment"
                              >
                                {" "}
                              </textarea>
                              {/* <input
                                  className="form-control"
                                  type="text"
                                  name="comment"
                                  id="comment"
                                  placeholder=""
                                  required
                                  onChange={(e) => setcomment(e.target.value)} 
                                /> */}
                            </div>
                          </div>
                          <div className="col-xl-1">
                            <div className="Btp-button btp-btn-new">
                              <button
                                className="Btp-btn2 textarea-btn1 me-5"
                                type="submit"
                                style={{ padding: "4px 6px !important" }}
                              >
                                Post
                              </button>

                              <button
                                className="Btp-btn textarea-btn"
                                onClick={() => setisshowcomment(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {Commentlist.slice(0, commentsToShow).map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div className="commentStart">
                          <img src="../../images/profile4.png"></img>
                          &nbsp;&nbsp;
                          <div className="comment1P">
                            <div className="comment1">
                              {" "}
                              <h6 className="Forumname1">{item.username} </h6>
                              <span className="commentTime">
                                {item.createdAt}

                                {/* 8h &nbsp;
                                  <FiberManualRecordIcon className="dotIcon" />
                                  <FiberManualRecordIcon className="dotIcon" />
                                  <FiberManualRecordIcon className="dotIcon" /> */}
                              </span>
                            </div>
                            <div>
                              <p className="comment1fourm">{item.comment}</p>
                            </div>
                            <div className="commentLike">
                              <span className="commentLikeT">
                                Like &nbsp;
                                {item?.likelist.length == 0 && (
                                  <AiOutlineLike
                                    size={20}
                                    onClick={(e) => updatecommentLike(item, e)}
                                  ></AiOutlineLike>
                                )}
                              </span>
                              {item?.likelist.map((u, i) => {
                                return (
                                  <span className="commentLikeT" key={u.id}>
                                    {JSON.parse(sessionStorage.getItem("items"))
                                      ._id == u.userID && (
                                        <AiTwotoneLike
                                          color="blue"
                                          onClick={(e) =>
                                            deletecommentLike(item, u, e)
                                          }
                                          size={20}
                                        ></AiTwotoneLike>
                                      )}
                                  </span>
                                );
                              })}
                              <span className="commentLikeT">
                                {item.likeBYcount}
                              </span>
                              <span className="commentReplay">
                                {" "}
                                <AiOutlineComment
                                  size={20}
                                  onClick={(e) => insertcommentReply(item.id)}
                                ></AiOutlineComment>{" "}
                                Reply{" "}
                              </span>
                            </div>
                            {isshowcommentReply == item.id && (
                              <div className="commentStart">
                                <form>
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="form-group">
                                        <label>Add Reply</label>

                                        <input
                                          className="form-control"
                                          type="text"
                                          name="reply"
                                          id="reply"
                                          placeholder=""
                                          required
                                          onChange={(e) =>
                                            setreply(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Btp-button ">
                                    <button
                                      className="Btp-btn2"
                                      type="button"
                                      onClick={(e) => SubmitReply(item, e)}
                                    >
                                      post
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        </div>
                        {item?.replylist.map((u, i) => {
                          return (
                            <div className="commentStart commentStart2">
                              <img src="../../images/Ellipse 9.png"></img>
                              &nbsp;&nbsp;
                              <div className="comment1P">
                                <div className="comment1">
                                  {" "}
                                  <h6 className="Forumname1">{u.username}</h6>
                                  <span className="commentTime">
                                    {day}/{month}/{year}&nbsp;&nbsp;{h}:{m}
                                    {/* <FiberManualRecordIcon className="dotIcon" />
                                    <FiberManualRecordIcon className="dotIcon" />
                                    <FiberManualRecordIcon className="dotIcon" /> */}
                                  </span>
                                </div>
                                <div>
                                  <p className="comment1fourm">{u.reply}</p>
                                </div>
                                {/* <div className="commentLike">
                                  <span className="commentLikeT">
                                    Like &nbsp;
                                    <img src="../../images/Like&N.png"></img>
                                  </span>
                                  <span className="commentReplay">Reply 1</span>
                                </div> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {commentsToShow < Commentlist.length && (
                    <button
                      onClick={() => setCommentsToShow(commentsToShow + 4)}
                      className="view-more-btn-fb"
                    >
                      View More
                    </button>
                  )}
                  {/* <div className="commentLoadM">
                   <Link to="#"> <p className="commentLoad">Load More Comments</p> </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-3 relatedTopics">
                <h5 className="trending2">Related Topics</h5>
                <div className="right-side-details">
                  <div className="row">
                    {relatedData.length > 0 &&
                      relatedData.map((data, index) => {
                        return (
                          <div key={index} className="col-xl-12">
                            <div className="card-forum">
                              <div className="container-fluid">
                                <div className="top-a-class">
                                  <a href="">
                                    {data?.topic ? data.topic : "Unknown"}
                                  </a>
                                </div>
                                <div className="headings">
                                  <h4>{data?.title ? data.title : "Title"}</h4>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: data.subtitle,
                                    }}
                                  ></p>
                                </div>
                                <div className="DisViw">
                                  <div className="disc-1">
                                    <svg
                                      width="18"
                                      height="16"
                                      viewBox="0 0 18 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.13672 5.71552V4.69598C7.13672 3.72673 7.92527 2.93817 8.89453 2.93817H13.9043V1.00458C13.9043 0.616242 13.5895 0.301453 13.2012 0.301453H4.79883C4.41049 0.301453 4.0957 0.616242 4.0957 1.00458V5.71552H7.13672Z"
                                        fill="#1C21AB"
                                      />
                                      <path
                                        d="M8.89453 12.0436C7.92527 12.0436 7.13672 11.2551 7.13672 10.2858V6.7702H0.703125C0.314789 6.7702 0 7.08499 0 7.47333V13.0632C0 13.4515 0.314789 13.7663 0.703125 13.7663H2.56641V15.3463C2.56641 15.5546 2.7373 15.6985 2.91941 15.6985C2.99095 15.6985 3.06418 15.6763 3.12954 15.6271L5.59902 13.7663H9.10547C9.4938 13.7663 9.80859 13.4515 9.80859 13.0631V12.0436H8.89453V12.0436Z"
                                        fill="#1C21AB"
                                      />
                                      <path
                                        d="M17.2969 3.99286H8.89453C8.5062 3.99286 8.19141 4.30765 8.19141 4.69598V10.2858C8.19141 10.6742 8.5062 10.989 8.89453 10.989H12.401L14.8705 12.8498C14.9358 12.899 15.009 12.9212 15.0806 12.9212C15.2627 12.9212 15.4336 12.7773 15.4336 12.569V10.989H17.2969C17.6852 10.989 18 10.6742 18 10.2859V4.69602C18 4.30765 17.6852 3.99286 17.2969 3.99286Z"
                                        fill="#1C21AB"
                                      />
                                    </svg>
                                    <p>35 Discussing</p>
                                  </div>
                                  <div className="disc-1">
                                    <svg
                                      width="18"
                                      height="12"
                                      viewBox="0 0 18 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 0.635193C5.56091 0.635193 2.44216 2.51675 0.140841 5.5729C-0.0469469 5.82329 -0.0469469 6.17309 0.140841 6.42347C2.44216 9.48331 5.56091 11.3649 9 11.3649C12.4391 11.3649 15.5578 9.48331 17.8592 6.42715C18.0469 6.17677 18.0469 5.82697 17.8592 5.57659C15.5578 2.51675 12.4391 0.635193 9 0.635193ZM9.2467 9.77787C6.96379 9.92148 5.07855 8.03992 5.22215 5.75333C5.33998 3.86809 6.86806 2.34001 8.7533 2.22218C11.0362 2.07858 12.9214 3.96014 12.7778 6.24673C12.6563 8.12829 11.1283 9.65636 9.2467 9.77787ZM9.13256 8.03255C7.90273 8.10988 6.88647 7.0973 6.96747 5.86747C7.03007 4.85121 7.85486 4.0301 8.87113 3.96382C10.101 3.8865 11.1172 4.89908 11.0362 6.1289C10.9699 7.14885 10.1451 7.96996 9.13256 8.03255Z"
                                        fill="#1C21AB"
                                      />
                                    </svg>
                                    <p>1558 Views</p>
                                  </div>
                                </div>
                              </div>
                              <div className="bottom-contain">
                                <div className="blogd-top2">
                                  <img src="../../images/profile4.png" alt="" />

                                  <span>
                                    {data?.username ? data.username : "Unknown"}
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    {data?.createdAt ? data.createdAt : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {/* <div className="col-xl-12">
                      <div className="card-forum">
                        <div className="container-fluid">
                          <div className="top-a-class">
                            <a href="">Water Conservation </a>
                          </div>
                          <div className="headings">
                            <h4>
                              How can we protect our rivers from <br></br>
                              pollution caused by human activities?
                            </h4>
                            <p>
                              Human activities such as industrialization,
                              agriculture, and urbanization have a significant
                              impact on the health of rivers. Let’s discuss the
                              ideas that we can reduce pollution created by
                              society{" "}
                            </p>
                          </div>
                          <div className="DisViw">
                            <div className="disc-1">
                              <svg
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.13672 5.71552V4.69598C7.13672 3.72673 7.92527 2.93817 8.89453 2.93817H13.9043V1.00458C13.9043 0.616242 13.5895 0.301453 13.2012 0.301453H4.79883C4.41049 0.301453 4.0957 0.616242 4.0957 1.00458V5.71552H7.13672Z"
                                  fill="#1C21AB"
                                />
                                <path
                                  d="M8.89453 12.0436C7.92527 12.0436 7.13672 11.2551 7.13672 10.2858V6.7702H0.703125C0.314789 6.7702 0 7.08499 0 7.47333V13.0632C0 13.4515 0.314789 13.7663 0.703125 13.7663H2.56641V15.3463C2.56641 15.5546 2.7373 15.6985 2.91941 15.6985C2.99095 15.6985 3.06418 15.6763 3.12954 15.6271L5.59902 13.7663H9.10547C9.4938 13.7663 9.80859 13.4515 9.80859 13.0631V12.0436H8.89453V12.0436Z"
                                  fill="#1C21AB"
                                />
                                <path
                                  d="M17.2969 3.99286H8.89453C8.5062 3.99286 8.19141 4.30765 8.19141 4.69598V10.2858C8.19141 10.6742 8.5062 10.989 8.89453 10.989H12.401L14.8705 12.8498C14.9358 12.899 15.009 12.9212 15.0806 12.9212C15.2627 12.9212 15.4336 12.7773 15.4336 12.569V10.989H17.2969C17.6852 10.989 18 10.6742 18 10.2859V4.69602C18 4.30765 17.6852 3.99286 17.2969 3.99286Z"
                                  fill="#1C21AB"
                                />
                              </svg>
                              <p>35 Discussing</p>
                            </div>
                            <div className="disc-1">
                              <svg
                                width="18"
                                height="12"
                                viewBox="0 0 18 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 0.635193C5.56091 0.635193 2.44216 2.51675 0.140841 5.5729C-0.0469469 5.82329 -0.0469469 6.17309 0.140841 6.42347C2.44216 9.48331 5.56091 11.3649 9 11.3649C12.4391 11.3649 15.5578 9.48331 17.8592 6.42715C18.0469 6.17677 18.0469 5.82697 17.8592 5.57659C15.5578 2.51675 12.4391 0.635193 9 0.635193ZM9.2467 9.77787C6.96379 9.92148 5.07855 8.03992 5.22215 5.75333C5.33998 3.86809 6.86806 2.34001 8.7533 2.22218C11.0362 2.07858 12.9214 3.96014 12.7778 6.24673C12.6563 8.12829 11.1283 9.65636 9.2467 9.77787ZM9.13256 8.03255C7.90273 8.10988 6.88647 7.0973 6.96747 5.86747C7.03007 4.85121 7.85486 4.0301 8.87113 3.96382C10.101 3.8865 11.1172 4.89908 11.0362 6.1289C10.9699 7.14885 10.1451 7.96996 9.13256 8.03255Z"
                                  fill="#1C21AB"
                                />
                              </svg>
                              <p>1558 Views</p>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-contain">
                          <div className="blogd-top2">
                            <img src="../../images/blog-icon.png" alt="" />

                            <span>Veronica Ben</span>
                          </div>
                          <div>
                            <span>22 Jan 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="card-forum">
                        <div className="container-fluid">
                          <div className="top-a-class">
                            <a href="">Water Conservation </a>
                          </div>
                          <div className="headings">
                            <h4>
                              How can we protect our rivers from <br></br>
                              pollution caused by human activities?
                            </h4>
                            <p>
                              Human activities such as industrialization,
                              agriculture, and urbanization have a significant
                              impact on the health of rivers. Let’s discuss the
                              ideas that we can reduce pollution created by
                              society{" "}
                            </p>
                          </div>
                          <div className="DisViw">
                            <div className="disc-1">
                              <svg
                                width="18"
                                height="16"
                                viewBox="0 0 18 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.13672 5.71552V4.69598C7.13672 3.72673 7.92527 2.93817 8.89453 2.93817H13.9043V1.00458C13.9043 0.616242 13.5895 0.301453 13.2012 0.301453H4.79883C4.41049 0.301453 4.0957 0.616242 4.0957 1.00458V5.71552H7.13672Z"
                                  fill="#1C21AB"
                                />
                                <path
                                  d="M8.89453 12.0436C7.92527 12.0436 7.13672 11.2551 7.13672 10.2858V6.7702H0.703125C0.314789 6.7702 0 7.08499 0 7.47333V13.0632C0 13.4515 0.314789 13.7663 0.703125 13.7663H2.56641V15.3463C2.56641 15.5546 2.7373 15.6985 2.91941 15.6985C2.99095 15.6985 3.06418 15.6763 3.12954 15.6271L5.59902 13.7663H9.10547C9.4938 13.7663 9.80859 13.4515 9.80859 13.0631V12.0436H8.89453V12.0436Z"
                                  fill="#1C21AB"
                                />
                                <path
                                  d="M17.2969 3.99286H8.89453C8.5062 3.99286 8.19141 4.30765 8.19141 4.69598V10.2858C8.19141 10.6742 8.5062 10.989 8.89453 10.989H12.401L14.8705 12.8498C14.9358 12.899 15.009 12.9212 15.0806 12.9212C15.2627 12.9212 15.4336 12.7773 15.4336 12.569V10.989H17.2969C17.6852 10.989 18 10.6742 18 10.2859V4.69602C18 4.30765 17.6852 3.99286 17.2969 3.99286Z"
                                  fill="#1C21AB"
                                />
                              </svg>
                              <p>35 Discussing</p>
                            </div>
                            <div className="disc-1">
                              <svg
                                width="18"
                                height="12"
                                viewBox="0 0 18 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 0.635193C5.56091 0.635193 2.44216 2.51675 0.140841 5.5729C-0.0469469 5.82329 -0.0469469 6.17309 0.140841 6.42347C2.44216 9.48331 5.56091 11.3649 9 11.3649C12.4391 11.3649 15.5578 9.48331 17.8592 6.42715C18.0469 6.17677 18.0469 5.82697 17.8592 5.57659C15.5578 2.51675 12.4391 0.635193 9 0.635193ZM9.2467 9.77787C6.96379 9.92148 5.07855 8.03992 5.22215 5.75333C5.33998 3.86809 6.86806 2.34001 8.7533 2.22218C11.0362 2.07858 12.9214 3.96014 12.7778 6.24673C12.6563 8.12829 11.1283 9.65636 9.2467 9.77787ZM9.13256 8.03255C7.90273 8.10988 6.88647 7.0973 6.96747 5.86747C7.03007 4.85121 7.85486 4.0301 8.87113 3.96382C10.101 3.8865 11.1172 4.89908 11.0362 6.1289C10.9699 7.14885 10.1451 7.96996 9.13256 8.03255Z"
                                  fill="#1C21AB"
                                />
                              </svg>
                              <p>1558 Views</p>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-contain">
                          <div className="blogd-top2">
                            <img src="../../images/blog-icon.png" alt="" />

                            <span>Veronica Ben</span>
                          </div>
                          <div>
                            <span>22 Jan 2023</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ForumBlog;
