import React, { useState } from "react";
import "./facility.css";
import Footer from "../footer/footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../header/header";
import NewHeader from "../NewHeader/NewHeader";
import ServiceStore from "../../utils/ServiceStore";
import imgeurl from "../../utils/constant";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Facility = () => {
  const [showText, setShowText] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [datalist, setdatalist] = React.useState([]);
  const [showText18, setShowText18] = useState(false);
  const [showText28, setShowText28] = useState(false);
  const [showText38, setShowText38] = useState(false);

  const [showImg2, setShowImg2] = useState(false);
  const [showText19, setShowText19] = useState(false);
  const [showText29, setShowText29] = useState(false);
  const [showText39, setShowText39] = useState(false);

  const [showImg3, setShowImg3] = useState(false);
  const [showText110, setShowText110] = useState(false);
  const [showText210, setShowText210] = useState(false);
  const [showText310, setShowText310] = useState(false);

  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [comment, setComment] = useState("")
  
  React.useEffect(() => {
    getAlldataFile();
  }, [showImg, showImg2, showImg3]);

  const getAlldataFile = () => {
    const methods = "facilities/English";
    new ServiceStore().GetAllData(methods).then((res) => {
      setdatalist(res.data);
    });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const body = {
      name: name,
      mobile: mobile,
      comment: comment,
    }
    const method = "/contactus/add"
    console.log("data :", body)
    new ServiceStore().InsertData(method, body).then((res) => {
      console.log(res)
    })
  }

  const handleInputChange = (e) => {
    console.log("Input changed:", e.target.name, e.target.value);

    const { name, value } = e.target;

    if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "comment") {
      setComment(value);
    }
  }

  return (
    <>
      <div className="outer-changes">
        <section className="facility-section">
          <div className="upper-wrapper">
            {/* <Header /> */}
            <NewHeader />
            <div
              className="row header-pad-fac"
              style={{ margin: "0", padding: "0" }}
            >
              <div className="col-xl-6 col-lg-6">
                <div>
                  <h5 className="future-h5">{datalist[0]?.line1}</h5>
                  <p className="future-p3">
                    {datalist[0]?.line2}
                    {/* River Rejuvenation Project is a comprehensive solution for
                    Mula- <br></br>Mutha river rejuvenation that includes river
                    cleaning, desilting, <br></br> developing the riverfront,
                    improving water consumption patterns <br></br> through
                    citizen awareness, upgrading the economy around the
                    riverfront, development of green spaces, and bio-diversity
                    improvement. */}
                  </p>
                </div>
                <div>
                  <Link data-toggle="modal" data-target="#exampleModal">
                    <button className="btn-lets2">{datalist[0]?.line3}</button>
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-6 col-lg-6 new-class new-class2"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div className="">
                  {/* <img width="550px"   height="500px" src={imgeurl + datalist[0]?.line4} /> */}
                  <img
                    width="405px"
                    height="555px"
                    src={imgeurl + "Image.png"}
                    className="back-rotate-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="images-sec">
          <div className="container-flex" style={{ margin: "0", padding: "0" }}>
            <div
              className="row outerRow1"
              style={{ margin: "0", padding: "0" }}
            >
              <div className="col-xl-12">
                <section className="section-env">
                  <div
                    className="row btn-new-after"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <div className="col-xl-6  ">
                      <div
                        className=""
                        style={{ display: showImg ? "block" : "none" }}
                      >
                        <button
                          onClick={() => {
                            setShowText18(true);
                            setShowText38(false);
                            setShowText28(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {" "}
                              {datalist[0]?.subline18}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText28(true);
                            setShowText18(false);
                            setShowText38(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline28}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText38(true);
                            setShowText28(false);
                            setShowText18(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline38}
                            </span>

                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>

                      <div
                        className=""
                        style={{ display: showImg2 ? "block" : "none" }}
                      >
                        <button
                          onClick={() => {
                            setShowText19(true);
                            setShowText39(false);
                            setShowText29(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {" "}
                              {datalist[0]?.subline19}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText29(true);
                            setShowText19(false);
                            setShowText39(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline29}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText39(true);
                            setShowText29(false);
                            setShowText19(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline39}
                            </span>

                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>

                      <div
                        className=""
                        style={{ display: showImg3 ? "block" : "none" }}
                      >
                        <button
                          onClick={() => {
                            setShowText110(true);
                            setShowText310(false);
                            setShowText210(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {" "}
                              {datalist[0]?.subline110}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText210(true);
                            setShowText110(false);
                            setShowText310(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            {" "}
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline210}
                            </span>
                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                        <button
                          onClick={() => {
                            setShowText310(true);
                            setShowText210(false);
                            setShowText110(false);
                            setHideText(!hideText);
                          }}
                          className="btn-check-after"
                          style={{ width: "100%", margin: "10px 0px" }}
                        >
                          <div className="fac-btndiv">
                            <span style={{ display: "block" }}>
                              {datalist[0]?.subline310}
                            </span>

                            <svg
                              className="svg-arrow2"
                              width="30"
                              height="9"
                              viewBox="0 0 30 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="blackcolor"
                                d="M29.8086 4.99174C30.0039 4.79648 30.0039 4.47989 29.8086 4.28463L26.6267 1.10265C26.4314 0.90739 26.1148 0.90739 25.9195 1.10265C25.7243 1.29791 25.7243 1.6145 25.9195 1.80976L28.748 4.63819L25.9195 7.46661C25.7243 7.66188 25.7243 7.97846 25.9195 8.17372C26.1148 8.36898 26.4314 8.36898 26.6267 8.17372L29.8086 4.99174ZM0.487305 5.13818L29.4551 5.13819L29.4551 4.13819L0.487305 4.13818L0.487305 5.13818Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>

                    {showText18 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg ? "block" : "none" }}
                        >
                          {/* <li className="li-right">
                          {datalist[0]?.subofsubline28}
                            Additional Treatment Capacity Planned: 364 MLD
                          </li>
                          <li className="li-right">
                          {datalist[0]?.subofsubline38}
                            Approx. 18.5 km of Trunk lines to curb pollution
                            from existing piped outfalls
                          </li> */}
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline18}
                          </li>
                          {/* <li className="li-right"> */}
                          {/* {" "} */}
                          {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                          {/* {datalist[0]?.subofsubline38} */}
                          {/* </li> */}
                        </ul>
                      </div>
                    )}
                    {showText28 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline28}
                          </li>
                          {/* <li className="li-right"> */}{" "}
                          {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                          {/* {datalist[0]?.subofsubline38} */}
                          {/* </li> */}
                        </ul>
                      </div>
                    )}
                    {showText38 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline38}
                          </li>
                          {/* <li className="li-right">
                          {datalist[0]?.subofsubline28}
                            Additional Treatment Capacity Planned: 364 MLD
                          </li>
                          <li className="li-right">
                          {datalist[0]?.subofsubline38}
                            Approx. 18.5 km of Trunk lines to curb pollution
                            from existing piped outfalls
                          </li> */}
                          {/* <li className="li-right"> */}
                          {/* {" "} */}
                          {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                          {/* {datalist[0]?.subofsubline28} */}
                          {/* </li> */}
                        </ul>
                      </div>
                    )}

                    {showText19 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg2 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline19}
                          </li>
                          {/* <li className="li-right"> */}{" "}
                          {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                          {/* {datalist[0]?.subofsubline28} */}
                          {/* </li> */}
                          {/* <li className="li-right"> */}{" "}
                          {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                          {/* {datalist[0]?.subofsubline28} */}
                          {/* </li> */}
                          {/* <li className="li-right">
                          {datalist[0]?.subofsubline28}
                            Additional Treatment Capacity Planned: 364 MLD
                          </li>
                          <li className="li-right">
                          {datalist[0]?.subofsubline38}
                            Approx. 18.5 km of Trunk lines to curb pollution
                            from existing piped outfalls
                          </li> */}
                        </ul>
                      </div>
                    )}
                    {showText29 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg2 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline29}
                          </li>
                        </ul>
                      </div>
                    )}
                    {showText39 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg2 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline39}
                          </li>
                        </ul>
                      </div>
                    )}

                    {showText110 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg3 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline110}
                          </li>
                          {/* <li className="li-right">
                          {datalist[0]?.subofsubline28}
                            Additional Treatment Capacity Planned: 364 MLD
                          </li>
                          <li className="li-right">
                          {datalist[0]?.subofsubline38}
                            Approx. 18.5 km of Trunk lines to curb pollution
                            from existing piped outfalls
                          </li> */}
                        </ul>
                      </div>
                    )}
                    {showText210 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg3 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline210}
                          </li>
                        </ul>
                      </div>
                    )}
                    {showText310 && (
                      <div className="col-xl-6  ">
                        <ul
                          className="ul-right"
                          style={{ display: showImg3 ? "block" : "none" }}
                        >
                          <li className="li-right">
                            {" "}
                            {/* 6 new STP’s & augmentation of 5 STP’s proposed under
                            JICA & NRCD scheme */}
                            {datalist[0]?.subofsubline310}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </section>

                <div className="ese-image">
                  <div className="ese2">
                    <input type="checkbox" id="open2" />
                    <label
                      for="open2"
                      onClick={() => {
                        {
                          setShowImg(true);
                          setShowImg2(false);
                          setShowImg3(false);
                          setShowText18(false);
                          setShowText38(false);
                          setShowText28(false);

                          document.getElementById("image").style.zIndex = "2";
                          document.getElementById("image2").style.zIndex = "1";
                          document.getElementById("image3").style.zIndex = "0";
                        }
                      }}
                    >
                      <img
                        id="image"
                        style={{ height: 500, width: 425 }}
                        src={imgeurl + datalist[0]?.line5}
                        className="content"
                      />
                    </label>
                    <label
                      for="open2"
                      onClick={() => {
                        {
                          setShowImg2(true);
                          setShowImg(false);
                          setShowImg3(false);
                          setShowText19(false);
                          setShowText39(false);
                          setShowText29(false);
                          document.getElementById("image").style.zIndex = "0";
                          document.getElementById("image2").style.zIndex = "2";
                          document.getElementById("image3").style.zIndex = "1";
                        }
                      }}
                    >
                      <img
                        id="image2"
                        style={{ height: 500, width: 425 }}
                        src={imgeurl + datalist[0]?.line6}
                        className="content2"
                      />
                    </label>
                    <label
                      for="open2"
                      onClick={() => {
                        {
                          setShowImg3(true);
                          setShowImg(false);
                          setShowImg2(false);
                          setShowText110(false);
                          setShowText310(false);
                          setShowText210(false);
                          document.getElementById("image").style.zIndex = "0";
                          document.getElementById("image2").style.zIndex = "1";
                          document.getElementById("image3").style.zIndex = "2";
                        }
                      }}
                    >
                      <img
                        id="image3"
                        style={{ height: 500, width: 425 }}
                        src={imgeurl + datalist[0]?.line7}
                        className="content3"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="intervention">
          <div className="container-fluid bottom-check">
            <div className="row">
              <div className="col-xl-12">
                <h5 className="sec-left-new">{datalist[0]?.line11}</h5>
                <div className="row align-items-new">
                  <div className="col-xl-6 col-lg-6">
                    <div>
                      <p className="class-inter">
                        {/* By creating a meaningful public realm along the river
                        and promoting community activities and programmes that
                        lead to a better quality of life in the City of Pune,
                        the Municipal Corporation of Pune is committed to
                        transforming today's city, built on the motherly laps of
                        the Mula-Mutha riverbanks, into a healthy, vibrant place
                        to live and thrive.{" "} */}
                        {datalist[0]?.line12}
                      </p>
                      {/* <p className="class-inter">
                        The revitalization of the river will contribute to the
                        waterfront becoming a hub for social, cultural, and
                        recreational activities in the city, as well as propose
                        a resilient river edge to mitigate the threat of
                        flooding, making it an appealing place to live for both
                        current and future residents.{" "}
                      </p>
                      <p className="class-inter">
                        As a result, it contributes to the city's potential for
                        adding green recreational spaces, as well as providing
                        active citizen partnerships with civic and business
                        organizations, as well as a revitalized sense of
                        community.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 key-img1">
                    <div className="key-img">
                      <img src={imgeurl + datalist[0]?.line13} />
                    </div>
                    <div className="key-img2">
                      <img src="../../images/Polygon 3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="cntainer" style={{ padding: '1rem' }}>
                <div className="contact-us1">
                  <h2 style={{ fontSize: '3rem' }}>Contact Us</h2> <br></br>
                  <form>
                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Name</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        required
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Mobile Number</label>
                      <input className='contactUsFormInput'
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={handleInputChange}
                        required
                        placeholder="Your Mobile Number" />
                    </div>

                    <div className="form-group1">
                      <label className='contactUsFormLabels' style={{ fontSize: '16px' }}>Comment Here</label>
                      <textarea className='contactUsFormInput'
                        type="text"
                        id="comment"
                        name="comment"
                        required
                        onChange={handleInputChange}
                        placeholder="Comment Here" ></textarea>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end', fontSize: '15px' }}>
                      <button type="submit" onClick={handleSubmitForm}  data-dismiss="modal" aria-label="Close">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Facility;
